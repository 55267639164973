import mysocket from "./GpsSocket";
import api from "../api";

function refresh_table(tables, min) {
  var json = {
    id: "11111-11111-11111-11111",
    type: "refresh",
    content: { tablename: tables, refreshtype: 5 },
  };
  api
    .sys_orderdata_save({
      id: 0,
      oDeviceID: 0,
      oDevType: 0,
      oOrderType: "refresh",
      oOrderContent: JSON.stringify(json),
      oSendState: 0,
      // userid: userid,
      // token: token,
    })
    .then((res) => {});

  mysocket.sendMsgToDB(
    "refresh",
    {
      tablename: tables,
      refreshtype: min,
    },
    function (json) {}
  );
}
export default refresh_table;
