<template>
  <splitpanes class="default-theme dth" @resize="changePaneSize">
    <pane :size="paneSize">
      <div class="left">
        <div class="left-1">
          <div class="flex search">
            <el-tree-select
              v-model="tree_id"
              :data="tree_option"
              :render-after-expand="false"
              check-strictly
              :props="tree_props"
              filterable
              clearable
              :filter-node-method="filterNodeMethod"
              :default-expanded-keys="['a_0']"
              node-key="node_id"
              @node-click="nodeClickTree"
            >
              <template #default="{ node, data }">
                <label class="prefix" v-if="data.nodetype != '3'"
                  ><img
                    style="width: 17px; height: 17px; margin-top: -3px"
                    :src="data.icon"
                    alt=""
                /></label>
                <label
                  style="padding-left: 5px; font-size: 13px; color: #333"
                  >{{ node.label }}</label
                >
              </template>
            </el-tree-select>
            <!-- <el-select
              v-model="cpy_id"
              placeholder="请选择"
              @change="change_cpy"
            >
              <el-option
                v-for="item in list_company"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="group_id"
              :placeholder="$t('map.qingxuanze')"             
              @change="change_group"
            >
              <el-option
                v-for="item in list_group"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
            <el-input
              clearable
              v-model="query_key"
              :placeholder="$t('commKey.keynameMap')"
              @input="onQueryChanged"
            />
            <span class="iconfont icon-jia55 da" @click="btnAddCar"></span>
          </div>
          <div class="flex-row navl">
            <div
              :class="[car_query.flag == 1 ? 'n_act' : '']"
              @click="search_tree_car(1)"
            >
              {{ $t("map.quanbu") }}
            </div>
            <div
              :class="[car_query.flag == 2 ? 'n_act' : '']"
              @click="search_tree_car(2)"
            >
              {{ $t("map.zaixian") }}
            </div>
            <div
              :class="[car_query.flag == 3 ? 'n_act' : '']"
              @click="search_tree_car(3)"
            >
              {{ $t("map.lixian") }}
            </div>
            <div
              :class="[car_query.flag == 4 ? 'n_act' : '']"
              @click="search_tree_car(4)"
            >
              {{ $t("map.baojing") }}
            </div>
            <div
              :class="[car_query.flag == 5 ? 'n_act' : '']"
              @click="search_tree_car(5)"
            >
              {{ $t("map.guanzhu") }}
            </div>
          </div>
          <div style="height: calc(100% - 65px)">
            <el-scrollbar
              style="background: #fff; position: relative; height: calc(100%)"
            >
              <div
                :class="['boxes', it.chk == 1 ? 'act' : '']"
                v-for="it in car_list"
                :key="'car' + it.id"
                @click="click_car_st(it)"
              >
                <div class="card">
                  <div class="info">
                    <div class="check">
                      <i></i>
                    </div>
                    <!--0 灰色 离线-->
                    <!--1 绿色，上线 无报警  GPS正常 有速度-->
                    <!--2 红色  上线  有报警-->
                    <!--3 紫色  上线 无报警  GPS没有信号-->
                    <!--4 黄色 上线 ACC关闭-->
                    <!--5 蓝色  上线 无报警 GPS正常  速度为0-->

                    <div class="img">
                      <span
                        :class="[
                          'iconfont',
                          'icon-qiche55',
                          'da2',
                          'color' + it.st_msg,
                        ]"
                      ></span>
                    </div>

                    <div class="view">
                      <div class="info-lb">
                        <div
                          class="wangluo iconfont"
                          :title="$t('map.wangluo')"
                          v-if="it.lWireNetSignValue < 0"
                        >
                          <div>
                            <span></span><span></span><span></span><span></span
                            ><span></span>
                          </div>
                          <label>{{ it.lWireNetSignValue }}</label>
                        </div>
                        <div
                          class="wangluo iconfont"
                          :title="$t('map.wangluo')"
                          v-if="
                            it.lWireNetSignValue >= 0 &&
                            it.lWireNetSignValue <= 6
                          "
                        >
                          <div>
                            <span class="blue"></span><span></span><span></span
                            ><span></span><span></span>
                          </div>
                          <label>{{ it.lWireNetSignValue }}</label>
                        </div>
                        <div
                          class="wangluo iconfont"
                          :title="$t('map.wangluo')"
                          v-if="
                            it.lWireNetSignValue >= 7 &&
                            it.lWireNetSignValue <= 12
                          "
                        >
                          <div>
                            <span class="blue"></span><span class="blue"></span
                            ><span></span><span></span><span></span>
                          </div>
                          <label class="blues">{{
                            it.lWireNetSignValue
                          }}</label>
                        </div>
                        <div
                          class="wangluo iconfont"
                          :title="$t('map.wangluo')"
                          v-if="
                            it.lWireNetSignValue >= 13 &&
                            it.lWireNetSignValue <= 19
                          "
                        >
                          <div>
                            <span class="blue"></span><span class="blue"></span
                            ><span class="blue"></span><span></span
                            ><span></span>
                          </div>
                          <label class="blues">{{
                            it.lWireNetSignValue
                          }}</label>
                        </div>
                        <div
                          class="wangluo iconfont"
                          :title="$t('map.wangluo')"
                          v-if="
                            it.lWireNetSignValue >= 20 &&
                            it.lWireNetSignValue <= 25
                          "
                        >
                          <div>
                            <span class="blue"></span><span class="blue"></span
                            ><span class="blue"></span><span class="blue"></span
                            ><span></span>
                          </div>
                          <label class="blues">{{
                            it.lWireNetSignValue
                          }}</label>
                        </div>
                        <div
                          class="wangluo iconfont"
                          :title="$t('map.wangluo')"
                          v-if="it.lWireNetSignValue > 25"
                        >
                          <div>
                            <span class="blue"></span><span class="blue"></span
                            ><span class="blue"></span><span class="blue"></span
                            ><span class="blue"></span>
                          </div>
                          <label class="blues">{{
                            it.lWireNetSignValue
                          }}</label>
                        </div>
                        <span
                          class="iconfont icon-weixing55 da3 da34"
                          v-if="it.lGNSSStarCount == 0"
                          :title="$t('map.weixing')"
                          ><label>{{ it.lGNSSStarCount }}</label></span
                        >
                        <span
                          class="iconfont icon-weixing55 da3 da34 blues"
                          v-else
                          :title="$t('map.weixing')"
                          ><label>{{ it.lGNSSStarCount }}</label></span
                        >
                        <span
                          v-if="it.acc == '1'"
                          class="iconfont icon-fadongji555 da3 blues"
                          :title="$t('map.dianhuo')"
                        ></span>
                        <span
                          v-else
                          class="iconfont icon-fadongji555 da3"
                          :title="$t('map.dianhuo')"
                        ></span>
                      </div>
                      <div class="flex-between">
                        <div class="w120">
                          <strong :title="it.vPlate">{{ it.cphm }} </strong>
                        </div>

                        <span
                          @click="dot_click($event, it)"
                          class="iconfont icon-liebiao-dian88 lbs"
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div class="sudu">
                    <div class="mr25">
                      <span
                        class="iconfont icon-tingchechang00 sd6 blues"
                        v-if="parseInt(it.sudu) == 0"
                      ></span
                      ><span
                        class="iconfont icon-hangshi1678 sd7 blues"
                        v-if="parseInt(it.sudu) != 0"
                      ></span
                      >{{ it.sudu }}
                    </div>
                    <div>
                      <span class="iconfont icon-licheng155 sd blues"></span
                      >{{ it.licheng }}
                    </div>
                  </div>
                </div>
                <div class="guanzhu">
                  <svg
                    @click="set_fav(it.id, 1)"
                    v-if="it.fav == 0"
                    t="1719896972449"
                    :plain="true"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="8658"
                    width="15"
                    height="15"
                  >
                    <path
                      d="M548.8 774.4L752 881.6 713.6 656c-4.8-25.6 4.8-52.8 22.4-70.4l163.2-160L672 392c-25.6-3.2-48-20.8-60.8-43.2L512 142.4l-100.8 204.8c-11.2 24-33.6 40-60.8 43.2L123.2 425.6l163.2 160c19.2 17.6 27.2 44.8 22.4 70.4L272 881.6l203.2-107.2c22.4-12.8 51.2-12.8 73.6 0z m-44.8 56l-203.2 107.2c-32 16-70.4 4.8-86.4-27.2-6.4-12.8-9.6-27.2-6.4-40l38.4-225.6c1.6-4.8-1.6-11.2-4.8-14.4L80 470.4c-25.6-24-25.6-65.6-1.6-91.2 9.6-9.6 22.4-16 36.8-19.2l227.2-33.6c4.8 0 9.6-4.8 12.8-8l100.8-204.8c16-32 54.4-44.8 86.4-28.8 12.8 6.4 22.4 16 28.8 28.8L670.4 320c1.6 4.8 6.4 8 12.8 8l227.2 33.6c35.2 4.8 59.2 36.8 54.4 72-1.6 14.4-8 27.2-19.2 36.8l-163.2 160c-3.2 3.2-4.8 9.6-4.8 14.4L816 870.4c6.4 35.2-17.6 67.2-52.8 73.6-14.4 1.6-28.8 0-40-6.4l-203.2-107.2c-4.8-1.6-11.2-1.6-16 0z"
                      fill="#ff8d1a"
                      p-id="8659"
                    ></path>
                  </svg>
                  <svg
                    @click="set_fav(it.id, 2)"
                    v-if="it.fav == 1"
                    t="1719897004671"
                    :plain="true"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="9060"
                    width="15"
                    height="15"
                  >
                    <path
                      d="M512 852.699L245.08 992.95c-32.28 16.962-70-10.451-63.832-46.392l50.972-297.025L16.296 439.174c-26.127-25.453-11.717-69.817 24.38-75.06l298.42-43.338L472.547 50.519c16.143-32.692 62.761-32.692 78.904 0l133.453 270.257 298.419 43.339c36.097 5.242 50.507 49.606 24.38 75.06L791.78 649.532l50.972 297.025c6.168 35.94-31.551 63.354-63.832 46.392L512 852.7z"
                      fill="#ff8d1a"
                      p-id="9061"
                    ></path>
                  </svg>
                </div>
              </div>
              <!-- <div class="boxes">
              <div class="card">
                <div class="info">
                  <div class="check">
                    <i></i>
                  </div>
                  <div class="img">
                    <span class="iconfont icon-qiche55 da2 color3"></span>
                  </div>
                  <div class="view flex-between">
                    <strong title="粤B0442**-无线">粤B0442** </strong>
                    <div class="info-lb">
                      <span class="iconfont icon-xinhao55 da3" :title="$t('map.wangluo')"
                        ><label>78</label></span
                      >
                      <span
                        class="iconfont icon-weixing55 da3 color3"
                        :title="$t('map.weixing')"
                        ><label>5</label></span
                      >
                      <span
                        class="iconfont icon-fadongji555 da3"
                        :title="$t('map.dianhuo')"
                      ></span>
                      <span class="iconfont icon-liebiao-dian88 da3 lbs"></span>
                    </div>
                  </div>
                </div>
                <div class="sudu">
                  <div class="mr25">
                    <span class="iconfont icon-hangshi1678 sd7"></span>20
                    英里/小时
                  </div>
                  <div>
                    <span class="iconfont icon-licheng155 sd"></span>1903 公里
                  </div>
                </div>
              </div>
            </div>
            <div class="boxes">
              <div class="card">
                <div class="info">
                  <div class="check">
                    <i></i>
                  </div>
                  <div class="img">
                    <span class="iconfont icon-qiche55 da2 color6"></span>
                  </div>
                  <div class="view flex-between">
                    <strong title="粤B0442**-无线">粤B0442** </strong>
                    <div class="info-lb">
                      <span class="iconfont icon-xinhao55 da3" :title="$t('map.wangluo')"
                        ><label>78</label></span
                      >
                      <span class="iconfont icon-weixing55 da3" :title="$t('map.weixing')"
                        ><label>5</label></span
                      >
                      <span
                        class="iconfont icon-fadongji555 da3 color6"
                        :title="$t('map.dianhuo')"
                      ></span>
                      <span class="iconfont icon-liebiao-dian88 da3 lbs"></span>
                    </div>
                  </div>
                </div>
                <div class="sudu">
                  <div class="mr25">
                    <span class="iconfont icon-tingchechang00 sd6"></span>0.00
                    英里/小时
                  </div>
                  <div>
                    <span class="iconfont icon-licheng155 sd"></span>1903 公里
                  </div>
                </div>
              </div>
            </div>
            <div class="boxes">
              <div class="card">
                <div class="info">
                  <div class="check">
                    <i></i>
                  </div>
                  <div class="img">
                    <span class="iconfont icon-qiche55 da2 color2"></span>
                  </div>
                  <div class="view flex-between">
                    <strong title="粤B0442**-无线">粤B0442** </strong>
                    <div class="info-lb">
                      <span class="iconfont icon-xinhao55 da3" :title="$t('map.wangluo')"
                        ><label>78</label></span
                      >
                      <span class="iconfont icon-weixing55 da3" :title="$t('map.weixing')"
                        ><label>5</label></span
                      >
                      <span
                        class="iconfont icon-fadongji555 da3 color2"
                        :title="$t('map.dianhuo')"
                      ></span>
                      <span class="iconfont icon-liebiao-dian88 da3 lbs"></span>
                    </div>
                  </div>
                </div>
                <div class="sudu">
                  <div class="mr25">
                    <span class="iconfont icon-tingchechang00 sd6"></span>0.00
                    英里/小时
                  </div>
                  <div>
                    <span class="iconfont icon-licheng155 sd"></span>1903 公里
                  </div>
                </div>
              </div>
            </div>
            <div class="boxes">
              <div class="card">
                <div class="info">
                  <div class="check">
                    <i></i>
                  </div>
                  <div class="img">
                    <span class="iconfont icon-qiche55 da2 color7"></span>
                  </div>
                  <div class="view flex-between">
                    <strong title="粤B0442**-无线">粤B0442** </strong>
                    <div class="info-lb">
                      <span
                        class="iconfont icon-xinhao55 da3 color7"
                        :title="$t('map.wangluo')"
                        ><label>78</label></span
                      >
                      <span class="iconfont icon-weixing55 da3" :title="$t('map.weixing')"
                        ><label>5</label></span
                      >
                      <span
                        class="iconfont icon-fadongji555 da3"
                        :title="$t('map.dianhuo')"
                      ></span>
                      <span class="iconfont icon-liebiao-dian88 da3 lbs"></span>
                    </div>
                  </div>
                </div>
                <div class="sudu">
                  <div class="mr25">
                    <span class="iconfont icon-hangshi1678 sd7"></span>20
                    英里/小时
                  </div>
                  <div>
                    <span class="iconfont icon-licheng155 sd"></span>1903 公里
                  </div>
                </div>
              </div>
            </div>
            <div class="boxes">
              <div class="card">
                <div class="info">
                  <div class="check">
                    <i></i>
                  </div>
                  <div class="img">
                    <span class="iconfont icon-qiche55 da2 color8"></span>
                  </div>
                  <div class="view flex-between">
                    <strong title="粤B0442**-无线">粤B0442** </strong>
                    <div class="info-lb">
                      <span class="iconfont icon-xinhao55 da3" :title="$t('map.wangluo')"
                        ><label>78</label></span
                      >
                      <span
                        class="iconfont icon-weixing55 da3 color8"
                        :title="$t('map.weixing')"
                        ><label>5</label></span
                      >
                      <span
                        class="iconfont icon-fadongji555 da3"
                        :title="$t('map.dianhuo')"
                      ></span>
                      <span class="iconfont icon-liebiao-dian88 da3 lbs"></span>
                    </div>
                  </div>
                </div>
                <div class="sudu">
                  <div class="mr25">
                    <span class="iconfont icon-tingchechang00 sd6"></span>0.00
                    英里/小时
                  </div>
                  <div>
                    <span class="iconfont icon-licheng155 sd"></span>1903 公里
                  </div>
                </div>
              </div>
            </div> -->
              <div class="gps-page">
                <el-pagination
                  :currentPage="car_query.page"
                  :page-size="car_query.rp"
                  :page-sizes="[20, 50, 100, 200, 300, 400]"
                  :disabled="disabled"
                  :background="background"
                  layout="total, prev, pager, next"
                  :total="car_total"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                />
              </div>
            </el-scrollbar>
          </div>

          <!-- <el-tabs
            v-model="activeName"
            @tab-click="handleClick"
            class="demo-tabs"
          >
            <el-tab-pane label="全部" name="first"> </el-tab-pane>
            <el-tab-pane label="在线" name="second">Config</el-tab-pane>
            <el-tab-pane label="离线" name="third">Role</el-tab-pane>
            <el-tab-pane label="报警" name="fourth">Task</el-tab-pane>
            <el-tab-pane label="关注" name="fiveth">Task</el-tab-pane>
          </el-tabs> -->
        </div>
      </div>
    </pane>
    <pane :size="100 - paneSize">
      <div :style="{ height: 'calc(100% - ' + mapBottomHeight + 'px)' }">
        <div class="it-map">
          <div class="tv_control">
            <div class="tv-fp">
              <div>
                <svg
                  t="1721100799587"
                  @click="closeAll"
                  :title="$t('map.guanbi')"
                  v-if="isShowVideo"
                  class="icon"
                  viewBox="0 0 1740 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="7612"
                  width="32"
                  height="32"
                >
                  <path
                    d="M496.35233318 512L783.54148155 756.41629648l-67.21448153 61.10407412L355.81296271 512l360.51403731-305.5203706L783.54148155 267.58370352 496.35233318 512z m311.63077802 0l293.29955578 244.41629648-73.32488895 61.10407412-360.51403731-305.5203706L1027.95777803 206.4796294l67.21448154 61.10407412-287.18914838 244.41629648z m293.29955578 0L1394.58222276 756.41629648l-67.21448154 61.10407412L966.85370391 512l360.51403732-305.5203706L1394.58222276 267.58370352l-293.29955578 244.41629648z"
                    fill="#2c78bf"
                    p-id="7613"
                  ></path>
                </svg>
                <svg
                  t="1720837294358"
                  class="icon"
                  @click="openAll"
                  :title="$t('map.dakai')"
                  v-else
                  viewBox="0 0 1803 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="8439"
                  width="30"
                  height="30"
                >
                  <path
                    d="M454.95709738 735.55929492l267.95539175-267.89223991 44.64870692 44.6487069L499.60580375 780.27115368z"
                    fill="#2c78bf"
                    p-id="8440"
                  ></path>
                  <path
                    d="M499.85841329 243.47623678l267.95539178 267.9553923-44.6487064 44.64870638L455.20970637 288.12494316zM771.09772692 735.87505684L1039.05311921 467.98281693l44.64870692 44.64870639-267.95539229 267.8922399z"
                    fill="#2c78bf"
                    p-id="8441"
                  ></path>
                  <path
                    d="M815.99904337 243.85515108L1083.89128276 511.74739047l-44.6487064 44.71185928L771.35033645 288.44070508zM1086.5436813 735.55929492l267.95539231-267.9553923 44.64870637 44.64870692L1131.25554007 780.20800131z"
                    fill="#2c78bf"
                    p-id="8442"
                  ></path>
                  <path
                    d="M1131.44499722 243.60254154l267.95539229 267.89223992-44.64870689 44.64870638L1086.79629084 288.18809555z"
                    fill="#2c78bf"
                    p-id="8443"
                  ></path>
                </svg>
              </div>
              <!-- <span @click="closeAll" class="tv_control_font closea"
              ></span> -->
              <div>
                <span
                  @click="change_video(1)"
                  v-show="isShowVideo"
                  :title="$t('map.danping')"
                  class="wincount iconfont tv_control_font"
                  ></span
                ><span
                  @click="change_video(4)"
                  v-show="isShowVideo"
                  :title="$t('map.siping')"
                  class="wincount iconfont tv_control_font"
                  ></span
                ><span
                  @click="change_video(9)"
                  v-show="isShowVideo"
                  :title="$t('map.jiuping')"
                  class="wincount iconfont tv_control_font"
                  ></span
                >
                <!-- <span
                  @click="openVideo()"
                  v-show="isShowVideo"
                  title="开关"
                  class="wincount iconfont tv_control_font"
                  >&#xe6e9;
                </span> -->
                <span
                  @click="closeAll"
                  v-show="isShowVideo"
                  :title="$t('map.guanbi')"
                  class="wincount iconfont tv_control_font"
                  >&#xe6e9;
                </span>

                <!-- <el-button
                  v-show="isShowVideo"
                  type="primary"
                  class="tv-an"
                  round
                  @click="closeAll"
                  >关闭</el-button
                > -->

                <!-- <el-button
                v-show="isShowVideo"
                type="primary"
                class="tv-an"
                round
                @click="closeAll"
                >关闭</el-button
              >-->
              </div>
            </div>

            <div class="map_list" v-if="false">
              <span
                v-for="it in mapList"
                :key="'map' + it.id"
                @click="mapChange(it.id)"
                >{{ it.name }}</span
              >
            </div>
          </div>
          <div
            style="height: calc(100% - 35px); position: relative"
            class="flex-row"
          >
            <div class="flex-row-wrap mapL" ref="cc" v-show="isShowVideo">
              <div
                class="box"
                v-for="i in boxes"
                :key="i"
                @contextmenu.prevent="openMenu($event, i)"
              >
                <GpsVideo
                  :ref="'video' + i"
                  :width="ww"
                  :height="hh"
                  :channelidx="i"
                ></GpsVideo>
              </div>
            </div>
            <div class="mapR">
              <GpsMap ref="mainMap"></GpsMap>

              <!-- <LeafletMap ref="mainMap"></LeafletMap> -->
            </div>
            <div class="map_tj">
              <el-button
                :class="[is_online_map == 1 ? 'act_online' : '']"
                type="primary"
                @click="btnOnline"
                >{{ $t("map.zaixian") }}</el-button
              >
              <el-button
                :class="[is_alarm_map == 1 ? 'act_alarm' : '']"
                type="warning"
                @click="btnAlarm"
                >{{ $t("map.baojing") }}</el-button
              >
            </div>
          </div>
        </div>
        <div class="it-map" v-if="false"></div>
      </div>
      <div class="map-bottom cp1" :style="{ height: mapBottomHeight + 'px' }">
        <div class="xx">
          <div
            :class="['xx-it', it.cls]"
            v-for="(it, idx) in xx_row"
            :key="'xx' + idx"
          >
            {{ it.name }}:{{ it.value }}
          </div>
        </div>
        <!-- <div class="close" @click="closeMsg"></div> -->
        <el-table
          :show-overflow-tooltip="true"
          :data="dataCar"
          border
          stripe
          :height="mapBottomHeight - 35"
          @row-click="rowClick"
          :row-class-name="tableRowClass"
        >
          <el-table-column
            align="center"
            v-for="col in columns"
            :key="col.field"
            :label="col.label"
            :width="col.width"
            :show-overflow-tooltip="true"
          >
            <template #default="{ row }">
              <span v-if="col.type == 'img'"
                ><img :src="row[col.field]" style="width: 30px; heigth: 30px"
              /></span>
              <span v-else-if="col.type == 'zt'">
                <b v-if="row[col.field] == 1" style="color: #909399">{{
                  row[col.field + "_name"]
                }}</b>
                <b v-if="row[col.field] == 2" style="color: #67c23a">{{
                  row[col.field + "_name"]
                }}</b>
                <b v-if="row[col.field] == 3" style="color: #f56c6c">{{
                  row[col.field + "_name"]
                }}</b>
              </span>
              <span v-else-if="col.type == 'fun'" v-html="col.render(row)">
              </span>
              <span v-else-if="typeof col.type === 'undefined'">{{
                row[col.field]
              }}</span>
            </template>
          </el-table-column>
        </el-table>

        <!-- {{ $t("map.rImg") }} -->
      </div>
      <div class="rightMenu" v-show="showRightMenu" id="menu">
        <ul v-if="dev_type_sn == '81802'">
          <li @click="click_video_all_it()" v-if="sel_row.st_msg > 0">
            <el-icon :size="20" class="spb"><VideoCamera /></el-icon
            >{{ $t("map.shipin") }}
          </li>
          <li @click="show_history_it()">
            <span class="icon iconfont gj el-icon">&#xe67b;</span
            >{{ $t("map.guiji") }}
          </li>
          <li @click="show_back_it()" v-if="sel_row.st_msg > 0">
            <el-icon :size="20" class="spb"><RefreshLeft /></el-icon
            >{{ $t("map.huifang") }}
          </li>
          <div class="xxsc" v-if="sel_row.st_msg > 0"></div>
          <li @click="clickMenuItem('xfwz')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s1.png" /> {{ $t("map.item_xfwzxx") }}
          </li>
          <li @click="clickMenuItem('cssz')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s2.png" />{{ $t("map.item_sbcssz") }}
          </li>
          <li @click="clickMenuItem('gshcp')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s3.png" />{{ $t("map.item_gshcp") }}
          </li>
          <li @click="clickMenuItem('gpssb')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s4.png" />{{ $t("map.item_gpssbjgsz") }}
          </li>
          <li @click="clickMenuItem('clkz')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s5.png" />{{ $t("map.item_clkz") }}
          </li>
          <li @click="clickMenuItem('clxx')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s6.png" />{{ $t("map.item_info") }}
          </li>
          <li @click="clickMenuItem('cq')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s7.png" />{{ $t("map.item_cp") }}
          </li>
          <li @click="clickMenuItem('dj')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s8.png" />{{ $t("map.item_intercom") }}
          </li>
          <li @click="clickMenuItem('lc')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s9.png" />{{ $t("map.item_lcsz") }}
          </li>
          <li @click="clickMenuItem('jssz')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s10.png" />{{ $t("map.item_drive") }}
          </li>
        </ul>
        <ul v-else-if="dev_type_sn == '81801'">
          <li @click="click_video_all_it()" v-if="sel_row.st_msg > 0">
            <el-icon :size="20" class="spb"><VideoCamera /></el-icon
            >{{ $t("map.shipin") }}
          </li>
          <li @click="show_history_it()">
            <span class="icon iconfont gj el-icon">&#xe67b;</span
            >{{ $t("map.guiji") }}
          </li>
          <li @click="show_back_it()" v-if="sel_row.st_msg > 0">
            <el-icon :size="20" class="spb"><RefreshLeft /></el-icon
            >{{ $t("map.huifang") }}
          </li>
          <div class="xxsc" v-if="sel_row.st_msg > 0"></div>
          <li @click="clickMenuItem('xfwz')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s1.png" />{{ $t("map.item_xfwzxx") }}
          </li>
          <li @click="clickMenuItem('cssz')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s2.png" />{{ $t("map.item_sbcssz") }}
          </li>
          <li @click="clickMenuItem('gshyp')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s3.png" />{{ $t("map.geshihuayingpan") }}
          </li>
          <li @click="clickMenuItem('gshsdk')" v-if="sel_row.st_msg > 0">
            <svg
              t="1720176499203"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2563"
              width="18"
              height="18"
              style="margin-right: 8px"
            >
              <path
                d="M849.655707 1024H174.340081A100.195197 100.195197 0 0 1 74.144884 923.804803V344.676567a101.197149 101.197149 0 0 1 30.058559-72.140542L356.695339 28.059745a99.193245 99.193245 0 0 1 70.136638-28.054655h422.82373a100.195197 100.195197 0 0 1 100.195196 100.195197v823.604516a100.195197 100.195197 0 0 1-100.195196 100.195197zM426.831977 80.161247a20.039039 20.039039 0 0 0-14.027328 6.011712L160.312753 330.649239a20.039039 20.039039 0 0 0-6.011711 14.027328v579.128236a20.039039 20.039039 0 0 0 20.039039 20.03904h675.315626a20.039039 20.039039 0 0 0 20.039039-20.03904V100.200287a20.039039 20.039039 0 0 0-20.039039-20.03904z"
                fill="#2c78bf"
                p-id="2564"
              ></path>
              <path
                d="M753.468318 876.713061H269.525518a40.078079 40.078079 0 0 1-40.078079-40.078079V588.150894a40.078079 40.078079 0 0 1 40.078079-40.078078h483.9428a40.078079 40.078079 0 0 1 40.078078 40.078078v248.484088a40.078079 40.078079 0 0 1-40.078078 40.078079z m-443.864722-80.156157h403.786643V628.228973H309.603596zM740.442942 464.910803a40.078079 40.078079 0 0 1-40.078079-40.078079V190.375964a40.078079 40.078079 0 0 1 80.156158 0v234.45676a40.078079 40.078079 0 0 1-40.078079 40.078079zM609.187235 464.910803a40.078079 40.078079 0 0 1-40.078079-40.078079V190.375964a40.078079 40.078079 0 0 1 80.156157 0v234.45676a40.078079 40.078079 0 0 1-40.078078 40.078079zM477.931527 464.910803a40.078079 40.078079 0 0 1-40.078079-40.078079V190.375964a40.078079 40.078079 0 0 1 80.156158 0v234.45676a40.078079 40.078079 0 0 1-40.078079 40.078079zM347.677771 464.910803a40.078079 40.078079 0 0 1-40.078079-40.078079V278.547737a40.078079 40.078079 0 0 1 80.156158 0v146.284987a40.078079 40.078079 0 0 1-40.078079 40.078079z"
                fill="#2c78bf"
                p-id="2565"
              ></path></svg
            >{{ $t("map.geshihua") }}
          </li>
          <li @click="clickMenuItem('bzgsensor')" v-if="sel_row.st_msg > 0">
            <svg
              t="1720176714627"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="5703"
              width="18"
              height="18"
              style="margin-right: 8px"
            >
              <path
                d="M886.608 682.528L735.504 155.072a31.808 31.808 0 0 0-21.712-25.376l-0.72-0.224a32.576 32.576 0 0 0-7.264-1.28C705.184 128.144 704.608 128 704 128H320c-0.592 0-1.136 0.144-1.712 0.176a32.96 32.96 0 0 0-3.552 0.352 34.384 34.384 0 0 0-8.704 2.8 29.744 29.744 0 0 0-7.632 5.184 31.392 31.392 0 0 0-5.76 7.12 29.76 29.76 0 0 0-2.72 5.856c-0.208 0.576-0.512 1.104-0.672 1.696L135.68 687.2c-0.512 1.6-0.928 3.232-1.392 4.864l-1.312 4.576c-0.16 0.576-0.176 1.136-0.304 1.712A158.784 158.784 0 0 0 128 736c0 88.224 71.776 160 160 160h448c88.224 0 160-71.776 160-160a158.928 158.928 0 0 0-9.392-53.472zM344.128 192h335.376l113.024 394.512A158.784 158.784 0 0 0 736 576H288a158.848 158.848 0 0 0-56.944 10.656L344.128 192zM736 832H288c-52.944 0-96-43.056-96-96 0-8.432 1.2-16.56 3.248-24.352l1.616-5.664C209.504 667.712 245.552 640 288 640h448c40.688 0 75.472 25.488 89.408 61.312l3.712 12.96c0.144 0.512 0.48 0.88 0.656 1.376 1.424 6.56 2.224 13.36 2.224 20.352 0 52.944-43.056 96-96 96z"
                fill="#2c78bf"
                p-id="5704"
              ></path>
              <path
                d="M736 736m-32 0a32 32 0 1 0 64 0 32 32 0 1 0-64 0Z"
                fill="#2c78bf"
                p-id="5705"
              ></path>
              <path
                d="M608 736m-32 0a32 32 0 1 0 64 0 32 32 0 1 0-64 0Z"
                fill="#2c78bf"
                p-id="5706"
              ></path>
            </svg>
            {{ $t("map.biaozhun") }}
          </li>
          <li @click="clickMenuItem('gpssb')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s4.png" /> {{ $t("map.item_gpssbjgsz") }}
          </li>
          <li @click="clickMenuItem('clkz')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s5.png" />{{ $t("map.item_clkz") }}
          </li>
          <li @click="clickMenuItem('clxx')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s6.png" />{{ $t("map.item_info") }}
          </li>
          <li @click="clickMenuItem('cq')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s7.png" />{{ $t("map.item_cp") }}
          </li>
          <li @click="clickMenuItem('dj')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s8.png" />{{ $t("map.item_intercom") }}
          </li>
          <li @click="clickMenuItem('lc')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s9.png" />{{ $t("map.item_lcsz") }}
          </li>
          <li @click="clickMenuItem('jssz')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s10.png" />{{ $t("map.item_drive") }}
          </li>
        </ul>
        <ul v-else>
          <li @click="click_video_all_it()" v-if="sel_row.st_msg > 0">
            <el-icon :size="20" class="spb"><VideoCamera /></el-icon
            >{{ $t("map.shipin") }}
          </li>
          <li @click="show_history_it()">
            <span class="icon iconfont gj el-icon">&#xe67b;</span
            >{{ $t("map.guiji") }}
          </li>
          <li @click="show_back_it()" v-if="sel_row.st_msg > 0">
            <el-icon :size="20" class="spb"><RefreshLeft /></el-icon
            >{{ $t("map.huifang") }}
          </li>
          <div class="xxsc" v-if="sel_row.st_msg > 0"></div>
          <li @click="clickMenuItem('xfwz')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s1.png" />{{ $t("map.item_xfwzxx") }}
          </li>
          <li @click="clickMenuItem('cq')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s7.png" />{{ $t("map.item_cp") }}
          </li>
          <li @click="clickMenuItem('dj')" v-if="sel_row.st_msg > 0">
            <img src="../assets/meun/s8.png" />{{ $t("map.item_intercom") }}
          </li>
        </ul>
      </div>
      <el-dialog
        :title="rightClickTitle"
        v-model="rightClickDialog"
        :width="dialogW"
        :append-to-body="true"
        :close-on-click-modal="false"
        @opened="rightMenuOpened"
        class="yjtc"
        :destroy-on-close="true"
      >
        <div class="xfwz" v-if="rightClickAct == 'xfwz'">
          <el-row :gutter="10" style="justify-content: space-between">
            <el-col :span="6" :offset="0" class="xf-1">
              <el-tree-v2
                highlight-current
                ref="treeRef_wz"
                :data="treeData"
                :height="500"
                :props="propoption"
                show-checkbox
                :filter-method="filterMethod"
                :default-expanded-keys="defaultExpandedKeys"
                ><template #default="{ node, data }">
                  <span class="prefix"
                    ><img
                      :src="data.icon"
                      alt=""
                      style="width: 17px; height: 17px"
                  /></span>
                  <span>{{ node.label }}</span>
                </template></el-tree-v2
              >
            </el-col>
            <el-col :span="18" :offset="0" class="xf-2">
              <div class="xf-3">
                <el-input
                  v-model="xfwz_val"
                  type="textarea"
                  placeholder="Please input"
                />
                <div
                  style="margin-top: 10px; margion-bottom: 10px; margin: 10px"
                >
                  <el-button type="primary" size="mini" @click="xfwzClick">{{
                    $t("map.btnSend")
                  }}</el-button>
                  <span style="padding-left: 10px">{{ $t("map.zy") }}</span>
                </div>
              </div>

              <div
                style="
                  margin-top: 20px;
                  color: #333;
                  font-weight: bold;
                  margin-bottom: 10px;
                "
              >
                <span class="xxs">▍</span>{{ $t("map.table_title") }}
              </div>
              <el-table :data="xfwz_tableData" style="width: 100%" class="ttr">
                <el-table-column
                  prop="vPlate"
                  :label="$t('map.table_zd')"
                  width="180"
                />
                <el-table-column
                  prop="sj"
                  :label="$t('map.table_sj')"
                  width="180"
                />
                <el-table-column prop="zt" :label="$t('map.table_zt')" />
                <el-table-column prop="nr" :label="$t('map.table_nr')" />
              </el-table>
            </el-col>
          </el-row>
        </div>
        <div class="gshcp" v-if="rightClickAct == 'gshcp'">
          <div>
            {{ $t("map.geshihualeixing") }}：<el-select
              v-model="gshlx_val"
              clearable
              filterable
            >
              <el-option
                v-for="item in gshlx_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div style="margin: 10px 0px; margin-top: 15px">
            {{ $t("map.fanyi1") }}
          </div>
          <div style="text-align: right">
            <el-button type="primary" size="mini">{{
              $t("commKey.Confirm")
            }}</el-button>
          </div>
        </div>
        <div class="gshsd" v-if="rightClickAct == 'gshyp'">
          <div style="margin-top: 10px">
            {{ $t("map.querenyaogeshihuayingpanma") }}
          </div>
          <el-button type="primary" size="mini" @click="click_gshyp">{{
            $t("commKey.Confirm")
          }}</el-button>
        </div>
        <div class="gshsd" v-if="rightClickAct == 'gshsdk'">
          <div>{{ $t("map.fanyi2") }}</div>
          <el-button type="primary" size="mini" @click="click_gshsdk">{{
            $t("commKey.Confirm")
          }}</el-button>
        </div>
        <div class="gshsd" v-if="rightClickAct == 'bzgsensor'">
          <div>{{ $t("map.fanyi3") }}</div>
          <el-button type="primary" size="mini" @click="click_bzgsensor">{{
            $t("commKey.Confirm")
          }}</el-button>
        </div>
        <div class="gpssb" v-if="rightClickAct == 'gpssb'">
          <el-form-item :label="$t('map.fanyi4')" size="normal">
            <el-input
              placeholder=""
              size="normal"
              clearable
              v-model="gps_temp.timeValue"
            >
              <template #append>{{ $t("map.fanyi6") }}</template>
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('map.fanyi5')" size="normal">
            <el-input
              placeholder=""
              size="normal"
              clearable
              v-model="gps_temp.xm_timeValue"
            >
              <template #append>{{ $t("map.fanyi6") }}</template>
            </el-input>
          </el-form-item>
          <div style="text-align: right">
            <el-button type="primary" size="medium" @click="get_gpssb">
              {{ $t("map.fanyi44") }}
            </el-button>
            <el-button type="primary" size="medium" @click="click_gpssb">{{
              $t("map.btnSend")
            }}</el-button>
          </div>
        </div>
        <div class="clkz" v-if="rightClickAct == 'clkz'">
          <el-form-item :label="$t('map.fanyi7')" size="normal">
            <el-radio-group v-model="clkz_temp.carMen" class="ml-4">
              <el-radio label="1" size="large">{{ $t("map.fanyi8") }}</el-radio>
              <el-radio label="0" size="large">{{ $t("map.fanyi9") }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('map.fanyi10')" size="normal">
            <el-radio-group v-model="clkz_temp.Circuit" class="ml-4">
              <el-radio label="1" size="large">{{
                $t("map.fanyi11")
              }}</el-radio>
              <el-radio label="0" size="large">{{
                $t("map.fanyi12")
              }}</el-radio>
            </el-radio-group>
          </el-form-item>

          <div style="text-align: right">
            <el-button type="primary" size="medium" @click="click_get_clkz">
              {{ $t("map.fanyi44") }}</el-button
            >
            <el-button type="primary" size="medium" @click="click_clkz">{{
              $t("map.btnSend")
            }}</el-button>
          </div>
        </div>
        <div class="cq" v-if="rightClickAct == 'cq'">
          <div>{{ $t("map.querenyaozhongqima") }}</div>
          <div style="text-align: right">
            <el-button type="primary" size="mini" @click="click_cq">{{
              $t("commKey.Confirm")
            }}</el-button>
          </div>
        </div>
        <div class="dj" v-if="rightClickAct == 'dj'">
          <el-radio-group v-model="dj_val" class="ml-4">
            <el-radio label="1" size="large">{{ $t("map.fanyi13") }}</el-radio>
            <el-radio label="2" size="large">{{ $t("map.fanyi14") }}</el-radio>
          </el-radio-group>
          <el-icon>
            <Microphone />
          </el-icon>
          <div style="text-align: right; width: 100%">
            <el-button type="primary" size="mini" @click="click_dj">{{
              $t("commKey.Confirm")
            }}</el-button>
          </div>
        </div>
        <div class="lc" v-if="rightClickAct == 'lc'">
          <el-form-item :label="$t('map.fanyi15')" size="normal">
            <el-input placeholder="" size="normal" clearable v-model="lc_val">
              <template #append>{{ $t("map.fanyi16") }}</template>
            </el-input>
          </el-form-item>
          <div style="text-align: right">
            <el-button type="primary" size="medium" @click="click_lc">{{
              $t("map.btnSend")
            }}</el-button>
          </div>
        </div>
        <div class="jssz" v-if="rightClickAct == 'jssz'">
          <el-row :gutter="20">
            <el-col :span="12" :offset="0">
              <el-form-item
                :label="$t('map.fanyi17')"
                size="normal"
                label-width="130px"
              >
                <el-input
                  placeholder=""
                  size="normal"
                  clearable
                  v-model="jssz_temp.zgsu_val"
                >
                  <template #append>(0~300) KM/H</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" :offset="0">
              <el-form-item
                :label="$t('map.fanyi18')"
                size="normal"
                label-width="130px"
              >
                <el-input
                  placeholder=""
                  size="normal"
                  clearable
                  v-model="jssz_temp.cscxsj_val"
                >
                  <template #append>{{ $t("map.fanyi19") }}</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12" :offset="0">
              <el-form-item
                :label="$t('map.fanyi20')"
                size="normal"
                label-width="130px"
              >
                <el-input
                  placeholder=""
                  size="normal"
                  clearable
                  v-model="jssz_temp.dtljsj_val"
                >
                  <template #append>{{ $t("map.fanyi19") }}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" :offset="0">
              <el-form-item
                :label="$t('map.fanyi21')"
                size="normal"
                label-width="130px"
              >
                <el-input
                  placeholder=""
                  size="normal"
                  clearable
                  v-model="jssz_temp.lxjssj_val"
                >
                  <template #append>{{ $t("map.fanyi19") }}</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12" :offset="0">
              <el-form-item
                :label="$t('map.fanyi22')"
                size="normal"
                label-width="130px"
              >
                <el-input
                  placeholder=""
                  size="normal"
                  clearable
                  v-model="jssz_temp.zctcsj_val"
                >
                  <template #append>{{ $t("map.fanyi19") }}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" :offset="0">
              <el-form-item
                :label="$t('map.fanyi23')"
                size="normal"
                label-width="130px"
              >
                <el-input
                  placeholder=""
                  size="normal"
                  clearable
                  v-model="jssz_temp.zxxxsj_val"
                >
                  <template #append>{{ $t("map.fanyi19") }}</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div style="text-align: right">
            <el-button type="primary" size="medium" @click="click_get_jssz">
              {{ $t("map.fanyi44") }}</el-button
            >
            <el-button type="primary" size="medium" @click="click_jssz">{{
              $t("map.btnSend")
            }}</el-button>
          </div>
        </div>
        <div class="clxx" v-if="rightClickAct == 'clxx'">
          <el-row :gutter="10">
            <el-col :span="6" :offset="0">
              <el-descriptions
                class="margin-top"
                :title="$t('map.fanyi24')"
                :column="1"
                border
              >
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">{{ $t("map.cph_name") }}</div>
                  </template>
                  {{ veh_data.vPlate }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">{{ $t("map.ssgs_name") }}</div>
                  </template>
                  {{ veh_data.cName }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">
                      {{ $t("map.group_name") }}
                    </div>
                  </template>
                  {{ veh_data.gName }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">{{ $t("map.zhongduan") }}</div>
                  </template>
                  {{ veh_data.dName }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">SIM</div>
                  </template>
                  {{ veh_data.vSimNo }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">{{ $t("map.fanyi25") }}</div>
                  </template>
                  {{ rightTreeData.dev_type_sn }}
                </el-descriptions-item>
              </el-descriptions>
            </el-col>
            <el-col :span="18" :offset="0">
              <el-descriptions
                class="margin-top"
                :title="$t('map.fanyi26')"
                :column="1"
                border
              >
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">{{ $t("map.fanyi27") }}</div>
                  </template>
                  {{ clxx_temp.bb_v }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">{{ $t("map.fanyi28") }}</div>
                  </template>
                  {{ clxx_temp.imei_v }}
                </el-descriptions-item>
              </el-descriptions>
              <el-descriptions
                class="margin-top"
                :title="$t('map.fanyi29')"
                :column="2"
                border
              >
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">{{ $t("map.fanyi30") }}</div>
                  </template>
                  {{ clxx_temp.wllx_v }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">{{ $t("map.fanyi31") }}</div>
                  </template>
                  {{ clxx_temp.wxxh_v }}
                </el-descriptions-item>
                <el-descriptions-item :span="2">
                  <template #label>
                    <div class="cell-item">{{ $t("map.fanyi32") }}</div>
                  </template>
                  {{ clxx_temp.wx_wldz_v }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">{{ $t("map.fanyi33") }}</div>
                  </template>
                  {{ clxx_temp.wifimc_v }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">{{ $t("map.fanyi34") }}</div>
                  </template>
                  {{ clxx_temp.wifixh_v }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label>
                    <div class="cell-item">{{ $t("map.wangluodizhi") }}</div>
                  </template>
                  {{ clxx_temp.wifi_wldz_v }}
                </el-descriptions-item>
              </el-descriptions>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12" :offset="0">
              <span>{{ $t("map.fanyi35") }}</span>
              <el-table :data="tableData_lx" style="width: 100%">
                <el-table-column
                  prop="ttmc"
                  :label="$t('map.fanyi36')"
                  width="180"
                />
                <el-table-column
                  prop="splx"
                  :label="$t('map.fanyi37')"
                  width="180"
                />
                <el-table-column prop="spds" :label="$t('map.fanyi38')" />
              </el-table>
            </el-col>
            <el-col :span="12" :offset="0">
              <span>{{ $t("map.fanyi39") }}</span>
              <el-table :data="tableData_cc" style="width: 100%">
                <el-table-column
                  prop="cp"
                  :label="$t('map.fanyi40')"
                  width="180"
                />
                <el-table-column
                  prop="zrl"
                  :label="$t('map.fanyi41')"
                  width="180"
                />
                <el-table-column prop="syrl" :label="$t('map.fanyi42')" />
              </el-table>
            </el-col>
          </el-row>
        </div>
        <div class="cssz" v-if="rightClickAct == 'cssz'">
          <el-row :gutter="20">
            <el-col :span="6" :offset="0">
              <div class="cssz-1">
                <el-tree
                  :data="cssztreeData"
                  :props="csszProps"
                  :default-expand-all="true"
                  @node-click="cssszHandleNodeClick"
                />
              </div>
            </el-col>

            <el-col
              :span="18"
              :offset="0"
              id="ccsz"
              style="padding-right: 30px !important"
            >
              <el-form-item
                v-for="it in cssztreeItems"
                :label="it.label"
                size="normal"
                :label-width="it.width"
                :key="curr_tree_node.id + '_' + it.key"
              >
                <el-input
                  v-if="it.type == 'ipt'"
                  placeholder=""
                  size="normal"
                  clearable
                  v-model="it.value"
                >
                  <template v-if="it.unit != ''" #append>{{
                    it.unit
                  }}</template>
                </el-input>
                <el-select
                  v-if="it.type == 'sel'"
                  v-model="it.value"
                  :placeholder="it.label"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in it.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value.toString()"
                  >
                  </el-option>
                </el-select>
                <div class="flex-row" v-if="it.type == 'list'">
                  <div v-for="ik in it.list" :key="'list' + ik.key">
                    <el-input
                      v-if="ik.type == 'ipt'"
                      placeholder=""
                      size="normal"
                      clearable
                      v-model="ik.value"
                    >
                      <template v-if="ik.unit != ''" #append>{{
                        ik.unit
                      }}</template>
                    </el-input>
                    <el-select
                      v-if="ik.type == 'sel'"
                      v-model="ik.value"
                      :placeholder="ik.label"
                      clearable
                      filterable
                    >
                      <el-option
                        v-for="item in ik.options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value.toString()"
                      >
                      </el-option>
                    </el-select>
                    <span class="it-flex" v-if="ik.type == 'span'">
                      {{ ik.label }}
                    </span>
                    <el-button
                      type="primary"
                      v-if="ik.type == 'btn'"
                      @click="typeof ik.click === 'function' && it.click"
                      >{{ ik.label }}</el-button
                    >
                  </div>
                </div>
                <el-checkbox-group v-if="it.type == 'chks'" v-model="it.value">
                  <el-checkbox
                    v-for="ic in it.options"
                    :key="'chk' + ic.value"
                    :label="ic.value"
                    >{{ ic.label }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-if="rightTreeData.dev_type_sn == '81801'">
            <el-col :span="6" :offset="0"></el-col>
            <el-col :span="18" :offset="0"
              ><div style="text-align: right">
                <el-button
                  type="primary"
                  size="medium"
                  @click="click_cssz_set"
                  >{{ $t("map.fanyi43") }}</el-button
                >
                <el-button
                  type="primary"
                  size="medium"
                  @click="click_cssz_get"
                  >{{ $t("map.fanyi44") }}</el-button
                >
              </div></el-col
            >
          </el-row>
          <el-row :gutter="20" v-if="rightTreeData.dev_type_sn == '81802'">
            <el-col :span="6" :offset="0"></el-col>
            <el-col :span="18" :offset="0"
              ><div style="text-align: right">
                <el-button
                  type="primary"
                  size="medium"
                  @click="click_cssz_set_81802"
                  >{{ $t("map.fanyi43") }}</el-button
                >
              </div></el-col
            >
          </el-row>
        </div>
      </el-dialog>
      <el-drawer
        v-model="drawer_history"
        :title="sel_cph"
        :direction="direction"
        :z-index="10001"
        size="80%"
        @open="open_history"
        @close="close_history"
        :append-to-body="true"
        :destroy-on-close="true"
      >
        <GpsHistory
          ref="gpsHistory"
          :devID="sel_devID"
          :cph="sel_cph"
          :mapExpID="map_history"
        ></GpsHistory>
      </el-drawer>
      <!-- 右键菜单部分 -->
      <ul
        v-show="visible"
        :style="{ left: left + 'px', top: top + 'px' }"
        class="contextmenu"
      >
        <li @click="handleCloseVideo">Video OFF</li>
        <li @click="handleCloseAllVideo">Video All OFF</li>
      </ul>
      <!----------------------------->
      <el-drawer
        v-model="drawer_back"
        :title="sel_cph"
        :direction="direction"
        :z-index="10001"
        size="80%"
        @open="open_back"
        :append-to-body="true"
        :destroy-on-close="true"
        @close="close_back"
      >
        <GpsBack
          ref="gpsBack"
          :devID="sel_devID"
          :cph="sel_cph"
          :chNum="ch_num"
          :dev_id="dev_id"
        >
        </GpsBack>
      </el-drawer>
      <!----------------------------->
      <GpsForm
        :formArray="formArray"
        :dialogTitle="$t('cars.carTitle')"
        ref="gpsCar"
        :row="row_car"
        @saveEvent="saveEvent"
      ></GpsForm>
      <!---------------------------->
      <el-dialog v-model="dialogVisible_close_tip" title="Video" width="500px">
        <span>{{ $t("map.sfjxgk") }}</span>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="video_close_handler">{{
              $t("map.gbts")
            }}</el-button>
            <el-button type="primary" @click="video_jixun_handler">
              {{ $t("map.jx") }}
            </el-button>
          </div>
        </template>
      </el-dialog>
    </pane>
  </splitpanes>
</template>

<script>
import { fa } from "element-plus/es/locale";
// import {
//   Microphone,
//   VideoCamera,
//   Headset,
//   Location,
//   Expand,
//   RefreshLeft,
// } from "@element-plus/icons-vue";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
// import MapComponent from "../components/map/MapComponent.vue";

// import LeafletMap from "../components/LeafletMap.vue";
import { imgData, car_dir, getHTTPURL } from "../utils/car";
import GpsSocket from "../utils/GpsSocket";
import { dateFormat, int2time } from "../utils/tools";
import Tree2Ref from "../utils/tree2ref";
import GpsVideo from "../components/GpsVideo.vue";
// 加载脚本库
// import "../assets/intercom/recorder-core.js";
// import "../assets/intercom/WSClient.js";
// import "../assets/intercom/resampler.js";
// import "../assets/intercom/recorder-util.js";
// import "../assets/intercom/flv.js";
// import "../assets/intercom/video.js";
// import "../assets/intercom/map.js";
import GpsHistory from "../components/GpsHistory.vue";
// import { SwitchButton } from "@element-plus/icons-vue";
import GpsMap from "../components/GpsMap.vue";
import GpsBack from "../components/GpsBack.vue";
import GpsForm from "./syssets/components/GpsForm.vue";
import CarData from "./syssets/CarsData.js";
import { ElLoading, ElNotification } from "element-plus";
import refresh_table from "../utils/refrshTable";
import moment from "moment";
import VueCookies from "vue-cookies";

export default {
  components: {
    Splitpanes,
    Pane,
    GpsVideo,
    GpsHistory,
    GpsMap,
    GpsBack,
    GpsForm,
  },
  data() {
    let obj_alarm_level = {
      st_1: this.$t("map.yijibaojing"),
      st_2: this.$t("map.erjibaojing"),
    };
    return {
      // treeDataTemp: [],
      video_close_timer_out: null,
      dialogVisible_close_tip: false,
      video_close_min: 2,
      video_close_timer: null,

      ay_video_obj: [],
      row_car: {},
      num: 4,
      dev_id: 0,
      ch_num: 0,
      is_show_loading: 0,
      curr_car: 0,
      is_alarm_map: 0,
      is_online_map: 0,
      loadingInstance: null,
      formArray: CarData.formCols,
      activeIndex: 1,
      checks: false,
      sel_row: {},
      tree_props: {
        label: "node_name",
        value: "node_id",
        children: "children",
      },
      tree_id: "a_0",
      tree_option: [],
      background: false,
      disabled: false,
      car_query: { rp: 8, page: 1, company_id: 0, group_id: 0, flag: 1 },
      car_list: [],
      car_total: 0,
      list_group: [],
      group_id: "",
      cpy_id: "",
      list_company: [],
      dataAlram: [],
      dialogW: "880px",
      drawer_back: false,
      activeName: "first",
      options: [
        {
          value: "1",
          label: this.$t("map.quanbu"),
        },
        {
          value: "2",
          label: this.$t("map.zaixian"),
        },
        {
          value: "3",
          label: this.$t("map.lixian"),
        },
      ],
      value: "",
      mapList: [
        {
          id: 1,
          name: this.$t("map.guge"),
        },
        {
          id: 2,
          name: this.$t("map.baidu"),
        },
      ],
      treeDataTemp: [],
      row_total: {},
      video_idx: 0,
      tree_pid: [],
      timer: null,
      ay_veh: [],
      xx_row: [
        {
          name: this.$t("map.zt_1"),
          value: 10,
          cls: "green",
        },
        {
          name: this.$t("map.xingshi"),
          value: 10,
          cls: "green",
        },
        {
          name: this.$t("map.zt_0"),
          value: 10,
          cls: "gray",
        },
        {
          name: this.$t("map.zt_2"),
          value: 10,
          cls: "red",
        },
        {
          name: this.$t("map.daisu"),
          value: 10,
          cls: "primary",
        },
        {
          name: this.$t("map.tingche"),
          value: 10,
          cls: "warning",
        },
        {
          name: this.$t("map.weidingwei"),
          value: 10,
          cls: "orange",
        },
        {
          name: this.$t("map.dian"),
          value: 10,
          cls: "green",
        },
        {
          name: this.$t("map.juxingquyu"),
          value: 10,
          cls: "green",
        },
        {
          name: this.$t("map.xian"),
          value: 10,
          cls: "green",
        },
        {
          name: this.$t("map.yuanxingquyu"),
          value: 10,
          cls: "green",
        },
      ],
      visible: false,
      left: 0,
      top: 0,
      videoMap: new Map(),
      videoAy: [],
      videoIdx: 0,
      defaultExpandedKeys: [],
      isShowVideo: false,
      treeH: window.innerHeight - 145,
      sel_cph: "",
      sel_devID: "",
      drawer_history: false,
      direction: "rtl",
      ay_player: [],
      ww: 300,
      hh: 200,
      boxes: 4,
      row_curr_cssz: {},
      tableData_lx: [],
      tableData_cc: [],
      clxx_temp: {},
      veh_data: {},
      jssz_temp: {
        zgsu_val: "",
        cscxsj_val: "",
        dtljsj_val: "",
        lxjssj_val: "",
        zctcsj_val: "",
        zxxxsj_val: "",
      },
      lc_val: "",
      dj_val: "1",
      clkz_temp: { carMen: "1", Circuit: "1" },
      gps_temp: { timeValue: "", xm_timeValue: "" },
      dev_type_sn: "",
      xfwz_tableData: [],
      xfwz_val: "",
      rightTreeNode: {},
      rightTreeData: {},
      rightClickWidth: "1000px",
      rightClickTitle: "11111222",
      dataCar: [
        // {
        //   id: "50",
        //   cph: "801234567892",
        //   dev_id: "50",
        //   dev_name: "801234567892",
        //   cpy_name: "test",
        //   vInstaller: null,
        //   lAlarmStartTime: "2023-08-20 14:34:34",
        //   lAlarmStartRevTime: "2023-08-20 14:34:35",
        //   lRecvTime: "2023-08-20 15:02:35",
        //   lng: "113.918193",
        //   lat: "22.694223",
        //   lAlarmFlag: "",
        //   lStatusFlag: "801234567892",
        //   lStatus: 786435,
        //   l2GBaseStation: null,
        //   l3GBaseStation: null,
        //   gps_time: "2023-08-20 15:02:33",
        //   wh_name: "",
        //   wh_tel: "",
        //   zx_state: 1,
        //   bj_sj: "2023-08-20 15:02:33",
        //   bj_nr: "1",
        //   islatlng: 1,
        //   drive_name: "",
        //   drive_tel: "",
        //   alarmlevel: 0,
        //   alarmguid: "",
        // },
      ],
      columns: [
        { field: "cpy_name", label: this.$t("map.ssgs_name"), width: "150px" },
        { field: "cphm", label: this.$t("map.cph_name"), width: "150px" },
        {
          field: "dwsj",
          label: this.$t("map.zxbjsj_name"),
          width: "150px",
        },
        {
          field: "address",
          label: this.$t("map.bjwz_name"),
          width: "200px",
          type: "fun",
          render: (row) => {
            // console.log(row);
            if (row.address == "") this.get_gps(row, "lng", "lat", "address");
            return row.address;
          },
        },
        { field: "sudu", label: this.$t("map.sudu") },
        // {
        //   field: "alarmlevel",
        //   label: this.$t("map.bjjb"),
        //   width: "100px",
        //   type: "fun",
        //   render: (row) => {
        //     let pos = "";
        //     if (row["alarmlevel"] == 0) {
        //       pos = "";
        //     } else {
        //       pos = "<span>" + obj_alarm_level["st_" + row[name]] + "</span>";
        //     }
        //     return pos;
        //   },
        // },

        // {
        //   field: "ckmx",
        //   label: this.$t("map.ckmx"),
        //   width: "100px",
        //   type: "fun",
        //   render: (row) => {
        //     let pos = "";
        //     if (row["alarmguid"] == "") {
        //       pos = "";
        //     } else {
        //       pos =
        //         '<span id="ckmx" data-value="' +
        //         row["alarmguid"] +
        //         '"><i class="fa fa-eye" style="color: #00a0e9"  aria-hidden="true"></i></span>';
        //     }
        //     return pos;
        //   },
        // },

        {
          field: "drive_name",
          label: this.$t("map.sjxm_name"),
          width: "100px",
        },
        { field: "drive_tel", label: this.$t("map.sjdh_name"), width: "100px" },
        {
          field: "bj",
          label: this.$t("map.zxbjnr_name"),
          width: "100px",
          type: "fun",
          render: (row) => {
            var ay_str = row["lAlarmMsg"];

            var ay_ay = ay_str.split(",");

            var ay_result = [];
            var str_temp = "";
            var str_a, ay_a, j;
            var str_str, str_ay;
            var result_ay = [];
            for (var i = 0; i < ay_ay.length; i++) {
              if (ay_ay[i] >= 217 && ay_ay[i] <= 224) continue;
              if (ay_ay[i] == "") continue;
              str_temp = this.$t("map.e_" + ay_ay[i]);
              if (ay_ay[i] == 101) {
                str_a = row["lVideoLossStatus"];
                ay_a = str_a.split(",");
                for (j = 0; j < ay_a.length; j++) {
                  ay_a[j] = "CH" + ay_a[j];
                }
                str_temp += ay_a.join(",");
              } else if (ay_ay[i] == 103) {
                str_str = row["lVideoMemoryFailureStatus"];
                str_ay = str_str.split(",");
                result_ay = [];
                for (var ik = 0; ik < str_ay.length; ik++) {
                  if (str_ay[ik] != "") {
                    if (str_ay[ik] == 1) {
                      result_ay.push("HDD");
                    } else {
                      if (str_ay[ik] == 2) {
                        result_ay.push("SD");
                      } else {
                        result_ay.push("SD" + (parseInt(str_ay[ik]) - 1));
                      }
                    }
                  }
                }
                str_temp += result_ay.join(",");
              } else if (ay_ay[i] == 102) {
                str_a = row["lVideoBlockingStatus"];
                ay_a = str_a.split(",");
                for (j = 0; j < ay_a.length; j++) {
                  ay_a[j] = "CH" + ay_a[j];
                }
                str_temp += ay_a.join(",");
              }

              ay_result.push(str_temp);
            }

            //不录像
            var str_blx = row["lRecordState"];
            if (str_blx != "") {
              var str_temp_blx = this.$t("map.blx") + ",";
              var ay_blx = str_blx.split(",");
              for (var i = 0; i < ay_blx.length; i++) {
                ay_blx[i] = "CH" + ay_blx[i];
              }
              str_temp_blx += ay_blx.join(",");
              ay_result.push(str_temp_blx);
            }

            var str_output = ay_result.join(",");

            str_output = str_output.replace(/(^[\s,]*)|([\s,]*$)/g, "");
            // let bj = row["bj"];
            // let bj_str = "";

            // if (bj != "" && bj != null) {
            //   if (
            //     bj != "" &&
            //     parseInt(bj) > 0 &&
            //     typeof this.$t("map.e_" + bj) != "undefined"
            //   ) {
            //     bj_str = this.$t("map.e_" + bj);
            //   }
            // }
            return '<span class="tree_red">' + str_output + "</span>";
          },
        },

        { field: "licheng", label: this.$t("map.licheng") },
        {
          field: "zt",
          label: this.$t("map.zhuangtai"),
          type: "fun",
          render: (row) => {
            return this.$t("map.zt_" + row.st);
          },
        },
        // { field: "miaosu", label: "描述" },
      ],
      mapBottomHeight: 30,
      paneSize: 18,
      csszProps: {
        children: "children",
        label: "name",
      },
      curr_tree_node: { id: 0 },
      gshlx_val: "",
      gshlx_options: [
        { value: 1, label: this.$t("map.yingpan") },
        { value: 5, label: "sd1" },
        { value: 6, label: "sd2" },
      ],
      cssztreeData: [],
      cssztreeData_81802: [
        {
          id: 1,
          name: this.$t("map.xitongguanli"),
          children: [
            // {
            //   id: 101,

            //   name: "信息",
            // },
            {
              id: 102,
              name: this.$t("map.zhongduanshezhi"),
              xmlrootname: "JTBASE",
              MESSAGE_ID: "",
              nodeName: "zdsz",
            },
            {
              id: 104,
              name: this.$t("map.xitongshizhong"),
              xmlrootname: "CLOCK",
              MESSAGE_ID: "",
              nodeName: "xtsz",
            },
            {
              id: 103,
              name: this.$t("map.xialingshikaiguan"),
              xmlrootname: "DST",
              MESSAGE_ID: "",
              nodeName: "dst",
            },

            {
              id: 105,
              name: this.$t("map.dianyuanguanli"),
              xmlrootname: "POWER",
              MESSAGE_ID: "",
              nodeName: "dygl",
            },
            {
              id: 106,
              name: this.$t("map.fujiashezhi"),
              xmlrootname: "GBJTPARA",
              MESSAGE_ID: "",
              nodeName: "fjsz",
            },
          ],
        },

        {
          id: 2,
          name: this.$t("map.luxiangshezhi"),
          children: [
            {
              id: 201,
              name: this.$t("map.jibenshezhi"),
              xmlrootname: "RECORD",
              MESSAGE_ID: "",
              nodeName: "jbsz",
            },
            {
              id: 206,
              name: this.$t("map.zhumaliu"),
              xmlrootname: "RECORD",
              MESSAGE_ID: "",
              nodeName: "zlm",
            },
            {
              id: 207,
              name: this.$t("map.zimaliu"),
              xmlrootname: "RECORD",
              MESSAGE_ID: "",
              nodeName: "zilm",
            },
            // {
            //   id: 208,
            //   name: "定时录像",
            // },
            {
              id: 202,
              name: this.$t("map.cunchushezhi"),
              xmlrootname: "STORE",
              MESSAGE_ID: "",
              nodeName: "ccsz",
            },
            // {
            //   id: 203,
            //   name: "信息叠加",
            // },
            // {
            //   id: 204,
            //   name: "ICP设置",
            // },
          ],
        },

        {
          id: 3,
          name: this.$t("map.wangluoshezhi"),
          children: [
            {
              id: 301,
              name: this.$t("map.zhongxinshezhi"),
              xmlrootname: "SERVER",
              MESSAGE_ID: "",
              nodeName: "zxsz",
            },
            // {
            //   id: 302,
            //   name: "本地设置",
            // },
            {
              id: 303,
              name: this.$t("map.bohaoshezhi"),
              xmlrootname: "DIALUP",
              MESSAGE_ID: "",
              nodeName: "bhsz",
            },
            {
              id: 305,
              name: this.$t("map.shengjifuwuqi"),
              xmlrootname: "UPGRADE",
              MESSAGE_ID: "",
              nodeName: "sjfwq",
            },
          ],
        },

        {
          id: 4,
          name: this.$t("map.baojinghewaishe"),
          children: [
            {
              id: 401,
              name: this.$t("map.fanyi59"),
              xmlrootname: "IOSET",
              MESSAGE_ID: "",
              nodeName: "io",
            },
            {
              id: 402,
              name: this.$t("map.fanyi58"),
              xmlrootname: "SPEED",
              MESSAGE_ID: "",
              nodeName: "csbj",
            },
            {
              id: 403,
              name: this.$t("map.fanyi60"),
              xmlrootname: "DRIVINGPOSTURE_CONFIG",
              MESSAGE_ID: "",
              nodeName: "jsd",
            },
            // {
            //   id: 403,
            //   name: "移动侦测",
            // },
            // {
            //   id: 403,
            //   name: "电压报警",
            // },
            // {
            //   id: 403,
            //   name: "串口管理",
            // },
            // {
            //   id: 403,
            //   name: "云台控制",
            // },
          ],
        },
      ],
      cssztreeData_81801: [
        {
          id: 1,
          name: this.$t("map.fanyi45"),
          children: [
            {
              id: 101,
              name: this.$t("map.fanyi46"),
              xmlrootname: "Param_Terminal_Info",
              MESSAGE_ID: "",
              nodeName: "jcsz",
            },
            {
              id: 102,
              name: this.$t("map.fanyi47"),
              xmlrootname: "Param_Base_Info",
              MESSAGE_ID: "",
              nodeName: "sjsz",
            },
            {
              id: 103,
              name: this.$t("map.fanyi48"),
              xmlrootname: "Param_SysPowerSet",
              MESSAGE_ID: "",
              nodeName: "dysz",
            },
          ],
        },
        {
          id: 2,
          name: this.$t("map.fanyi49"),
          children: [
            {
              id: 201,
              name: this.$t("map.fanyi50"),
              xmlrootname: "Param_RecordSet",
              MESSAGE_ID: "",
              nodeName: "lxsz",
            },
            {
              id: 202,
              name: this.$t("map.fanyi51"),
              xmlrootname: "Param_RecordCodeSet",
              MESSAGE_ID: "",
              nodeName: "bmsz",
            },
            {
              id: 203,
              name: this.$t("map.fanyi52"),
              xmlrootname: "Param_DisplaySet",
              MESSAGE_ID: "",
              nodeName: "xssz",
            },
            {
              id: 204,
              name: this.$t("map.fanyi53"),
              xmlrootname: "Param_DiskUseSet",
              MESSAGE_ID: "",
              nodeName: "chsz",
            },
          ],
        },
        {
          id: 3,
          name: this.$t("map.fanyi54"),
          children: [
            {
              id: 301,
              name: this.$t("map.fanyi55"),
              xmlrootname: "Param_DialSet",
              MESSAGE_ID: "",
              nodeName: "4gsz",
            },
            {
              id: 302,
              name: this.$t("map.fanyi56"),
              xmlrootname: "Param_CenterNetWork",
              MESSAGE_ID: "",
              nodeName: "zxsz",
            },
          ],
        },
        {
          id: 4,
          name: this.$t("map.fanyi57"),
          children: [
            {
              id: 401,
              name: this.$t("map.fanyi58"),
              xmlrootname: "Param_SpeedSet",
              MESSAGE_ID: "",
              nodeName: "sdbj",
            },
            {
              id: 402,
              name: this.$t("map.fanyi59"),
              xmlrootname: "Param_IoSet",
              MESSAGE_ID: "",
              nodeName: "iobj",
            },
            {
              id: 403,
              name: this.$t("map.fanyi60"),
              xmlrootname: "Param_G_SensorSet",
              MESSAGE_ID: "",
              nodeName: "jsdbj",
            },
            {
              id: 404,
              name: this.$t("map.fanyi61"),
              xmlrootname: "Param_TemperateSet",
              MESSAGE_ID: "",
              nodeName: "wdbj",
            },
            {
              id: 405,
              name: this.$t("map.fanyi62"),
              xmlrootname: "Param_VoltageSet",
              MESSAGE_ID: "",
              nodeName: "dybj",
            },
          ],
        },
      ],
      cssztreeData_other: [],
      cssztreeItems: [],
      cssztreeItemsObj: [],
      cssztreeItemsObj_81802: {
        // a101: [
        //   {
        //     label: "引导程序",
        //     width: "130px",
        //     type: "ipt",
        //     value: "",
        //     key: "device_num",
        //     unit: "",
        //   },
        //   {
        //     label: "内核程序",
        //     width: "130px",
        //     type: "ipt",
        //     value: "",
        //     key: "device_num",
        //     unit: "",
        //   },
        //   {
        //     label: "文件系统",
        //     width: "130px",
        //     type: "ipt",
        //     value: "",
        //     key: "device_num",
        //     unit: "",
        //   },
        //   {
        //     label: "应用程序",
        //     width: "130px",
        //     type: "ipt",
        //     value: "",
        //     key: "device_num",
        //     unit: "",
        //   },
        //   {
        //     label: "单片机",
        //     width: "130px",
        //     type: "ipt",
        //     value: "",
        //     key: "device_num",
        //     unit: "",
        //   },
        //   {
        //     label: "硬件版本",
        //     width: "130px",
        //     type: "ipt",
        //     value: "",
        //     key: "device_num",
        //     unit: "",
        //   },
        // ],
        a102: [
          {
            label: this.$t("map.fanyi118"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "DevId",
            unit: "",
          },
          {
            label: this.$t("map.fanyi119"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "phonenum",
            unit: "",
          },
          {
            label: this.$t("map.chepaihao"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "license",
            unit: "",
          },
          {
            label: this.$t("map.zhongxinxieyi"),
            width: "130px",
            type: "sel",
            value: "",
            key: "protocol1",
            unit: "",
            options: [
              {
                label: "CMSV6",
                value: 1,
              },
              {
                label: "905",
                value: 10,
              },
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
            ],
          },
          {
            label: this.$t("map.zhongxinxieyi2"),
            width: "130px",
            type: "sel",
            value: "",
            key: "protocol2",
            unit: "",
            options: [
              {
                label: "CNMS",
                value: 4,
              },
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
            ],
          },
        ],
        a104: [
          {
            label: this.$t("map.xiaoshixuanxiang"),
            width: "130px",
            type: "sel",
            value: "",
            key: "switch",
            unit: "",
            options: [
              {
                label: this.$t("map.shoudong"),
                value: 0,
              },
              {
                label: this.$t("map.gpsxiaozhun"),
                value: 1,
              },
              {
                label: "NTP",
                value: 2,
              },
            ],
          },
          {
            label: this.$t("map.chaoshishijian"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "OprTimeOut",
            unit: "sec",
          },
          {
            label: this.$t("map.shiqu"),
            width: "130px",
            type: "list",
            value: "",
            key: "sqkey",
            unit: "sec",
            list: [
              {
                label: this.$t("map.shiqu"),
                width: "130px",
                type: "sel",
                value: "",
                key: "timezone",
                unit: "",
                options: [
                  {
                    label: "GMT-12",
                    value: -12,
                  },
                  {
                    label: "GMT-11",
                    value: -11,
                  },
                  {
                    label: "GMT-10",
                    value: -10,
                  },
                  {
                    label: "GMT-09",
                    value: -9,
                  },
                  {
                    label: "GMT-08",
                    value: -8,
                  },
                  {
                    label: "GMT-07",
                    value: -7,
                  },
                  {
                    label: "GMT-06",
                    value: -6,
                  },
                  {
                    label: "GMT-05",
                    value: -5,
                  },
                  {
                    label: "GMT-04",
                    value: -4,
                  },
                  {
                    label: "GMT-03",
                    value: -3,
                  },
                  {
                    label: "GMT-02",
                    value: -2,
                  },
                  {
                    label: "GMT-01",
                    value: -1,
                  },
                  {
                    label: "GMT+00",
                    value: 0,
                  },
                  {
                    label: "GMT+01",
                    value: 1,
                  },
                  {
                    label: "GMT+02",
                    value: 2,
                  },
                  {
                    label: "GMT+03",
                    value: 3,
                  },
                  {
                    label: "GMT+04",
                    value: 4,
                  },
                  {
                    label: "GMT+05",
                    value: 5,
                  },
                  {
                    label: "GMT+06",
                    value: 6,
                  },
                  {
                    label: "GMT+07",
                    value: 7,
                  },
                  {
                    label: "GMT+08",
                    value: 8,
                  },
                  {
                    label: "GMT+09",
                    value: 9,
                  },
                  {
                    label: "GMT+10",
                    value: 10,
                  },
                  {
                    label: "GMT+11",
                    value: 11,
                  },
                  {
                    label: "GMT+12",
                    value: 12,
                  },
                ],
              },
              {
                label: this.$t("map.shijian"),
                width: "130px",
                type: "sel",
                value: "",
                key: "timezone_sub",
                unit: "",
                options: [
                  {
                    label: "00",
                    value: 0,
                  },
                  {
                    label: "15",
                    value: 15,
                  },
                  {
                    label: "30",
                    value: 30,
                  },
                  {
                    label: "45",
                    value: 45,
                  },
                ],
              },
            ],
          },
          {
            label: this.$t("map.ntpfuwuqi"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "ntpserver",
            unit: "",
          },
          {
            label: this.$t("map.ntpduankou"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "ntpport",
            unit: "",
          },
        ],
        a103: [
          {
            label: this.$t("map.fanyi67"),
            width: "130px",
            type: "sel",
            value: "",
            key: "onoff",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: this.$t("map.kaiqi"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.kaishishijian"),
            width: "130px",
            type: "list",
            value: "",
            key: "sMonthkey",
            unit: "",
            list: [
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "sMonth",
                unit: "",
                options: [
                  { label: this.$t("map.yiyue"), value: 0 },
                  { label: this.$t("map.eryue"), value: 1 },
                  { label: this.$t("map.sanyue"), value: 2 },
                  { label: this.$t("map.siyue"), value: 3 },
                  { label: this.$t("map.wuyue"), value: 4 },
                  { label: this.$t("map.liuyue"), value: 5 },
                  { label: this.$t("map.qiyue"), value: 6 },
                  { label: this.$t("map.bayue"), value: 7 },
                  { label: this.$t("map.jiuyue"), value: 8 },
                  { label: this.$t("map.shiyue"), value: 9 },
                  { label: this.$t("map.shiyiyue"), value: 10 },
                  { label: this.$t("map.shieryue"), value: 11 },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "sWeek",
                unit: "",
                options: [
                  { label: this.$t("map.diyizhou"), value: 0 },
                  { label: this.$t("map.dierzhou"), value: 1 },
                  { label: this.$t("map.disanzhou"), value: 2 },
                  { label: this.$t("map.disizhou"), value: 3 },
                  { label: this.$t("map.diwuzhou"), value: 4 },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "sDate",
                unit: "",
                options: [
                  { label: this.$t("map.zhouyi"), value: 1 },
                  { label: this.$t("map.zhouer"), value: 2 },
                  { label: this.$t("map.zhousan"), value: 3 },
                  { label: this.$t("map.zhousi"), value: 4 },
                  { label: this.$t("map.zhouwu"), value: 5 },
                  { label: this.$t("map.zhouliu"), value: 6 },
                  { label: this.$t("map.zhouri"), value: 0 },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "sHour",
                unit: "",
                options: [
                  { label: "00:00:00", value: 0 },
                  { label: "01:00:00", value: 1 },
                  { label: "02:00:00", value: 2 },
                  { label: "03:00:00", value: 3 },
                  { label: "04:00:00", value: 4 },
                  { label: "05:00:00", value: 5 },
                  { label: "06:00:00", value: 6 },
                  { label: "07:00:00", value: 7 },
                  { label: "08:00:00", value: 8 },
                  { label: "09:00:00", value: 9 },
                  { label: "10:00:00", value: 10 },
                  { label: "11:00:00", value: 11 },
                  { label: "12:00:00", value: 12 },
                  { label: "13:00:00", value: 13 },
                  { label: "14:00:00", value: 14 },
                  { label: "15:00:00", value: 15 },
                  { label: "16:00:00", value: 16 },
                  { label: "17:00:00", value: 17 },
                  { label: "18:00:00", value: 18 },
                  { label: "19:00:00", value: 19 },
                  { label: "20:00:00", value: 20 },
                  { label: "21:00:00", value: 21 },
                  { label: "22:00:00", value: 22 },
                  { label: "23:00:00", value: 23 },
                ],
              },
            ],
          },
          {
            label: this.$t("map.jieshushijian"),
            width: "130px",
            type: "list",
            value: "",
            key: "eMonthkey",
            unit: "",
            list: [
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "eMonth",
                unit: "",
                options: [
                  { label: this.$t("map.yiyue"), value: 0 },
                  { label: this.$t("map.eryue"), value: 1 },
                  { label: this.$t("map.sanyue"), value: 2 },
                  { label: this.$t("map.siyue"), value: 3 },
                  { label: this.$t("map.wuyue"), value: 4 },
                  { label: this.$t("map.liuyue"), value: 5 },
                  { label: this.$t("map.qiyue"), value: 6 },
                  { label: this.$t("map.bayue"), value: 7 },
                  { label: this.$t("map.jiuyue"), value: 8 },
                  { label: this.$t("map.shiyue"), value: 9 },
                  { label: this.$t("map.shiyiyue"), value: 10 },
                  { label: this.$t("map.shieryue"), value: 11 },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "eWeek",
                unit: "",
                options: [
                  { label: this.$t("map.diyizhou"), value: 0 },
                  { label: this.$t("map.dierzhou"), value: 1 },
                  { label: this.$t("map.disanzhou"), value: 2 },
                  { label: this.$t("map.disizhou"), value: 3 },
                  { label: this.$t("map.diwuzhou"), value: 4 },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "eDate",
                unit: "",
                options: [
                  { label: this.$t("map.zhouyi"), value: 1 },
                  { label: this.$t("map.zhouer"), value: 2 },
                  { label: this.$t("map.zhousan"), value: 3 },
                  { label: this.$t("map.zhousi"), value: 4 },
                  { label: this.$t("map.zhouwu"), value: 5 },
                  { label: this.$t("map.zhouliu"), value: 6 },
                  { label: this.$t("map.zhouri"), value: 0 },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "eHour",
                unit: "",
                options: [
                  { label: "00:00:00", value: 0 },
                  { label: "01:00:00", value: 1 },
                  { label: "02:00:00", value: 2 },
                  { label: "03:00:00", value: 3 },
                  { label: "04:00:00", value: 4 },
                  { label: "05:00:00", value: 5 },
                  { label: "06:00:00", value: 6 },
                  { label: "07:00:00", value: 7 },
                  { label: "08:00:00", value: 8 },
                  { label: "09:00:00", value: 9 },
                  { label: "10:00:00", value: 10 },
                  { label: "11:00:00", value: 11 },
                  { label: "12:00:00", value: 12 },
                  { label: "13:00:00", value: 13 },
                  { label: "14:00:00", value: 14 },
                  { label: "15:00:00", value: 15 },
                  { label: "16:00:00", value: 16 },
                  { label: "17:00:00", value: 17 },
                  { label: "18:00:00", value: 18 },
                  { label: "19:00:00", value: 19 },
                  { label: "20:00:00", value: 20 },
                  { label: "21:00:00", value: 21 },
                  { label: "22:00:00", value: 22 },
                  { label: "23:00:00", value: 23 },
                ],
              },
            ],
          },
          {
            label: this.$t("map.pianyishijian"),
            width: "130px",
            type: "sel",
            value: "",
            key: "offset",
            unit: "",
            options: [
              {
                label: "15",
                value: 15,
              },
              {
                label: "30",
                value: 30,
              },
              {
                label: "45",
                value: 45,
              },
              {
                label: "60",
                value: 60,
              },
            ],
          },
        ],
        a105: [
          {
            label: this.$t("map.dianyuanmoshi"),
            width: "130px",
            type: "sel",
            value: "",
            key: "switch",
            unit: "",
            options: [
              {
                label: "ACC",
                value: 1,
              },
              {
                label: this.$t("map.dingshi"),
                value: 2,
              },
            ],
          },
          {
            label: this.$t("map.yanshiguanji"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "delay",
            unit: this.$t("map.fen") + "（0-1440）",
          },
          {
            label: this.$t("map.xiumianshangbao"),
            width: "130px",
            type: "sel",
            value: "",
            key: "hibernationReport",
            unit: "",
            options: [
              {
                label: this.$t("map.kaiqi"),
                value: 0,
              },
              {
                label: this.$t("map.guanbi"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.pingbaoyanshi"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "ScreenOffTime",
            unit: this.$t("map.fen") + "（0-1440）",
          },
          {
            label: this.$t("map.kaijishijian"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "PowerOnTime",
            unit: "",
          },
          {
            label: this.$t("map.guanjishijian"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "PowerOffTime",
            unit: "",
          },
          {
            label: this.$t("map.luxiangyanshi"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "AccOffRecTime",
            unit: "",
          },
          {
            label: this.$t("map.xihuoluxiang"),
            width: "130px",
            type: "chks",
            value: "",
            key: "chk_ch",
            unit: "",
            options: [
              {
                label: "CH1",
                value: 1,
              },
              {
                label: "CH2",
                value: 2,
              },
            ],
          },
          {
            label: this.$t("map.zidongweihu"),
            width: "130px",
            type: "sel",
            value: "",
            key: "TimeRebootEn",
            unit: "",
            options: [
              {
                label: this.$t("map.kaiqi"),
                value: 0,
              },
              {
                label: this.$t("map.guanbi"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.weihushijian"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "RebootTime",
            unit: "",
          },
        ],
        a106: [
          {
            label: this.$t("map.lianxujiashichaoshishijian"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "max_driver_time",
            unit: this.$t("map.xiumianshangbao") + this.$t("map.fen"),
          },
          {
            label: this.$t("map.dangrileijijiashichaoshi"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "maxDriverTimeDay",
            unit: this.$t("map.xiumianshangbao") + this.$t("map.fen"),
          },
        ],
        a201: [
          {
            label: this.$t("map.shipinzhishi"),
            width: "130px",
            type: "sel",
            value: "",
            key: "SysNorm",
            unit: "",
            options: [
              {
                label: "PAL",
                value: 0,
              },
              {
                label: "NTSC",
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.luxiangmoshi"),
            width: "130px",
            type: "sel",
            value: "",
            key: "RecMode",
            unit: "",
            options: [
              {
                label: this.$t("map.kaijiluxiang"),
                value: 0,
              },
              {
                label: this.$t("map.dingshiluxiang"),
                value: 1,
              },
              {
                label: this.$t("map.baojingluxiang"),
                value: 2,
              },
            ],
          },
          {
            label: this.$t("map.yinpinleixing"),
            width: "130px",
            type: "sel",
            value: "",
            key: "AudioType",
            unit: "",
            options: [
              {
                label: "G711A",
                value: 1,
              },
              {
                label: "G726",
                value: 3,
              },
            ],
          },
        ],
        a206: [
          {
            label: this.$t("map.tongdao"),
            width: "130px",
            type: "list",
            value: "",
            key: "Phonenumber",
            unit: "",
            list: [
              {
                type: "span",
                label: this.$t("map.fenbianlv"),
                value: "1",
                key: 1,
                unit: "",
              },
              {
                type: "span",
                label: this.$t("map.zhenglv"),
                value: "2",
                key: 2,
                unit: "",
              },
              {
                type: "span",
                label: this.$t("map.huazhi"),
                value: "3",
                key: 3,
                unit: "",
              },
              {
                type: "span",
                label: this.$t("map.luxiang"),
                value: "4",
                key: 4,
                unit: "",
              },
              {
                type: "span",
                label: this.$t("map.jingxiang"),
                value: "5",
                key: 5,
                unit: "",
              },
              {
                type: "span",
                label: this.$t("map.luyin"),
                value: "6",
                key: 6,
                unit: "",
              },
            ],
          },
          {
            label: "CH1",
            width: "130px",
            type: "list",
            value: "",
            key: "Phonenumber",
            unit: "",
            list: [
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "PowerMode",
                unit: "",
                options: [
                  {
                    label: "HD1080",
                    value: 0,
                  },
                  {
                    label: "HD720",
                    value: 1,
                  },
                  {
                    label: "D1",
                    value: 3,
                  },
                  {
                    label: "CIF",
                    value: 5,
                  },
                ],
              },
              {
                label: this.$t("map.zhenglv"),
                width: "130px",
                type: "sel",
                value: "",
                key: "PowerMode",
                unit: "",
                options: [
                  { label: 1, value: 1 },
                  { label: 2, value: 2 },
                  { label: 3, value: 3 },
                  { label: 4, value: 4 },
                  { label: 5, value: 5 },
                  { label: 6, value: 6 },
                  { label: 7, value: 7 },
                  { label: 8, value: 8 },
                  { label: 9, value: 9 },
                  { label: 10, value: 10 },
                  { label: 11, value: 11 },
                  { label: 12, value: 12 },
                  { label: 13, value: 13 },
                  { label: 14, value: 14 },
                  { label: 15, value: 15 },
                  { label: 16, value: 16 },
                  { label: 17, value: 17 },
                  { label: 18, value: 18 },
                  { label: 19, value: 19 },
                  { label: 20, value: 20 },
                  { label: 21, value: 21 },
                  { label: 22, value: 22 },
                  { label: 23, value: 23 },
                  { label: 24, value: 24 },
                  { label: 25, value: 25 },
                ],
              },
              {
                label: this.$t("map.huazhi"),
                width: "130px",
                type: "sel",
                value: "",
                key: "PowerMode",
                unit: "",
                options: [
                  { label: 1, value: 1 },
                  { label: 2, value: 2 },
                  { label: 3, value: 3 },
                  { label: 4, value: 4 },
                  { label: 5, value: 5 },
                  { label: 6, value: 6 },
                  { label: 7, value: 7 },
                  { label: 8, value: 8 },
                ],
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "PowerMode",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "PowerMode",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.zuoyoujingxiang"),
                    value: 1,
                  },
                  {
                    label: this.$t("map.shangxiajingxiang"),
                    value: 2,
                  },
                  {
                    label: this.$t("map.shangxiazuoyoujingxiang"),
                    value: 3,
                  },
                ],
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "PowerMode",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
            ],
          },
        ],
        a207: [
          {
            label: this.$t("map.fenbianlv"),
            width: "130px",
            type: "sel",
            value: "",
            key: "SubChn_chn0_Resolution",
            unit: "",
            options: [
              {
                label: "HD1080",
                value: 0,
              },
              {
                label: "HD720",
                value: 1,
              },
              {
                label: "D1",
                value: 3,
              },
              {
                label: "CIF",
                value: 5,
              },
            ],
          },
          {
            label: this.$t("map.zhenglv"),
            width: "130px",
            type: "sel",
            value: "",
            key: "SubChn_chn0_FrameRate",
            unit: "",
            options: [
              { label: 1, value: 1 },
              { label: 2, value: 2 },
              { label: 3, value: 3 },
              { label: 4, value: 4 },
              { label: 5, value: 5 },
              { label: 6, value: 6 },
              { label: 7, value: 7 },
              { label: 8, value: 8 },
              { label: 9, value: 9 },
              { label: 10, value: 10 },
              { label: 11, value: 11 },
              { label: 12, value: 12 },
              { label: 13, value: 13 },
              { label: 14, value: 14 },
              { label: 15, value: 15 },
              { label: 16, value: 16 },
              { label: 17, value: 17 },
              { label: 18, value: 18 },
              { label: 19, value: 19 },
              { label: 20, value: 20 },
              { label: 21, value: 21 },
              { label: 22, value: 22 },
              { label: 23, value: 23 },
              { label: 24, value: 24 },
              { label: 25, value: 25 },
            ],
          },
          {
            label: this.$t("map.huazhi"),
            width: "130px",
            type: "sel",
            value: "",
            key: "SubChn_chn0_Quality",
            unit: "",
            options: [
              { label: 1, value: 0 },
              { label: 2, value: 1 },
              { label: 3, value: 2 },
              { label: 4, value: 3 },
              { label: 5, value: 4 },
              { label: 6, value: 5 },
              { label: 7, value: 6 },
              { label: 8, value: 7 },
            ],
          },
        ],
        a202: [
          {
            label: this.$t("map.baojingyulu"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "prerecord",
            unit: this.$t("map.miao"),
          },
          {
            label: this.$t("map.baojingluxiangyanshi"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "delayrecord",
            unit: this.$t("map.miao"),
          },
          {
            label: this.$t("map.baojingwenjianbaohu"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "protectday",
            unit: this.$t("map.tian"),
          },
          {
            label: this.$t("map.baojingwenjianshangchuan"),
            width: "130px",
            type: "sel",
            value: "",
            key: "sendEnable",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: "CMSV6",
                value: 1,
              },
              {
                label: "FTP",
                value: 2,
              },
            ],
          },
          {
            label: this.$t("map.cipan") + "1",
            width: "130px",
            type: "sel",
            value: "",
            key: "diskinfo_disk0",
            unit: "",
            options: [
              {
                label: this.$t("map.wu"),
                value: 0,
              },
              {
                label: this.$t("map.zhuluxiang"),
                value: 1,
              },
              {
                label: this.$t("map.jingxiangluxiang"),
                value: 2,
              },
              {
                label: this.$t("map.beixuan"),
                value: 3,
              },
            ],
          },
          {
            label: this.$t("map.cipan") + "2",
            width: "130px",
            type: "sel",
            value: "",
            key: "diskinfo_disk1",
            unit: "",
            options: [
              {
                label: this.$t("map.wu"),
                value: 0,
              },
              {
                label: this.$t("map.zhuluxiang"),
                value: 1,
              },
              {
                label: this.$t("map.jingxiangluxiang"),
                value: 2,
              },
              {
                label: this.$t("map.beixuan"),
                value: 3,
              },
            ],
          },
          {
            label: this.$t("map.cipan") + "3",
            width: "130px",
            type: "sel",
            value: "",
            key: "diskinfo_disk2",
            unit: "",
            options: [
              {
                label: this.$t("map.wu"),
                value: 0,
              },
              {
                label: this.$t("map.zhuluxiang"),
                value: 1,
              },
              {
                label: this.$t("map.jingxiangluxiang"),
                value: 2,
              },
              {
                label: this.$t("map.beixuan"),
                value: 3,
              },
            ],
          },
        ],
        // a203: [
        //   {
        //     label: this.$t("map.fanyi82"),
        //     width: "130px",
        //     type: "sel",
        //     value: "",
        //     key: "PowerMode",
        //     unit: "",
        //     options: [
        //       {
        //         label: "ACC",
        //         value: "acc",
        //       },
        //       {
        //         label: this.$t("map.fanyi64"),
        //         value: "djms",
        //       },
        //     ],
        //   },
        //   {
        //     label: this.$t("map.fanyi89"),
        //     width: "130px",
        //     type: "sel",
        //     value: "",
        //     key: "PowerMode",
        //     unit: "",
        //     options: [
        //       {
        //         label: "ACC",
        //         value: "acc",
        //       },
        //       {
        //         label: this.$t("map.fanyi64"),
        //         value: "djms",
        //       },
        //     ],
        //   },
        //   {
        //     label: this.$t("map.fanyi90"),
        //     width: "130px",
        //     type: "sel",
        //     value: "",
        //     key: "PowerMode",
        //     unit: "",
        //     options: [
        //       {
        //         label: "ACC",
        //         value: "acc",
        //       },
        //       {
        //         label: this.$t("map.fanyi64"),
        //         value: "djms",
        //       },
        //     ],
        //   },
        // ],
        // a204: [
        //   {
        //     label: this.$t("map.fanyi91"),
        //     width: "130px",
        //     type: "sel",
        //     value: "",
        //     key: "PowerMode",
        //     unit: "",
        //     options: [
        //       {
        //         label: "ACC",
        //         value: "acc",
        //       },
        //       {
        //         label: this.$t("map.fanyi64"),
        //         value: "djms",
        //       },
        //     ],
        //   },
        //   {
        //     label: this.$t("map.fanyi92"),
        //     width: "130px",
        //     type: "sel",
        //     value: "",
        //     key: "PowerMode",
        //     unit: "",
        //     options: [
        //       {
        //         label: "ACC",
        //         value: "acc",
        //       },
        //       {
        //         label: this.$t("map.fanyi64"),
        //         value: "djms",
        //       },
        //     ],
        //   },
        // ],
        a301: [
          {
            label: this.$t("map.fanyi98") + "1",
            width: "130px",
            type: "ipt",
            value: "",
            key: "server0_mainip",
            unit: "",
          },
          {
            label: this.$t("map.fanyi98") + "1" + this.$t("map.duankou"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "server0_mainport",
            unit: "",
          },
          {
            label: this.$t("map.fanyi98") + "2",
            width: "130px",
            type: "ipt",
            value: "",
            key: "server1_mainip",
            unit: "",
          },
          {
            label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "server1_mainport",
            unit: "",
          },
        ],
        a303: [
          {
            label: this.$t("map.kaiguan"),
            width: "130px",
            type: "sel",
            value: "",
            key: "switch",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: this.$t("map.kaiqi"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.wangluoleixing"),
            width: "130px",
            type: "sel",
            value: "",
            key: "type",
            unit: "",
            options: [
              {
                label: "WCDMA",
                value: 0,
              },
              {
                label: "EVDO",
                value: 1,
              },
              {
                label: "TD-SCDMA",
                value: 2,
              },
              {
                label: "TDD-LTE",
                value: 3,
              },
              {
                label: "FDD-1",
                value: 4,
              },
              {
                label: "FDD-2",
                value: 5,
              },
            ],
          },
          {
            label: this.$t("map.jierudian"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "apn",
            unit: "",
          },
          {
            label: this.$t("map.zhongxinhaoma"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "servercode",
            unit: "",
          },
          {
            label: this.$t("map.yonghuming"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "user",
            unit: "",
          },
          {
            label: this.$t("map.mima"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "passwd",
            unit: "",
          },
        ],
        a305: [
          {
            label: "IP",
            width: "130px",
            type: "ipt",
            value: "",
            key: "ip",
            unit: "",
          },
          {
            label: this.$t("map.duankou"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "port",
            unit: "",
          },
          {
            label: this.$t("map.yonghuming"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "user",
            unit: "",
          },
          {
            label: this.$t("map.mima"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "passwd",
            unit: "",
          },
        ],
        a401: [
          {
            label: this.$t("map.bianhao"),
            width: "70px",
            type: "list",
            value: "",
            key: "Phonenumber",
            unit: "",
            list: [
              {
                type: "span",
                label: this.$t("map.mingcheng"),
                value: "1",
                key: 1,
                unit: "",
              },
              {
                type: "span",
                label: this.$t("map.dianping"),
                value: "2",
                key: 2,
                unit: "",
              },
              {
                type: "span",
                label: this.$t("map.yanshi"),
                value: "3",
                key: 3,
                unit: "",
              },
              {
                type: "span",
                label: this.$t("map.fangdou"),
                value: "4",
                key: 4,
                unit: "",
              },
              {
                type: "span",
                label: this.$t("map.luxiang"),
                value: "5",
                key: 5,
                unit: "",
              },
              {
                type: "span",
                label: this.$t("map.baojingliandong"),
                value: "6",
                key: 6,
                unit: "",
              },
            ],
          },
          {
            label: "IN1",
            width: "70px",
            type: "list",
            value: "",
            key: "inkey",
            unit: "",
            list: [
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn0_enable",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.jinji"),
                    value: 1,
                  },
                  {
                    label: this.$t("map.qianmen"),
                    value: 2,
                  },
                  {
                    label: this.$t("map.zhongmen"),
                    value: 3,
                  },
                  {
                    label: this.$t("map.houmen"),
                    value: 4,
                  },
                  {
                    label: this.$t("map.youzhuan"),
                    value: 9,
                  },
                  {
                    label: this.$t("map.zuozhuan"),
                    value: 10,
                  },
                  {
                    label: this.$t("map.shache"),
                    value: 11,
                  },
                  {
                    label: this.$t("map.daoche"),
                    value: 12,
                  },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn0_limit",
                unit: "",
                options: [
                  {
                    label: "Low",
                    value: 0,
                  },
                  {
                    label: "High",
                    value: 1,
                  },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "ipt",
                value: "",
                key: "input_chn0_delay",
                unit: "",
              },
              {
                label: "",
                width: "130px",
                type: "ipt",
                value: "",
                key: "input_chn0_holdtime",
                unit: "",
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn0_record",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.baojingliandong"),
                width: "130px",
                type: "btn",
                value: "",
                key: 100,
                unit: "",
              },
            ],
          },
          {
            label: "IN2",
            width: "70px",
            type: "list",
            value: "",
            key: "inkey",
            unit: "",
            list: [
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn1_enable",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.jinji"),
                    value: 1,
                  },
                  {
                    label: this.$t("map.qianmen"),
                    value: 2,
                  },
                  {
                    label: this.$t("map.zhongmen"),
                    value: 3,
                  },
                  {
                    label: this.$t("map.houmen"),
                    value: 4,
                  },
                  {
                    label: this.$t("map.youzhuan"),
                    value: 9,
                  },
                  {
                    label: this.$t("map.zuozhuan"),
                    value: 10,
                  },
                  {
                    label: this.$t("map.shache"),
                    value: 11,
                  },
                  {
                    label: this.$t("map.daoche"),
                    value: 12,
                  },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn1_limit",
                unit: "",
                options: [
                  {
                    label: "Low",
                    value: 0,
                  },
                  {
                    label: "High",
                    value: 1,
                  },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "ipt",
                value: "",
                key: "input_chn1_delay",
                unit: "",
              },
              {
                label: "",
                width: "130px",
                type: "ipt",
                value: "",
                key: "input_chn1_holdtime",
                unit: "",
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn1_record",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.baojingliandong"),
                width: "130px",
                type: "btn",
                value: "",
                key: 101,
                unit: "",
              },
            ],
          },
          {
            label: "IN3",
            width: "70px",
            type: "list",
            value: "",
            key: "inkey",
            unit: "",
            list: [
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn2_enable",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.jinji"),
                    value: 1,
                  },
                  {
                    label: this.$t("map.qianmen"),
                    value: 2,
                  },
                  {
                    label: this.$t("map.zhongmen"),
                    value: 3,
                  },
                  {
                    label: this.$t("map.houmen"),
                    value: 4,
                  },
                  {
                    label: this.$t("map.youzhuan"),
                    value: 9,
                  },
                  {
                    label: this.$t("map.zuozhuan"),
                    value: 10,
                  },
                  {
                    label: this.$t("map.shache"),
                    value: 11,
                  },
                  {
                    label: this.$t("map.daoche"),
                    value: 12,
                  },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn2_limit",
                unit: "",
                options: [
                  {
                    label: "Low",
                    value: 0,
                  },
                  {
                    label: "High",
                    value: 1,
                  },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "ipt",
                value: "",
                key: "input_chn2_delay",
                unit: "",
              },
              {
                label: "",
                width: "130px",
                type: "ipt",
                value: "",
                key: "input_chn2_holdtime",
                unit: "",
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn2_record",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.baojingliandong"),
                width: "130px",
                type: "btn",
                value: "",
                key: 102,
                unit: "",
              },
            ],
          },
          {
            label: "IN4",
            width: "70px",
            type: "list",
            value: "",
            key: "inkey",
            unit: "",
            list: [
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn3_enable",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.jinji"),
                    value: 1,
                  },
                  {
                    label: this.$t("map.qianmen"),
                    value: 2,
                  },
                  {
                    label: this.$t("map.zhongmen"),
                    value: 3,
                  },
                  {
                    label: this.$t("map.houmen"),
                    value: 4,
                  },
                  {
                    label: this.$t("map.youzhuan"),
                    value: 9,
                  },
                  {
                    label: this.$t("map.zuozhuan"),
                    value: 10,
                  },
                  {
                    label: this.$t("map.shache"),
                    value: 11,
                  },
                  {
                    label: this.$t("map.daoche"),
                    value: 12,
                  },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn3_limit",
                unit: "",
                options: [
                  {
                    label: "Low",
                    value: 0,
                  },
                  {
                    label: "High",
                    value: 1,
                  },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "ipt",
                value: "",
                key: "input_chn3_delay",
                unit: "",
              },
              {
                label: "",
                width: "130px",
                type: "ipt",
                value: "",
                key: "input_chn3_holdtime",
                unit: "",
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn3_record",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.baojingliandong"),
                width: "130px",
                type: "btn",
                value: "",
                key: 103,
                unit: "",
              },
            ],
          },
          {
            label: "IN5",
            width: "70px",
            type: "list",
            value: "",
            key: "inkey",
            unit: "",
            list: [
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn4_enable",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.jinji"),
                    value: 1,
                  },
                  {
                    label: this.$t("map.qianmen"),
                    value: 2,
                  },
                  {
                    label: this.$t("map.zhongmen"),
                    value: 3,
                  },
                  {
                    label: this.$t("map.houmen"),
                    value: 4,
                  },
                  {
                    label: this.$t("map.youzhuan"),
                    value: 9,
                  },
                  {
                    label: this.$t("map.zuozhuan"),
                    value: 10,
                  },
                  {
                    label: this.$t("map.shache"),
                    value: 11,
                  },
                  {
                    label: this.$t("map.daoche"),
                    value: 12,
                  },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn4_limit",
                unit: "",
                options: [
                  {
                    label: "Low",
                    value: 0,
                  },
                  {
                    label: "High",
                    value: 1,
                  },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "ipt",
                value: "",
                key: "input_chn4_delay",
                unit: "",
              },
              {
                label: "",
                width: "130px",
                type: "ipt",
                value: "",
                key: "input_chn4_holdtime",
                unit: "",
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn4_record",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.baojingliandong"),
                width: "130px",
                type: "btn",
                value: "",
                key: 104,
                unit: "",
              },
            ],
          },
          {
            label: "IN6",
            width: "70px",
            type: "list",
            value: "",
            key: "inkey",
            unit: "",
            list: [
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn5_enable",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.jinji"),
                    value: 1,
                  },
                  {
                    label: this.$t("map.qianmen"),
                    value: 2,
                  },
                  {
                    label: this.$t("map.zhongmen"),
                    value: 3,
                  },
                  {
                    label: this.$t("map.houmen"),
                    value: 4,
                  },
                  {
                    label: this.$t("map.youzhuan"),
                    value: 9,
                  },
                  {
                    label: this.$t("map.zuozhuan"),
                    value: 10,
                  },
                  {
                    label: this.$t("map.shache"),
                    value: 11,
                  },
                  {
                    label: this.$t("map.daoche"),
                    value: 12,
                  },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn5_limit",
                unit: "",
                options: [
                  {
                    label: "Low",
                    value: 0,
                  },
                  {
                    label: "High",
                    value: 1,
                  },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "ipt",
                value: "",
                key: "input_chn5_delay",
                unit: "",
              },
              {
                label: "",
                width: "130px",
                type: "ipt",
                value: "",
                key: "input_chn5_holdtime",
                unit: "",
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn5_record",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.baojingliandong"),
                width: "130px",
                type: "btn",
                value: "",
                key: 105,
                unit: "",
              },
            ],
          },
          {
            label: "IN7",
            width: "70px",
            type: "list",
            value: "",
            key: "inkey",
            unit: "",
            list: [
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn6_enable",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.jinji"),
                    value: 1,
                  },
                  {
                    label: this.$t("map.qianmen"),
                    value: 2,
                  },
                  {
                    label: this.$t("map.zhongmen"),
                    value: 3,
                  },
                  {
                    label: this.$t("map.houmen"),
                    value: 4,
                  },
                  {
                    label: this.$t("map.youzhuan"),
                    value: 9,
                  },
                  {
                    label: this.$t("map.zuozhuan"),
                    value: 10,
                  },
                  {
                    label: this.$t("map.shache"),
                    value: 11,
                  },
                  {
                    label: this.$t("map.daoche"),
                    value: 12,
                  },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn6_limit",
                unit: "",
                options: [
                  {
                    label: "Low",
                    value: 0,
                  },
                  {
                    label: "High",
                    value: 1,
                  },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "ipt",
                value: "",
                key: "input_chn6_delay",
                unit: "",
              },
              {
                label: "",
                width: "130px",
                type: "ipt",
                value: "",
                key: "input_chn6_holdtime",
                unit: "",
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn6_record",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.baojingliandong"),
                width: "130px",
                type: "btn",
                value: "",
                key: 106,
                unit: "",
              },
            ],
          },
          {
            label: "IN8",
            width: "70px",
            type: "list",
            value: "",
            key: "inkey",
            unit: "",
            list: [
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn7_enable",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.jinji"),
                    value: 1,
                  },
                  {
                    label: this.$t("map.qianmen"),
                    value: 2,
                  },
                  {
                    label: this.$t("map.zhongmen"),
                    value: 3,
                  },
                  {
                    label: this.$t("map.houmen"),
                    value: 4,
                  },
                  {
                    label: this.$t("map.youzhuan"),
                    value: 9,
                  },
                  {
                    label: this.$t("map.zuozhuan"),
                    value: 10,
                  },
                  {
                    label: this.$t("map.shache"),
                    value: 11,
                  },
                  {
                    label: this.$t("map.daoche"),
                    value: 12,
                  },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn7_limit",
                unit: "",
                options: [
                  {
                    label: "Low",
                    value: 0,
                  },
                  {
                    label: "High",
                    value: 1,
                  },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "ipt",
                value: "",
                key: "input_chn7_delay",
                unit: "",
              },
              {
                label: "",
                width: "130px",
                type: "ipt",
                value: "",
                key: "input_chn7_holdtime",
                unit: "",
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "input_chn7_record",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.baojingliandong"),
                width: "130px",
                type: "btn",
                value: "",
                key: 107,
                unit: "",
              },
            ],
          },
        ],
        a402: [
          {
            label: this.$t("map.leixing"),
            width: "130px",
            type: "list",
            value: "",
            key: "Phonenumber",
            unit: "",
            list: [
              {
                type: "span",
                label: this.$t("map.kaiguan"),
                value: "1",
                key: 1,
                unit: "",
              },
              {
                type: "span",
                label: this.$t("map.zuozhi"),
                value: "2",
                key: 2,
                unit: "",
              },
              {
                type: "span",
                label: this.$t("map.fangdou"),
                value: "3",
                key: 3,
                unit: "",
              },
              {
                type: "span",
                label: this.$t("map.luxiang"),
                value: "4",
                key: 4,
                unit: "",
              },
              {
                type: "span",
                label: this.$t("map.yanshi"),
                value: "5",
                key: 5,
                unit: "",
              },
            ],
          },
          {
            label: this.$t("map.chaoshitingche"),
            width: "130px",
            type: "list",
            value: "",
            key: "Phonenumber",
            unit: "",
            list: [
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "stopalarm_enable",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "stopalarm_limit",
                unit: "",
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "stopalarm_holdtime",
                unit: "",
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "stopalarm_record",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "stopalarm_delay",
                unit: "",
              },
            ],
          },
          {
            label: this.$t("map.disuyujing"),
            width: "130px",
            type: "list",
            value: "",
            key: "Phonenumber",
            unit: "",
            list: [
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "lowprealarm_enable",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "lowprealarm_limit",
                unit: "",
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "lowprealarm_holdtime",
                unit: "",
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "lowprealarm_record",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "lowprealarm_delay",
                unit: "",
              },
            ],
          },
          {
            label: this.$t("map.disubaojing"),
            width: "130px",
            type: "list",
            value: "",
            key: "Phonenumber",
            unit: "",
            list: [
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "lowalarm_enable",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "lowalarm_limit",
                unit: "",
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "lowalarm_holdtime",
                unit: "",
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "lowalarm_record",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "lowalarm_delay",
                unit: "",
              },
            ],
          },
          {
            label: this.$t("map.chaosuyujing"),
            width: "130px",
            type: "list",
            value: "",
            key: "Phonenumber",
            unit: "",
            list: [
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "highprealarm_enable",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "highprealarm_limit",
                unit: "",
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "highprealarm_holdtime",
                unit: "",
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "highprealarm_record",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "highprealarm_delay",
                unit: "",
              },
            ],
          },
          {
            label: this.$t("map.chaosubaojing"),
            width: "130px",
            type: "list",
            value: "",
            key: "Phonenumber",
            unit: "",
            list: [
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "highalarm_enable",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "highalarm_limit",
                unit: "",
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "highalarm_holdtime",
                unit: "",
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "highalarm_record",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "highalarm_delay",
                unit: "",
              },
            ],
          },
          {
            label: this.$t("map.sudulaiyuan"),
            width: "130px",
            type: "sel",
            value: "",
            key: "source",
            unit: "",
            options: [
              {
                label: "GPS",
                value: 0,
              },
              {
                label: this.$t("map.cheliang"),
                value: 1,
              },
              {
                label: this.$t("map.hunhe"),
                value: 2,
              },
            ],
          },
          {
            label: this.$t("map.sududanwei"),
            width: "130px",
            type: "sel",
            value: "",
            key: "unit",
            unit: "",
            options: [
              {
                label: "KM/H",
                value: 0,
              },
              {
                label: "Mph",
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.maichongxishu"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "pulse",
            unit: "",
          },
        ],
        a403: [
          {
            label: this.$t("map.leixing"),
            width: "130px",
            type: "list",
            value: "",
            key: "Phonenumber",
            unit: "",
            list: [
              {
                type: "span",
                label: this.$t("map.kaiguan"),
                value: "1",
                key: 1,
                unit: "",
              },
              {
                type: "span",
                label: this.$t("map.zuozhi"),
                value: "2",
                key: 2,
                unit: "",
              },
              {
                type: "span",
                label: this.$t("map.fangdou"),
                value: "3",
                key: 3,
                unit: "",
              },
              {
                type: "span",
                label: this.$t("map.luxiang"),
                value: "4",
                key: 4,
                unit: "",
              },
              {
                type: "span",
                label: this.$t("map.yanshi"),
                value: "5",
                key: 5,
                unit: "",
              },
            ],
          },
          {
            label: this.$t("map.jijiasu"),
            width: "130px",
            type: "list",
            value: "",
            key: "Phonenumber",
            unit: "",
            list: [
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "Acceleration_enable",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "Acceleration_limit",
                unit: "",
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "Acceleration_holdtime",
                unit: "",
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "Acceleration_record",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "Acceleration_delay",
                unit: "",
              },
            ],
          },
          {
            label: this.$t("map.jijiansu"),
            width: "130px",
            type: "list",
            value: "",
            key: "Phonenumber",
            unit: "",
            list: [
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "SharpSlowdown_enable",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "SharpSlowdown_limit",
                unit: "",
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "SharpSlowdown_holdtime",
                unit: "",
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "SharpSlowdown_record",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "SharpSlowdown_delay",
                unit: "",
              },
            ],
          },
          {
            label: this.$t("map.jizhuanwan"),
            width: "130px",
            type: "list",
            value: "",
            key: "Phonenumber",
            unit: "",
            list: [
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "SharpTurn_enable",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "SharpTurn_limit",
                unit: "",
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "SharpTurn_holdtime",
                unit: "",
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "SharpTurn_record",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "SharpTurn_delay",
                unit: "",
              },
            ],
          },
          {
            label: this.$t("map.pengzhuang"),
            width: "130px",
            type: "list",
            value: "",
            key: "Phonenumber",
            unit: "",
            list: [
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "Collision_enable",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "Collision_limit",
                unit: "",
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "Collision_holdtime",
                unit: "",
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "Collision_record",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "Collision_delay",
                unit: "",
              },
            ],
          },
          {
            label: this.$t("map.cefan"),
            width: "130px",
            type: "list",
            value: "",
            key: "Phonenumber",
            unit: "",
            list: [
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "Rollover_enable",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "Rollover_limit",
                unit: "",
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "Rollover_holdtime",
                unit: "",
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "Rollover_record",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi98") + "2" + this.$t("map.duankou"),
                width: "130px",
                type: "ipt",
                value: "",
                key: "Rollover_delay",
                unit: "",
              },
            ],
          },
          {
            label: "Sensor" + this.$t("map.rRectType"),
            width: "130px",
            type: "sel",
            value: "",
            key: "GsensorType",
            unit: "",
            options: [
              {
                label: this.$t("map.sanzhou"),
                value: 0,
              },
              {
                label: this.$t("map.liuzhou"),
                value: 1,
              },
            ],
          },
        ],
        // a404: [
        //   {
        //     label: this.$t("map.fanyi112"),
        //     width: "130px",
        //     type: "sel",
        //     value: "",
        //     key: "PowerMode",
        //     unit: "",
        //     options: [
        //       {
        //         label: "ACC",
        //         value: "acc",
        //       },
        //       {
        //         label: this.$t("map.fanyi64"),
        //         value: "djms",
        //       },
        //     ],
        //   },
        //   {
        //     label: this.$t("map.fanyi115"),
        //     width: "130px",
        //     type: "sel",
        //     value: "",
        //     key: "PowerMode",
        //     unit: "",
        //     options: [
        //       {
        //         label: "ACC",
        //         value: "acc",
        //       },
        //       {
        //         label: this.$t("map.fanyi64"),
        //         value: "djms",
        //       },
        //     ],
        //   },
        //   {
        //     label: this.$t("map.fanyi99"),
        //     width: "130px",
        //     type: "sel",
        //     value: "",
        //     key: "PowerMode",
        //     unit: "",
        //     options: [
        //       {
        //         label: "ACC",
        //         value: "acc",
        //       },
        //       {
        //         label: this.$t("map.fanyi64"),
        //         value: "djms",
        //       },
        //     ],
        //   },
        //   {
        //     label: this.$t("map.fanyi107"),
        //     width: "130px",
        //     type: "ipt",
        //     value: "",
        //     key: "Phonenumber",
        //     unit: "",
        //   },
        //   {
        //     label: this.$t("map.fanyi108"),
        //     width: "130px",
        //     type: "ipt",
        //     value: "",
        //     key: "Phonenumber",
        //     unit: this.$t("map.fanyi80"),
        //   },
        //   {
        //     label: this.$t("map.fanyi113"),
        //     width: "130px",
        //     type: "ipt",
        //     value: "",
        //     key: "Phonenumber",
        //     unit: this.$t("map.fanyi80"),
        //   },

        //   {
        //     label: this.$t("map.fanyi105"),
        //     width: "130px",
        //     type: "sel",
        //     value: "",
        //     key: "PowerMode",
        //     unit: "",
        //     options: [
        //       {
        //         label: "ACC",
        //         value: "acc",
        //       },
        //       {
        //         label: this.$t("map.fanyi64"),
        //         value: "djms",
        //       },
        //     ],
        //   },
        //   {
        //     label: this.$t("map.fanyi106"),
        //     width: "130px",
        //     type: "sel",
        //     value: "",
        //     key: "PowerMode",
        //     unit: "",
        //     options: [
        //       {
        //         label: "ACC",
        //         value: "acc",
        //       },
        //       {
        //         label: this.$t("map.fanyi64"),
        //         value: "djms",
        //       },
        //     ],
        //   },
        //   {
        //     label: this.$t("map.fanyi114"),
        //     width: "130px",
        //     type: "sel",
        //     value: "",
        //     key: "PowerMode",
        //     unit: "",
        //     options: [
        //       {
        //         label: "ACC",
        //         value: "acc",
        //       },
        //       {
        //         label: this.$t("map.fanyi64"),
        //         value: "djms",
        //       },
        //     ],
        //   },
        // ],
        // a405: [
        //   {
        //     label: this.$t("map.fanyi112"),
        //     width: "130px",
        //     type: "sel",
        //     value: "",
        //     key: "PowerMode",
        //     unit: "",
        //     options: [
        //       {
        //         label: "ACC",
        //         value: "acc",
        //       },
        //       {
        //         label: this.$t("map.fanyi64"),
        //         value: "djms",
        //       },
        //     ],
        //   },
        //   {
        //     label: this.$t("map.fanyi99"),
        //     width: "130px",
        //     type: "sel",
        //     value: "",
        //     key: "PowerMode",
        //     unit: "",
        //     options: [
        //       {
        //         label: "ACC",
        //         value: "acc",
        //       },
        //       {
        //         label: this.$t("map.fanyi64"),
        //         value: "djms",
        //       },
        //     ],
        //   },
        //   {
        //     label: this.$t("map.fanyi107"),
        //     width: "130px",
        //     type: "ipt",
        //     value: "",
        //     key: "Phonenumber",
        //     unit: "V",
        //   },
        //   {
        //     label: this.$t("map.fanyi108"),
        //     width: "130px",
        //     type: "ipt",
        //     value: "",
        //     key: "Phonenumber",
        //     unit: this.$t("map.fanyi80"),
        //   },
        //   {
        //     label: this.$t("map.fanyi116"),
        //     width: "130px",
        //     type: "ipt",
        //     value: "",
        //     key: "Phonenumber",
        //     unit: this.$t("map.fanyi117"),
        //   },
        // ],
      },
      cssztreeItemsObj_81801: {
        a101: [
          {
            label: this.$t("map.fanyi118"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "device_num",
            unit: "",
          },
          {
            label: this.$t("map.fanyi119"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "Phonenumber",
            unit: "",
          },
          {
            label: this.$t("map.cph_name"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "license_num",
            unit: "",
          },
        ],
        a102: [
          {
            label: this.$t("map.fanyi63"),
            width: "130px",
            type: "sel",
            value: "",
            key: "DateType",
            unit: "",
            options: [
              {
                label: "YYYY/MM/DD",
                value: 0,
              },
              {
                label: "DD/MM/YYYY",
                value: 1,
              },
              {
                label: "MM/DD/YYYY",
                value: 2,
              },
            ],
          },
          {
            label: this.$t("map.fanyi65"),
            width: "130px",
            type: "sel",
            value: "",
            key: "TimingMode",
            unit: "",
            options: [
              {
                label: this.$t("map.shoudong"),
                value: 0,
              },
              {
                label: "GPS" + this.$t("map.xiaozhun"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi66"),
            width: "130px",
            type: "list",
            value: "",
            key: "TimeZoneParent",
            unit: "",
            list: [
              {
                label: this.$t("map.fanyi65"),
                width: "130px",
                type: "sel",
                value: "",
                key: "TimeZone",
                unit: "",
                options: [
                  {
                    label: "GMT-12",
                    value: -12,
                  },
                  {
                    label: "GMT-11",
                    value: -11,
                  },
                  {
                    label: "GMT-10",
                    value: -10,
                  },
                  {
                    label: "GMT-09",
                    value: -9,
                  },
                  {
                    label: "GMT-08",
                    value: -8,
                  },
                  {
                    label: "GMT-07",
                    value: -7,
                  },
                  {
                    label: "GMT-06",
                    value: -6,
                  },
                  {
                    label: "GMT-05",
                    value: -5,
                  },
                  {
                    label: "GMT-04",
                    value: -4,
                  },
                  {
                    label: "GMT-03",
                    value: -3,
                  },
                  {
                    label: "GMT-02",
                    value: -2,
                  },
                  {
                    label: "GMT-01",
                    value: -1,
                  },
                  {
                    label: "GMT+00",
                    value: 0,
                  },
                  {
                    label: "GMT+01",
                    value: 1,
                  },
                  {
                    label: "GMT+02",
                    value: 2,
                  },
                  {
                    label: "GMT+03",
                    value: 3,
                  },
                  {
                    label: "GMT+04",
                    value: 4,
                  },
                  {
                    label: "GMT+05",
                    value: 5,
                  },
                  {
                    label: "GMT+06",
                    value: 6,
                  },
                  {
                    label: "GMT+07",
                    value: 7,
                  },
                  {
                    label: "GMT+08",
                    value: 8,
                  },
                  {
                    label: "GMT+09",
                    value: 9,
                  },
                  {
                    label: "GMT+10",
                    value: 10,
                  },
                  {
                    label: "GMT+11",
                    value: 11,
                  },
                  {
                    label: "GMT+12",
                    value: 12,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi65"),
                width: "130px",
                type: "sel",
                value: "",
                key: "TimeZone_sub",
                unit: "",
                options: [
                  {
                    label: "00",
                    value: 0,
                  },
                  {
                    label: "15",
                    value: 15,
                  },
                  {
                    label: "30",
                    value: 30,
                  },
                  {
                    label: "45",
                    value: 45,
                  },
                ],
              },
            ],
          },
          {
            label: this.$t("map.fanyi67"),
            width: "130px",
            type: "sel",
            value: "",
            key: "DST_onoff",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: this.$t("map.kaiqi"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi68"),
            width: "130px",
            type: "list",
            value: "",
            key: "DST_start_month_p",
            unit: "",
            list: [
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "DST_start_month",
                unit: "",
                options: [
                  { label: this.$t("map.yiyue"), value: 0 },
                  { label: this.$t("map.eryue"), value: 1 },
                  { label: this.$t("map.sanyue"), value: 2 },
                  { label: this.$t("map.siyue"), value: 3 },
                  { label: this.$t("map.wuyue"), value: 4 },
                  { label: this.$t("map.liuyue"), value: 5 },
                  { label: this.$t("map.qiyue"), value: 6 },
                  { label: this.$t("map.bayue"), value: 7 },
                  { label: this.$t("map.jiuyue"), value: 8 },
                  { label: this.$t("map.shiyue"), value: 9 },
                  { label: this.$t("map.shiyiyue"), value: 10 },
                  { label: this.$t("map.shieryue"), value: 11 },
                ],
              },
              {
                label: this.$t("map.fanyi65"),
                width: "130px",
                type: "sel",
                value: "",
                key: "DST_start_week_id",
                unit: "",
                options: [
                  { label: this.$t("map.diyizhou"), value: 0 },
                  { label: this.$t("map.dierzhou"), value: 1 },
                  { label: this.$t("map.disanzhou"), value: 2 },
                  { label: this.$t("map.disizhou"), value: 3 },
                  { label: this.$t("map.diwuzhou"), value: 4 },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "DST_start_week",
                unit: "",
                options: [
                  { label: this.$t("map.zhouyi"), value: 1 },
                  { label: this.$t("map.zhouer"), value: 2 },
                  { label: this.$t("map.zhousan"), value: 3 },
                  { label: this.$t("map.zhousi"), value: 4 },
                  { label: this.$t("map.zhouwu"), value: 5 },
                  { label: this.$t("map.zhouliu"), value: 6 },
                  { label: this.$t("map.zhouri"), value: 0 },
                ],
              },
              {
                label: this.$t("map.fanyi65"),
                width: "130px",
                type: "sel",
                value: "",
                key: "DST_start_hour",
                unit: "",
                options: [
                  { label: "00:00:00", value: 0 },
                  { label: "01:00:00", value: 1 },
                  { label: "02:00:00", value: 2 },
                  { label: "03:00:00", value: 3 },
                  { label: "04:00:00", value: 4 },
                  { label: "05:00:00", value: 5 },
                  { label: "06:00:00", value: 6 },
                  { label: "07:00:00", value: 7 },
                  { label: "08:00:00", value: 8 },
                  { label: "09:00:00", value: 9 },
                  { label: "10:00:00", value: 10 },
                  { label: "11:00:00", value: 11 },
                  { label: "12:00:00", value: 12 },
                  { label: "13:00:00", value: 13 },
                  { label: "14:00:00", value: 14 },
                  { label: "15:00:00", value: 15 },
                  { label: "16:00:00", value: 16 },
                  { label: "17:00:00", value: 17 },
                  { label: "18:00:00", value: 18 },
                  { label: "19:00:00", value: 19 },
                  { label: "20:00:00", value: 20 },
                  { label: "21:00:00", value: 21 },
                  { label: "22:00:00", value: 22 },
                  { label: "23:00:00", value: 23 },
                ],
              },
            ],
          },
          {
            label: this.$t("map.fanyi69"),
            width: "130px",
            type: "list",
            value: "",
            key: "DST_end_month_p",
            unit: "",
            list: [
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "DST_end_month",
                unit: "",
                options: [
                  { label: this.$t("map.yiyue"), value: 0 },
                  { label: this.$t("map.eryue"), value: 1 },
                  { label: this.$t("map.sanyue"), value: 2 },
                  { label: this.$t("map.siyue"), value: 3 },
                  { label: this.$t("map.wuyue"), value: 4 },
                  { label: this.$t("map.liuyue"), value: 5 },
                  { label: this.$t("map.qiyue"), value: 6 },
                  { label: this.$t("map.bayue"), value: 7 },
                  { label: this.$t("map.jiuyue"), value: 8 },
                  { label: this.$t("map.shiyue"), value: 9 },
                  { label: this.$t("map.shiyiyue"), value: 10 },
                  { label: this.$t("map.shieryue"), value: 11 },
                ],
              },
              {
                label: this.$t("map.fanyi65"),
                width: "130px",
                type: "sel",
                value: "",
                key: "DST_end_week_id",
                unit: "",
                options: [
                  { label: this.$t("map.diyizhou"), value: 0 },
                  { label: this.$t("map.dierzhou"), value: 1 },
                  { label: this.$t("map.disanzhou"), value: 2 },
                  { label: this.$t("map.disizhou"), value: 3 },
                  { label: this.$t("map.diwuzhou"), value: 4 },
                ],
              },
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "DST_end_week",
                unit: "",
                options: [
                  { label: this.$t("map.zhouyi"), value: 1 },
                  { label: this.$t("map.zhouer"), value: 2 },
                  { label: this.$t("map.zhousan"), value: 3 },
                  { label: this.$t("map.zhousi"), value: 4 },
                  { label: this.$t("map.zhouwu"), value: 5 },
                  { label: this.$t("map.zhouliu"), value: 6 },
                  { label: this.$t("map.zhouri"), value: 0 },
                ],
              },
              {
                label: this.$t("map.fanyi65"),
                width: "130px",
                type: "sel",
                value: "",
                key: "DST_end_hour",
                unit: "",
                options: [
                  { label: "00:00:00", value: 0 },
                  { label: "01:00:00", value: 1 },
                  { label: "02:00:00", value: 2 },
                  { label: "03:00:00", value: 3 },
                  { label: "04:00:00", value: 4 },
                  { label: "05:00:00", value: 5 },
                  { label: "06:00:00", value: 6 },
                  { label: "07:00:00", value: 7 },
                  { label: "08:00:00", value: 8 },
                  { label: "09:00:00", value: 9 },
                  { label: "10:00:00", value: 10 },
                  { label: "11:00:00", value: 11 },
                  { label: "12:00:00", value: 12 },
                  { label: "13:00:00", value: 13 },
                  { label: "14:00:00", value: 14 },
                  { label: "15:00:00", value: 15 },
                  { label: "16:00:00", value: 16 },
                  { label: "17:00:00", value: 17 },
                  { label: "18:00:00", value: 18 },
                  { label: "19:00:00", value: 19 },
                  { label: "20:00:00", value: 20 },
                  { label: "21:00:00", value: 21 },
                  { label: "22:00:00", value: 22 },
                  { label: "23:00:00", value: 23 },
                ],
              },
            ],
          },
          {
            label: this.$t("map.fanyi70"),
            width: "130px",
            type: "sel",
            value: "",
            key: "DST_adjust",
            unit: "",
            options: [
              {
                label: "15",
                value: 15,
              },
              {
                label: "30",
                value: 30,
              },
              {
                label: "45",
                value: 45,
              },
              {
                label: "60",
                value: 60,
              },
            ],
          },
        ],
        a103: [
          {
            label: this.$t("map.fanyi71"),
            width: "130px",
            type: "sel",
            value: "",
            key: "PowerMode",
            unit: "",
            options: [
              {
                label: "ACC",
                value: 1,
              },
              {
                label: this.$t("map.daijimoshi"),
                value: 3,
              },
            ],
          },
          {
            label: this.$t("map.fanyi72"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "DelayTime",
            unit: "",
          },
        ],
        a201: [
          {
            label: this.$t("map.fanyi73"),
            width: "130px",
            type: "sel",
            value: "",
            key: "SysNorm",
            unit: "",
            options: [
              {
                label: "PAL",
                value: 0,
              },
              {
                label: "NTSC",
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi74"),
            width: "130px",
            type: "sel",
            value: "",
            key: "CameraType",
            unit: "",
            options: [
              {
                label: this.$t("map.putong"),
                value: 0,
              },
              {
                label: "AHD",
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi75"),
            width: "130px",
            type: "sel",
            value: "",
            key: "RecMode",
            unit: "",
            options: [
              {
                label: this.$t("map.kaijiluxiang"),
                value: 0,
              },
              {
                label: this.$t("map.baojingluxiang"),
                value: 2,
              },
            ],
          },
          {
            label: this.$t("map.fanyi76"),
            width: "130px",
            type: "sel",
            value: "",
            key: "AudioType",
            unit: "",
            options: [
              {
                label: "G711A",
                value: 0,
              },
              {
                label: "G726_M40K",
                value: 1,
              },
              {
                label: "G726_40K",
                value: 3,
              },
            ],
          },
          {
            label: this.$t("map.fanyi77"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "AlarmPreRecTime",
            unit: this.$t("map.fanyi80"),
          },
          {
            label: this.$t("map.fanyi78"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "AlarmRecDelayTime",
            unit: this.$t("map.fanyi80"),
          },
          {
            label: this.$t("map.fanyi79"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "AlarmRecFileProtectTm",
            unit: this.$t("map.fanyi81"),
          },
        ],
        a202: [
          {
            label: this.$t("map.fanyi82"),
            width: "130px",
            type: "sel",
            value: "",
            key: "ChId",
            unit: "",
            options: [
              {
                label: 1,
                value: 0,
              },
              {
                label: 2,
                value: 1,
              },
              {
                label: 3,
                value: 2,
              },
              {
                label: 4,
                value: 3,
              },
            ],
          },
          {
            label: this.$t("map.fanyi83"),
            width: "130px",
            type: "sel",
            value: "",
            key: "StreamType",
            unit: "",
            options: [
              {
                label: this.$t("map.zhumaliu"),
                value: 0,
              },
              {
                label: this.$t("map.zimaliu"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi84"),
            width: "130px",
            type: "sel",
            value: "",
            key: "isRec",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: this.$t("map.kaiqi"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi85"),
            width: "130px",
            type: "sel",
            value: "",
            key: "Resolution",
            unit: "",
            options: [
              {
                label: "CIF",
                value: 2,
              },
              {
                label: "D1",
                value: 0,
              },
              {
                label: "720P",
                value: 3,
              },
            ],
          },
          {
            label: this.$t("map.fanyi86"),
            width: "130px",
            type: "sel",
            value: "",
            key: "FrameRate",
            unit: "",
            options: [
              { label: 1, value: 1 },
              { label: 2, value: 2 },
              { label: 3, value: 3 },
              { label: 4, value: 4 },
              { label: 5, value: 5 },
              { label: 6, value: 6 },
              { label: 7, value: 7 },
              { label: 8, value: 8 },
              { label: 9, value: 9 },
              { label: 10, value: 10 },
              { label: 11, value: 11 },
              { label: 12, value: 12 },
              { label: 13, value: 13 },
              { label: 14, value: 14 },
              { label: 15, value: 15 },
              { label: 16, value: 16 },
              { label: 17, value: 17 },
              { label: 18, value: 18 },
              { label: 19, value: 19 },
              { label: 20, value: 20 },
              { label: 21, value: 21 },
              { label: 22, value: 22 },
              { label: 23, value: 23 },
              { label: 24, value: 24 },
              { label: 25, value: 25 },
            ],
          },
          {
            label: this.$t("map.fanyi87"),
            width: "130px",
            type: "sel",
            value: "",
            key: "Quality",
            unit: "",
            options: [
              { label: 1, value: 1 },
              { label: 2, value: 2 },
              { label: 3, value: 3 },
              { label: 4, value: 4 },
              { label: 5, value: 5 },
              { label: 6, value: 6 },
              { label: 7, value: 7 },
              { label: 8, value: 8 },
            ],
          },
          {
            label: this.$t("map.fanyi88"),
            width: "130px",
            type: "sel",
            value: "",
            key: "HaveAudio",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: this.$t("map.kaiqi"),
                value: 1,
              },
            ],
          },
        ],
        a203: [
          {
            label: this.$t("map.fanyi82"),
            width: "130px",
            type: "sel",
            value: "",
            key: "ChId",
            unit: "",
            options: [
              { label: 1, value: 0 },
              { label: 2, value: 1 },
              { label: 3, value: 2 },
              { label: 4, value: 3 },
            ],
          },
          {
            label: this.$t("map.fanyi89"),
            width: "130px",
            type: "sel",
            value: "",
            key: "Mirror",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: this.$t("map.kaiqi"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi90"),
            width: "130px",
            type: "sel",
            value: "",
            key: "Flip",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: this.$t("map.kaiqi"),
                value: 1,
              },
            ],
          },
        ],
        a204: [
          {
            label: this.$t("map.fanyi91"),
            width: "130px",
            type: "sel",
            value: "2",
            key: "diskId",
            unit: "",
            options: [
              {
                label: this.$t("map.yingpan"),
                value: 2,
              },
              {
                label: this.$t("map.sdka"),
                value: 3,
              },
            ],
          },
          {
            label: this.$t("map.fanyi92"),
            width: "130px",
            type: "sel",
            value: "",
            key: "RecUse",
            unit: "",
            options: [
              {
                label: this.$t("map.buluxiang"),
                value: 0,
              },
              {
                label: this.$t("map.zhuluxiang"),
                value: 1,
              },
              {
                label: this.$t("map.jingxiangluxiang"),
                value: 2,
              },
              {
                label: this.$t("map.zimaliuluxiang"),
                value: 3,
              },
            ],
          },
        ],
        a301: [
          {
            label: this.$t("map.fanyi93"),
            width: "130px",
            type: "sel",
            value: "",
            key: "DialType",
            unit: "",
            options: [
              {
                label: "WCDMA",
                value: 0,
              },
              {
                label: "EVDO",
                value: 1,
              },
              {
                label: "TD-SCDMA",
                value: 2,
              },
              {
                label: "TDD-LTE",
                value: 3,
              },
              {
                label: "FDD-LTE",
                value: 4,
              },
            ],
          },
          {
            label: this.$t("map.fanyi94"),
            width: "130px",
            type: "sel",
            value: "",
            key: "AuthMode",
            unit: "",
            options: [
              {
                label: "CHAP",
                value: 0,
              },
              {
                label: "APA",
                value: 1,
              },
            ],
          },

          {
            label: "APN",
            width: "130px",
            type: "ipt",
            value: "",
            key: "APN",
            unit: "",
          },
          {
            label: this.$t("map.fanyi95"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "CenterNum",
            unit: "",
          },
          {
            label: this.$t("map.fanyi96"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "UserName",
            unit: "",
          },
          {
            label: this.$t("map.fanyi97"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "UserPwd",
            unit: "",
          },
        ],
        a302: [
          {
            label: this.$t("map.fanyi98"),
            width: "130px",
            type: "sel",
            value: "",
            key: "id",
            unit: "",
            options: [
              {
                label: "CMSV6",
                value: 0,
              },
              {
                label: "CNMS",
                value: 1,
              },
              {
                label: this.$t("map.beifenfuwuqi"),
                value: 2,
              },
            ],
          },
          {
            label: this.$t("map.fanyi99"),
            width: "130px",
            type: "sel",
            value: "",
            key: "Enable",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: this.$t("map.kaiqi"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi100"),
            width: "130px",
            type: "sel",
            value: "",
            key: "Type",
            unit: "",
            options: [
              {
                label: "IP",
                value: 0,
              },
              {
                label: this.$t("map.yuming"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi101"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "CenterIP",
            unit: "",
          },
          {
            label: this.$t("map.fanyi102"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "ContrlPort",
            unit: "",
          },
          {
            label: this.$t("map.fanyi103"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "DomainName",
            unit: "",
          },
        ],
        a401: [
          {
            label: this.$t("map.fanyi104"),
            width: "130px",
            type: "sel",
            value: "",
            key: "id",
            unit: "",
            options: [
              {
                label: this.$t("map.e_20"),
                value: 0,
              },
              {
                label: this.$t("map.disubaojing"),
                value: 1,
              },
              {
                label: this.$t("map.disuyujing"),
                value: 2,
              },
              {
                label: this.$t("map.chaosuyujing"),
                value: 3,
              },
              {
                label: this.$t("map.chaosubaojing"),
                value: 4,
              },
            ],
          },
          {
            label: this.$t("map.fanyi99"),
            width: "130px",
            type: "sel",
            value: "",
            key: "Enable",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: this.$t("map.kaiqi"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi105"),
            width: "130px",
            type: "sel",
            value: "",
            key: "isRecord",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: this.$t("map.kaiqi"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi106"),
            width: "130px",
            type: "sel",
            value: "",
            key: "linkage",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: this.$t("map.kaiqi"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi107"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "LimitValue_11",
            unit: "",
          },
          {
            label: this.$t("map.fanyi108"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "HoldTime_11",
            unit: this.$t("map.fanyi80"),
          },
          {
            label: this.$t("map.fanyi109"),
            width: "130px",
            type: "sel",
            value: "",
            key: "SpeedSource",
            unit: "",
            options: [
              {
                label: "GPS",
                value: 0,
              },
              {
                label: this.$t("map.cheliang"),
                value: 1,
              },
              {
                label: this.$t("map.hunhe"),
                value: 2,
              },
            ],
          },
          {
            label: this.$t("map.fanyi110"),
            width: "130px",
            type: "sel",
            value: "",
            key: "SpeedUnit",
            unit: "",
            options: [
              {
                label: "KM/H",
                value: 0,
              },
              {
                label: "Mph",
                value: 1,
              },
            ],
          },
        ],
        a402: [
          {
            label: this.$t("map.fanyi111"),
            width: "130px",
            type: "sel",
            value: "",
            key: "id",
            unit: "",
            options: [
              {
                label: 1,
                value: 0,
              },
              {
                label: 2,
                value: 1,
              },
              {
                label: 3,
                value: 2,
              },
              {
                label: 4,
                value: 3,
              },
              {
                label: 5,
                value: 4,
              },
              {
                label: 6,
                value: 5,
              },
              {
                label: 7,
                value: 6,
              },
              {
                label: 8,
                value: 7,
              },
            ],
          },
          {
            label: this.$t("map.fanyi99"),
            width: "130px",
            type: "sel",
            value: "",
            key: "Enable",
            unit: "",
            options: [
              {
                label: "SOS",
                value: 1,
              },
              {
                label: this.$t("map.qianmen"),
                value: 2,
              },
              {
                label: this.$t("map.zhongmen"),
                value: 3,
              },
              {
                label: this.$t("map.houmen"),
                value: 4,
              },
              {
                label: this.$t("map.youzhuan"),
                value: 9,
              },
              {
                label: this.$t("map.zuozhuan"),
                value: 10,
              },
              {
                label: this.$t("map.shache"),
                value: 11,
              },
              {
                label: this.$t("map.daoche"),
                value: 12,
              },
            ],
          },
          {
            label: this.$t("map.fanyi112"),
            width: "130px",
            type: "sel",
            value: "",
            key: "LimitValue_12",
            unit: "",
            options: [
              {
                label: this.$t("map.didianping"),
                value: 0,
              },
              {
                label: this.$t("map.gaodianping"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi108"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "HoldTime_12",
            unit: this.$t("map.fanyi80"),
          },
          {
            label: this.$t("map.fanyi113"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "DelayTime_12",
            unit: this.$t("map.fanyi80"),
          },

          {
            label: this.$t("map.fanyi105"),
            width: "130px",
            type: "sel",
            value: "",
            key: "isRecord",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: this.$t("map.kaiqi"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi106"),
            width: "130px",
            type: "sel",
            value: "",
            key: "linkage",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: "I/O OUT-1",
                value: 1,
              },
              {
                label: "I/O OUT-2",
                value: 2,
              },
            ],
          },
          {
            label: this.$t("map.fanyi114"),
            width: "130px",
            type: "sel",
            value: "",
            key: "HomePageChannel",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: "CH1",
                value: 1,
              },
              {
                label: "CH2",
                value: 2,
              },
              {
                label: "CH3",
                value: 3,
              },
              {
                label: "CH4",
                value: 4,
              },
              {
                label: "CH5",
                value: 5,
              },
              {
                label: "CH6",
                value: 6,
              },
              {
                label: "CH7",
                value: 7,
              },
              {
                label: "CH8",
                value: 8,
              },
            ],
          },
        ],
        a403: [
          {
            label: this.$t("map.fanyi112"),
            width: "130px",
            type: "sel",
            value: "3",
            key: "id",
            unit: "",
            options: [
              {
                label: this.$t("map.pengzhuang"),
                value: 3,
              },
              {
                label: this.$t("map.cefan"),
                value: 4,
              },
            ],
          },
          {
            label: this.$t("map.fanyi99"),
            width: "130px",
            type: "sel",
            value: "",
            key: "Enable",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: this.$t("map.kaiqi"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi107"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "LimitValue_13",
            unit: "",
          },
          {
            label: this.$t("map.fanyi108"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "HoldTime_13",
            unit: this.$t("map.fanyi80"),
          },
          {
            label: this.$t("map.fanyi113"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "DelayTime_13",
            unit: this.$t("map.fanyi80"),
          },

          {
            label: this.$t("map.fanyi105"),
            width: "130px",
            type: "sel",
            value: "",
            key: "isRecord",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: this.$t("map.kaiqi"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi106"),
            width: "130px",
            type: "sel",
            value: "",
            key: "linkage",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: "I/O OUT-1",
                value: 1,
              },
              {
                label: "I/O OUT-2",
                value: 2,
              },
            ],
          },
          // {
          //   label: this.$t("map.fanyi114"),
          //   width: "130px",
          //   type: "sel",
          //   value: "",
          //   key: "PowerMode",
          //   unit: "",
          //   options: [
          //     {
          //       label: "ACC",
          //       value: "acc",
          //     },
          //     {
          //       label: this.$t("map.fanyi64"),
          //       value: "djms",
          //     },
          //   ],
          // },
        ],
        a404: [
          {
            label: this.$t("map.fanyi112"),
            width: "130px",
            type: "sel",
            value: "",
            key: "id",
            unit: "",
            options: [
              {
                label: this.$t("map.diwen"),
                value: 0,
              },
              {
                label: this.$t("map.gaowen"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi115"),
            width: "130px",
            type: "sel",
            value: "",
            key: "TempUnit",
            unit: "",
            options: [
              {
                label: this.$t("map.sheshidu"),
                value: 0,
              },
              {
                label: this.$t("map.huashidu"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi99"),
            width: "130px",
            type: "sel",
            value: "",
            key: "Enable",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: this.$t("map.kaiqi"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi107"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "LimitValue_14",
            unit: "",
          },
          {
            label: this.$t("map.fanyi108"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "HoldTime_14",
            unit: this.$t("map.fanyi80"),
          },
          {
            label: this.$t("map.fanyi113"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "DelayTime_14",
            unit: this.$t("map.fanyi80"),
          },

          {
            label: this.$t("map.fanyi105"),
            width: "130px",
            type: "sel",
            value: "",
            key: "isRecord",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: this.$t("map.kaiqi"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi106"),
            width: "130px",
            type: "sel",
            value: "",
            key: "linkage",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: "I/O OUT-1",
                value: 1,
              },
              {
                label: "I/O OUT-2",
                value: 2,
              },
            ],
          },
          // {
          //   label: this.$t("map.fanyi114"),
          //   width: "130px",
          //   type: "sel",
          //   value: "",
          //   key: "PowerMode",
          //   unit: "",
          //   options: [
          //     {
          //       label: "ACC",
          //       value: "acc",
          //     },
          //     {
          //       label: this.$t("map.fanyi64"),
          //       value: "djms",
          //     },
          //   ],
          // },
        ],
        a405: [
          {
            label: this.$t("map.fanyi112"),
            width: "130px",
            type: "sel",
            value: "1",
            key: "id",
            unit: "",
            options: [
              {
                label: this.$t("map.didianya"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi99"),
            width: "130px",
            type: "sel",
            value: "",
            key: "Enable",
            unit: "",
            options: [
              {
                label: this.$t("map.guanbi"),
                value: 0,
              },
              {
                label: this.$t("map.kaiqi"),
                value: 1,
              },
            ],
          },
          {
            label: this.$t("map.fanyi107"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "LimitValue_15",
            unit: "V",
          },
          {
            label: this.$t("map.fanyi108"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "HoldTime_15",
            unit: this.$t("map.fanyi80"),
          },
          {
            label: this.$t("map.fanyi116"),
            width: "130px",
            type: "ipt",
            value: "",
            key: "VoltageShutdownDelay",
            unit: this.$t("map.fanyi117"),
          },
        ],
      },
      tableData: [],
      rightClickAct: "cssz",
      rightClickDialog: false,
      showRightMenu: false,
      propoption: {
        icon: "icon",
        value: "gps_id",
        label: "nodeName",
        children: "children",
        disabled: "disabled",
      },
      treeDataMain: [],
      query_key: "",
      cheigth: 600,
      map: null,
      BMap: null,
    };
  },
  watch: {
    // dataCar(value) {
    //   console.log("value", value);
    //   if (value.length > 0) {
    //     this.mapBottomHeight = 200;
    //   } else {
    //     this.mapBottomHeight = 30;
    //   }
    // },
    // 监听 visible，来触发关闭右键菜单，调用关闭菜单的方法
    visible(value) {
      if (value) {
        document.body.addEventListener("click", this.closeMenu);
      } else {
        document.body.removeEventListener("click", this.closeMenu);
      }
    },
    rightClickAct(val) {
      if (
        val == "dj" ||
        val == "gshcp" ||
        val == "gshyp" ||
        val == "lc" ||
        val == "cq" ||
        val == "gpssb" ||
        val == "clkz"
      ) {
        this.dialogW = "400px";
      } else {
        this.dialogW = "880px";
      }
    },
  },
  created() {
    this.defaultExpandedKeys = ["a_0"];
    this.$api.tree_deptTree_Tree({}).then((res) => {
      this.treeDataMain = res.data.resultData;
      this.treeDataTemp = res.data.resultData;
      this.delChannel(this.treeDataTemp);
      this.treeData = this.treeDataTemp;
      this.getPid(this.treeDataMain);
      // Tree2Ref.convertTreeToList(this.treeDataMain[0]);
    });
  },
  mounted() {
    this.$api.get_config_imgs({}).then((res) => {
      document.title = res.data.website_name;
    });
    // this.cssztreeItemsObj_81801_temp = this.deepCopy(
    //   this.cssztreeItemsObj_81801
    // );
    // this.cssztreeItemsObj_81802_temp = this.deepCopy(
    //   this.cssztreeItemsObj_81802
    // );

    this.rightMenuNone();
    GpsSocket.addListener((data) => {
      console.log("socket", data);
      let row;
      switch (data.type) {
        case "realtime":
          if (this.is_show_loading == 1) {
            if (this.loadingInstance) {
              this.loadingInstance.close();
            }
          }
          this.realtime_proc(data);
          break;
        case "answer":
          // if (this.is_show_loading == 1) {
          //   this.is_show_loading = 0;
          //   if (this.loadingInstance) {
          //     this.loadingInstance.close();
          //   }
          //   this.$message.success(this.$t("map.caozuochenggong"));
          // }
          this.answer_proc(data);
          break;
      }
      // console.log(data);
    });

    this.$api.sys_get_xtpz({}).then((res) => {
      this.video_close_min = parseInt(res.data.row.videoTime);
    });

    window.onresize = () => {
      //调用methods中的事件
      setTimeout(() => {
        this.calculateBoxes();
      }, 500);
    };

    this.timer = setInterval(() => {
      if (VueCookies.get("gps_token")) {
        if (this.ay_veh.length > 0) {
          this.getMapCar(this.ay_veh.join(","), true);
        }
        this.get_map_total();
        this.get_car_list();
      }
    }, 10000);
    this.get_map_total();
    this.init_data();
    this.get_car_list();
    this.load_company_group();

    // console.log(this.$t("s_1.s0"));
    // this.init_baidu();
    // L.control
    //   .zoom({
    //     zoomInTitle: "放大",
    //     zoomOutTitle: "缩小",
    //   })
    //   .addTo(map);
  },
  methods: {
    delChannel(dataList) {
      dataList.forEach((it) => {
        if (it.nodetype == 2) {
          it.children = [];
        } else {
          if (it.children && it.children.length > 0) {
            this.delChannel(it.children);
          }
        }
      });
    },
    video_jixun_handler() {
      if (this.video_close_timer_out) {
        clearTimeout(this.video_close_timer_out);
      }
      this.dialogVisible_close_tip = false;
    },
    video_close_handler() {
      this.handleCloseAllVideo();
      this.dialogVisible_close_tip = false;
    },
    close_back() {
      this.$refs.gpsBack.close_timeout();
    },
    deepClearData(obj) {
      if (obj === null || typeof obj !== "object") {
        return obj;
      }

      if (obj instanceof Object) {
        if (obj.hasOwnProperty("value")) {
          obj["value"] = "";
        }
      }

      if (obj instanceof Array) {
        obj.forEach((it) => {
          this.deepClearData(it);
        });
      }
    },

    close_history() {
      this.$refs.gpsHistory.closeTimeout();
    },
    click_get_clkz() {
      //发送socket请求
      let sn = this.leftZeroPadding(this.rightTreeData.devID, 12);
      let order = "8201";
      let node = "";

      let obj = { deviceid: sn, order: order, content: node };
      this.get_socket(obj);
    },
    click_get_jssz() {
      //发送socket请求
      let sn = this.leftZeroPadding(this.rightTreeData.devID, 12);
      let order = "8106";
      let node = "00000055,00000056,00000057,00000058,00000059,0000005A";

      let obj = { deviceid: sn, order: order, content: node };
      this.get_socket(obj);
    },
    get_gpssb() {
      //发送socket请求
      let sn = this.leftZeroPadding(this.rightTreeData.devID, 12);
      let order = "8106";
      let node = "00000027,00000029";

      let obj = { deviceid: sn, order: order, content: node };
      this.get_socket(obj);
    },
    get_gps(it, lng, lat, to) {
      setTimeout(() => {
        this.$api
          .bdmap_get_gps_addr({
            lng: parseFloat(it[lng]),
            lat: parseFloat(it[lat]),
          })
          .then((res_addr) => {
            it[to] = res_addr.data.formatted_address;
          });
      }, 500);
    },
    btnAlarm() {
      this.is_online_map = 0;
      if (this.is_alarm_map == 0) {
        this.clear_car_map();
        this.is_alarm_map = 1;
        //获取数据上线
        this.get_car_map(4);
      } else {
        //清空地图
        this.is_alarm_map = 0;
        this.clear_car_map();
      }
    },
    btnOnline() {
      this.is_alarm_map = 0;
      if (this.is_online_map == 0) {
        this.is_online_map = 1;
        this.clear_car_map();
        //获取数据上线
        this.get_car_map(2);
      } else {
        //清空地图
        this.is_online_map = 0;
        this.clear_car_map();
      }
    },
    clear_car_map() {
      // console.log("clear_car_map");
      if (this.ay_veh.length == 0) return;
      this.ay_veh.forEach((it) => {
        this.$refs.mainMap.removeMaerker({ id: it });
      });
      // this.dataCar_temp.splice(0, this.dataCar_temp.length);
      this.dataCar.splice(0, this.dataCar.length);
      this.ay_veh.splice(0, this.ay_veh.length);
      this.car_list.forEach((il) => {
        il.chk = 0;
      });

      this.showBottom();
      // console.log("this.dataCar", this.dataCar);
      setTimeout(() => {
        this.calculateBoxes();
      }, 100);
    },
    get_car_map(flag) {
      this.$api
        .tree_tree_vehicles({ page: 1, rp: this.car_total, flag: flag })
        .then((res) => {
          if (res.data.data) {
            let row_img = null;
            let icon = "";
            let dataCar_temp = [...this.dataCar];
            res.data.data.forEach((it) => {
              row_img = imgData(it.st_msg);
              icon =
                "Public/tree/dxtree/imgs/vehicle/" +
                it.icon +
                "/" +
                row_img.dirName +
                "/" +
                car_dir(it.fangxiang) +
                ".png";
              it.icon = getHTTPURL() + icon;
              it.color = row_img.color;
              it.html = this.get_pop_html(it);
              // console.log("icon", icon);
              this.$refs.mainMap.addMarker(it);
              //判断有车
              if (!this.ay_veh.includes(it.id)) {
                this.ay_veh.push(it.id);
                dataCar_temp = dataCar_temp.concat(it);
              }
            });
            this.dataCar.splice(0, this.dataCar.length);
            this.dataCar = dataCar_temp;
            //处理左边车辆是否选中
            this.car_list.forEach((il) => {
              il.chk = 0;
              if (this.ay_veh.includes(il.id)) {
                il.chk = 1;
              }
            });

            this.showBottom();
            // console.log("this.dataCar", this.dataCar);
            setTimeout(() => {
              this.calculateBoxes();
            }, 100);
          }
        });
    },
    closeAll() {
      this.handleCloseAllVideo();
      this.isShowVideo = false;
    },
    openAll() {
      this.isShowVideo = true;
      this.$nextTick(() => {
        this.calculateBoxes();
      });
    },
    saveEvent(temp) {
      if (temp.id == 0) {
        temp.vPlateColorID = 0;

        temp.vOwnerName = "";
        temp.vOwnerSex = this.$t("map.nan");
        temp.vOwnerAddress = "";
        temp.vOwnerBobyNo = "";
        temp.vOwnerEmail = "";
        temp.vOwnerLinkTel = "";
        temp.vFrameNo = "";
        temp.vEngineNo = "";

        temp.vMore = "";
        temp.vDeviceID = 0;
        temp.vSimID = 0;
      }
      this.$api.sys_vehicles_save(temp).then((res) => {
        this.row_car = {};
        ElNotification({
          title: this.$t("commKey.MSG_TITLE"),
          message: this.$t("commKey.CAR_" + res.data.msg_type),
          duration: 2000,
        });
        if (res.data.msg_type == 1 || res.data.msg_type == 2) {
          this.$refs["gpsCar"].dialogVisible = false;
          this.car_query.page = 1;
          this.get_car_list();
        }
        refresh_table("gps_vehicles,gps_devices,gps_sims", 5);
      });
    },
    btnAddCar() {
      // this.row_car = { id: 0 };
      // console.log("btnAddCar");
      this.$refs["gpsCar"].dialogVisible = true;
      this.$refs["gpsCar"].clearVal({
        id: 0,
        vPlate: "",
        vVehType: "2",
        vCompanyID: "",
        vBobyColor: "",
        sNo: "",
        sMonthFlow: "3072",
        sMonthDay: "1",
        sResidualFlow: "0",
        dType: "1",
        dName: "",
        dChannelNum: "4",
        // dPara: "CH1,CH2,CH3,CH4",
        vxlh: "",
        vxh: "",
        vInstaller: "",
        vStartServDate: moment().format("YYYY-MM-DD"),
        vStopServDate: moment().add(50, "years").format("YYYY-MM-DD"),
        vState: "1",
        vIcoID: "1",
      });
    },
    onQueryChanged(keyword) {
      // console.log(keyword);
      setTimeout(() => {
        this.car_query.page = 1;
        this.car_query.keyname = keyword;
        this.get_car_list();
      }, 500);
      // this.car_query.flag = flag;
    },
    search_tree_car(flag) {
      this.car_query.flag = flag;
      this.car_query.page = 1;
      this.get_car_list();
    },
    set_fav(veh_id, flag) {
      this.$api
        .sys_set_user_favs({ veh_id: veh_id, flag: flag })
        .then((res) => {
          // console.log(res);
          let ay = res.data.fav_list;
          this.car_list.forEach((il) => {
            il.fav = 0;
          });
          ay.forEach((ik) => {
            this.car_list.forEach((il) => {
              if (parseInt(il.id) == parseInt(ik.fVehID)) {
                il.fav = 1;
              }
            });
          });
        });
    },
    show_history_it() {
      console.log(this.sel_row, "wxw");
      this.show_history(this.sel_row);
      this.showRightMenu = false;
    },
    // 离开消失
    rightMenuNone() {
      const menu = document.getElementById("menu");
      menu.addEventListener("mouseleave", () => {
        //menu.style.display = "none";
        this.showRightMenu = false;
      });
    },
    show_back_it() {
      // console.log(this.sel_row, "wxw");
      this.show_back(this.sel_row);
      this.showRightMenu = false;
    },
    click_video_all_it() {
      this.click_video_all(this.sel_row);
      this.showRightMenu = false;
    },
    dot_click(evt, it) {
      this.sel_row = it;
      console.log("sel_row", this.sel_row);
      evt.stopPropagation();
      // alert("wxw");
      this.rightClick(evt, it, it);
    },
    click_car_st(it) {
      if (it.chk == 0) {
        if (it.st_msg >= 0) {
          it.chk = 1;
          this.curr_car = it.id;
          this.$refs.mainMap.setCurrCar(it.id);
          // if (!this.ay_veh.includes(it.id)) {
          //   this.ay_veh.push(it.id);
          // }
          this.$api.alarm_vehicle_info({ id: it.id }).then((res) => {
            let ay = res.data.data;

            console.log("ay", ay);
            if (ay.length > 0) {
              let row_img = null;
              let icon = "";
              let dataCar_temp = [...this.dataCar];
              ay.forEach((it) => {
                // console.log("it", it);
                row_img = imgData(it.st_msg);
                icon =
                  "Public/tree/dxtree/imgs/vehicle/" +
                  it.icon +
                  "/" +
                  row_img.dirName +
                  "/" +
                  car_dir(it.fangxiang) +
                  ".png";
                it.icon = getHTTPURL() + icon;
                it.color = row_img.color;
                it.html = this.get_pop_html(it);
                // console.log("icon", icon);
                this.$refs.mainMap.addMarker(it);
                //判断有车
                if (!this.ay_veh.includes(it.id)) {
                  this.ay_veh.push(it.id);
                  dataCar_temp = dataCar_temp.concat(it);
                }
              });
              this.dataCar.splice(0, this.dataCar.length);
              this.dataCar = dataCar_temp;
              this.showBottom();
              setTimeout(() => {
                this.calculateBoxes();
              }, 100);
            }
            // console.log("this.dataCar", this.dataCar);
          });
          //
        }
      } else {
        it.chk = 0;
        this.$refs.mainMap.setCurrCar(0);
        this.curr_car = 0;

        let dataCar_temp = [...this.dataCar];

        this.$refs.mainMap.removeMaerker({ id: it.id });
        for (let i = this.ay_veh.length - 1; i >= 0; i--) {
          if (this.ay_veh[i] == it.id) {
            this.ay_veh.splice(i, 1);
          }
        }
        for (let i = dataCar_temp.length - 1; i >= 0; i--) {
          if (dataCar_temp[i].id == it.id) {
            dataCar_temp.splice(i, 1);
          }
        }

        this.dataCar.splice(0, this.dataCar.length);
        this.dataCar = dataCar_temp;

        this.showBottom();
        console.log("this.dataCar", this.dataCar);
        setTimeout(() => {
          this.calculateBoxes();
        }, 100);
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    opengz1() {
      this.$message({
        message: this.$t("map.guanzhuchenggong"),
        type: "success",
      });
    },
    opengz2() {
      this.$message({
        message: this.$t("map.quxiaoguanzhu"),
        type: "success",
      });
    },
    filterNodeMethod(value, data) {
      // console.log(value, data);
      return data.node_name.indexOf(value) > -1;
    },
    nodeClickTree(node) {
      // console.log(node);
      this.car_query.page = 1;
      this.car_query.company_id = 0;
      this.car_query.group_id = 0;
      if (parseInt(node.node_type) == 2) {
        this.car_query.group_id = node.id;
      } else {
        this.car_query.company_id = node.id;
      }

      this.get_car_list();
    },
    load_company_group() {
      this.$api.tree_get_company_tree({}).then((res) => {
        console.log("tree-data", res.data.data);
        this.tree_option = res.data.data;
      });
    },
    change_group() {
      get_car_list();
    },
    handleCurrentChange(val) {
      this.car_query.page = val;
      this.get_car_list();
    },
    handleSizeChange(val) {
      this.car_query.rp = val;
      this.get_car_list();
    },
    get_car_list() {
      // this.car_query.company_id = this.cpy_id;
      // this.car_query.group_id = this.group_id;
      this.$api.tree_tree_vehicles(this.car_query).then((res) => {
        if (res.data.data) {
          res.data.data.forEach((it) => {
            it.chk = 0;
            if (this.ay_veh.includes(it.id)) {
              it.chk = 1;
            }
            it.fav = 0;
          });

          this.car_list = res.data.data;
          this.car_total = res.data.pagination.totalRecords;
          this.$api.sys_get_user_favs({}).then((rs) => {
            // console.log("aaaaay", rs.data);
            let ay = rs.data.fav_list;

            ay.forEach((ik) => {
              this.car_list.forEach((il) => {
                if (parseInt(il.id) == parseInt(ik.fVehID)) {
                  il.fav = 1;
                }
              });
            });
          });
        } else {
          this.car_list = [];
          this.car_total = 0;
        }
      });
    },
    change_cpy(val) {
      // console.log(val);
      this.$api.search_getGroupList({ company_id: val }).then((res) => {
        if (res.data.data.length > 0) {
          let ay = res.data.data.map((it) => {
            return {
              name: it.gName,
              value: it.id,
            };
          });
          this.list_group = ay;
        }
      });
    },
    init_data() {
      this.$api.sys_init_datas({}).then((res) => {
        if (res.data.companys.length > 0) {
          let ay_companys = res.data.companys.map((it) => {
            return {
              name: it.cName,
              value: it.id,
            };
          });
          this.list_company = ay_companys;
        }
      });
      ////////////////////////////////////
      this.$api.sys_get_bases({ flag: ["cllx", "sblx"] }).then((res) => {
        this.formArray[1].data = res.data.cllx;
        this.formArray[8].data = res.data.sblx;
      });
      let ay_channel = [];
      for (let i = 0; i < 17; i++) {
        ay_channel.push({
          name: i.toString(),
          value: i.toString(),
        });
      }
      this.formArray[10].data = ay_channel;
      this.$api.item_get_company({}).then((res) => {
        let ay = res.data.data.map((it) => {
          it.name = it.cName;
          it.value = it.id;
          return it;
        });
        this.formArray[2].data = ay;
      });
    },
    tableRowClass({ row }) {
      if (row.lAlarmMsg != "") {
        return "row-red";
      }
      return "";
    },
    change_zt(val) {
      // console.log(val);
      this.$refs.treeRef.filter(val);
    },
    open_back() {
      this.$nextTick(() => {
        this.$refs.gpsBack.search_data();
      });
    },
    show_back(row) {
      console.log("show_back", row);
      this.dev_id = row.dev_id;
      this.sel_devID = row.devID;
      this.sel_cph = row.vPlate;
      this.ch_num = row.dPara.split(",").length;
      console.log("wxw", this.ch_num, row.dPara);
      this.drawer_back = true;
    },
    rowClick(row) {
      this.curr_car = row.id;
      // console.log(row);
      this.$api.alarm_vehicle_info({ id: row.id }).then((res) => {
        let ay = res.data.data;
        if (ay.length == 1) {
          let row_row = ay[ay.length - 1];
          let row_img = imgData(row_row.st_msg);
          let icon =
            "Public/tree/dxtree/imgs/vehicle/" +
            row_row.icon +
            "/" +
            row_img.dirName +
            "/" +
            car_dir(row_row.fangxiang) +
            ".png";
          row_row.icon = getHTTPURL() + icon;
          row_row.color = row_img.color;
          row_row.html = this.get_pop_html(row_row);
          this.$refs.mainMap.posMarker(row_row);
          this.$refs.mainMap.setCurrCar(row.id);
        }
      });
    },
    mapChange(id) {
      this.$refs.mainMap.mainMap(id);
    },
    get_map_total() {
      this.$api.tree_get_total_num({}).then((res) => {
        this.row_total = res.data;
        this.xx_row[0].value = this.row_total.online_num;
        this.xx_row[1].value = this.row_total.run_num;
        this.xx_row[2].value = this.row_total.offline_num;
        this.xx_row[3].value = this.row_total.alarm_num;
        this.xx_row[4].value = this.row_total.daisu_num;
        this.xx_row[5].value = this.row_total.tingche_num;
        this.xx_row[6].value = this.row_total.wdw_num;
        this.xx_row[7].value = this.row_total.juxing_num;
        this.xx_row[8].value = this.row_total.dot_num;
        this.xx_row[9].value = this.row_total.line_num;
        this.xx_row[10].value = this.row_total.yuan_num;
      });
    },
    handleCloseAllVideo() {
      let video_ip = this.$store.getters.orderIp; //"www.xilihuala.top";
      let video_port = this.$store.getters.orderPort; //"9500";

      let video_ip_play = this.$store.getters.videoIp; //"test.cnms365.com";
      let video_port_play = this.$store.getters.videoPort; //4022;

      const get_video_url = (
        video_ip_play,
        video_port_play,
        sn,
        channel_id
      ) => {
        return (
          "https://" +
          video_ip_play +
          ":" +
          video_port_play +
          "/flv?port=10077&app=live&stream=" +
          sn +
          "_channel_" +
          channel_id
        );
      };

      let player_idx;
      let video_close_obj;

      for (let i = 0; i < this.boxes; i++) {
        player_idx = i + 1;
        video_close_obj = this.videoMap.get("video" + player_idx);
        if (
          video_close_obj != null &&
          typeof video_close_obj.sn !== "undefined" &&
          video_close_obj.sn != ""
        ) {
          console.log("9102", video_close_obj);
          //下发关闭
          GpsSocket.sendMsg("order", {
            deviceid: video_close_obj.sn_org,
            order: "9102",
            content:
              video_ip +
              "," +
              video_port +
              ",0," +
              video_close_obj.idx +
              ",0,1",
          });
        }
        this.$refs["video" + player_idx][0].destroy();

        // let url = get_video_url(
        //   video_ip_play,
        //   video_port_play,
        //   video_close_obj.sn,
        //   video_close_obj.idx
        // );
        delete this.ay_player[i];
      }
      this.ay_video_obj.splice(0, this.ay_video_obj.length);
      if (this.video_close_timer) {
        clearInterval(this.video_close_timer);
      }
    },
    handleCloseVideo() {
      let video_ip = this.$store.getters.orderIp; //"www.xilihuala.top";
      let video_port = this.$store.getters.orderPort; //"9500";

      let video_ip_play = this.$store.getters.videoIp; //"test.cnms365.com";
      let video_port_play = this.$store.getters.videoPort; //4022;

      const get_video_url = (
        video_ip_play,
        video_port_play,
        sn,
        channel_id
      ) => {
        return (
          "https://" +
          video_ip_play +
          ":" +
          video_port_play +
          "/flv?port=10077&app=live&stream=" +
          sn +
          "_channel_" +
          channel_id
        );
      };

      let player_idx = this.video_idx;
      let video_close_obj = this.ay_video_obj[player_idx - 1]; //this.videoMap.get("video" + player_idx);
      // console.log()
      if (
        video_close_obj != null &&
        typeof video_close_obj.sn !== "undefined" &&
        video_close_obj.sn != ""
      ) {
        console.log("9102", video_close_obj);
        //下发关闭
        GpsSocket.sendMsg("order", {
          deviceid: video_close_obj.sn_org,
          order: "9102",
          content:
            video_ip + "," + video_port + ",0," + video_close_obj.idx + ",0,1",
        });
      }
      this.$refs["video" + player_idx][0].destroy();

      let url = get_video_url(
        video_ip_play,
        video_port_play,
        video_close_obj.sn,
        video_close_obj.idx
      );
      let i = 0;
      for (i = 0; i < this.ay_player.length; i++) {
        if (this.ay_player[i] == url) {
          break;
        }
      }
      delete this.ay_player[i];

      this.ay_video_obj[player_idx - 1].sn_org = "";
      this.ay_video_obj[player_idx - 1].key = "";
      this.ay_video_obj[player_idx - 1].idx = 0;
      this.ay_video_obj[player_idx - 1].play = 0;
      this.ay_video_obj[player_idx - 1].flag = 0;
      this.ay_video_obj[player_idx - 1].send = 0;

      console.log("wxw-", this.ay_video_obj);

      if (this.ay_video_obj.every((it) => it.play == 0)) {
        if (this.video_close_timer) {
          clearInterval(this.video_close_timer);
        }
      }
    },
    setCls(value, value2, key) {
      // console.log(value2);
      if (
        key != "" &&
        (value.indexOf(key) > -1 ||
          (value2.devID && value2.devID.indexOf(key) > -1))
      ) {
        return true;
      }
      return false;
    },
    getPid(dataList) {
      dataList.forEach((it) => {
        if (it.children && it.children.length > 0) {
          if (!this.tree_pid.includes(it.gps_id)) {
            this.tree_pid.push(it.gps_id);
          }
          if (it.children) {
            this.getPid(it.children);
          }
        }
      });
    },
    getAllPId(keys, dataList, PidList) {
      let that = this;
      if (dataList && dataList.length) {
        let pid = PidList;
        for (let i = 0; i < dataList.length; i++) {
          // if (
          //   dataList[i].terminal &&
          //   dataList[i].terminal.indexOf(that.query_key) !== -1
          // )
          //   console.log("sszjy", dataList[i].terminal);
          if (
            (dataList[i].label != null &&
              dataList[i].label.indexOf(that.query_key) !== -1) ||
            (dataList[i].terminal != null &&
              dataList[i].terminal.indexOf(that.query_key) !== -1)
          ) {
            keys.push(PidList);
          }
          if (dataList[i].children) {
            keys = this.getAllPId(
              keys,
              dataList[i].children,
              pid + "," + dataList[i].gps_id
            );
          }
        }
      }
      return keys;
    },
    showBottom() {
      if (this.dataCar.length > 0) {
        this.mapBottomHeight = 200;
      } else {
        this.mapBottomHeight = 30;
      }
    },
    clickLabel(row) {
      // console.log(row);
      if (row.nodetype == 2) {
        this.getMapCar(row.nodeId, false);
      }
    },
    getMapCar(ids, flag) {
      this.$api.alarm_vehicle_info({ id: ids }).then((res) => {
        let ay = res.data.data;
        if (flag) {
          this.dataCar = ay;
        }
        console.log("ay", ay);
        if (ay.length > 0) {
          let row_img = null;
          let icon = "";
          // let dataCar_temp = this.dataCar;
          let curr_row = null;
          ay.forEach((it) => {
            // console.log("it", it);
            row_img = imgData(it.st_msg);
            icon =
              "Public/tree/dxtree/imgs/vehicle/" +
              it.icon +
              "/" +
              row_img.dirName +
              "/" +
              car_dir(it.fangxiang) +
              ".png";
            it.icon = getHTTPURL() + icon;
            it.color = row_img.color;
            it.html = this.get_pop_html(it);
            // console.log("icon", icon);
            this.$refs.mainMap.addMarker(it, 0);
            if (it.id == this.curr_car) {
              curr_row = it;
            }
            //判断有车
            // if (!this.ay_veh.includes(it.id)) {
            //   dataCar_temp = dataCar_temp.concat(it);
            // }
          });
          if (curr_row) {
            // console.log("curr_row", curr_row);
            this.$refs.mainMap.addMarker(curr_row, 0);
            this.$refs.mainMap.setCurrCar(curr_row.id);
          }
          // this.dataCar.splice(0, this.dataCar.length);
          // this.dataCar = dataCar_temp;
        }
        // console.log(res.data);
      });
    },
    // 关闭右键菜单
    closeMenu() {
      this.visible = false;
    },
    openMenu(e, idx) {
      this.video_idx = idx;
      console.log(e, idx);
      this.visible = true;
      this.top = e.pageY;
      this.left = e.pageX;
    },
    openVideo() {
      this.isShowVideo = !this.isShowVideo;
      this.$nextTick(() => {
        this.calculateBoxes();
      });
    },
    open_history() {
      this.$nextTick(() => {
        // this.$refs.gpsHistory.$refs.mainMap.loadMap();
        this.$refs.gpsHistory.$refs.historyMap.$refs.mapRef.loadScripts();
        setTimeout(() => {
          this.$refs.gpsHistory.select_date_fun();
        }, 500);
      });
    },
    show_history(row) {
      // console.log(row);
      this.sel_devID = row.vDeviceID;
      this.sel_cph = row.vPlate;
      this.drawer_history = true;
    },
    changePaneSize(evt) {
      // paneSize = $event[0].size
      this.paneSize = evt[0].size;
      setTimeout(() => {
        this.calculateBoxes();
      }, 100);
    },
    click_video_item(node, data) {
      console.log(node.parent.data, data);
      if (!this.isShowVideo) {
        this.isShowVideo = true;
        // this.boxes = num;
      }
      let ch = data.nodeName;
      let channel_id = parseInt(ch.substr(2));
      //先判断所有视频是否正在播放
      if (this.videoAy.length > this.boxes) {
        this.videoIdx = 0;
        let ay_del = this.videoAy.splice(this.boxes);
        //销毁视频和发送关闭视频
        ay_del.forEach((it) => {
          // window.parent.back_socket(dev_id, "9102", $("#VidedList" + idx).data('channel') + ",0,0,0");
        });
      }
      this.$nextTick(() => {
        this.calculateBoxes();
        let sn = node.parent.data.devID;
        let sn_org = node.parent.data.devID;
        let video_ip = this.$store.getters.orderIp; //"www.xilihuala.top";
        let video_port = this.$store.getters.orderPort; //"9500";
        // let channel_id = 1;

        let video_Idx = 0;

        let video_key = null;

        sn = this.leftZeroPadding(sn, 11);

        //下发播放视频命令
        GpsSocket.sendMsg("order", {
          deviceid: sn_org,
          order: "9101",
          content: video_ip + "," + video_port + ",0," + channel_id + ",0,1",
        });

        let video_ip_play = this.$store.getters.videoIp; //"test.cnms365.com";
        let video_port_play = this.$store.getters.videoPort; //4022;

        const get_video_url = (
          video_ip_play,
          video_port_play,
          sn,
          channel_id
        ) => {
          return (
            "https://" +
            video_ip_play +
            ":" +
            video_port_play +
            "/flv?port=10077&app=live&stream=" +
            sn +
            "_channel_" +
            channel_id
          );
        };
        setTimeout(() => {
          let url = get_video_url(
            video_ip_play,
            video_port_play,
            sn,
            channel_id
          );
          if (!this.ay_player.includes(url)) {
            this.ay_player.push(url);
            let player_idx = this.ay_player.length % this.boxes;
            if (player_idx == 0) {
              player_idx = this.boxes;
            }
            console.log("player_idx", player_idx);
            let video_close_obj = this.videoMap.get("video" + player_idx);
            if (
              video_close_obj != null &&
              typeof video_close_obj.sn !== "undefined" &&
              video_close_obj.sn != ""
            ) {
              console.log("9102", video_close_obj);
              //下发关闭
              GpsSocket.sendMsg("order", {
                deviceid: video_close_obj.sn_org,
                order: "9102",
                content:
                  video_ip +
                  "," +
                  video_port +
                  ",0," +
                  video_close_obj.idx +
                  ",0,1",
              });
              let url_del = get_video_url(
                video_ip_play,
                video_port_play,
                video_close_obj.sn,
                video_close_obj.idx
              );
              let kk = 0;
              for (kk = 0; kk < this.ay_player.length; kk++) {
                if (this.ay_player[kk] == url_del) {
                  break;
                }
              }
              delete this.ay_player[kk];
            }
            console.log(url);
            this.$refs["video" + player_idx][0].playUrl(url);
            this.$refs["video" + player_idx][0].setVidoName(
              node.parent.data.vPlate,
              channel_id
            );
            this.videoMap.set("video" + player_idx, {
              sn_org: sn_org,
              sn: sn,
              idx: channel_id,
            });
          }
        }, 1000);
      });
    },
    click_video_all(data) {
      if (!this.isShowVideo) {
        this.isShowVideo = true;
      }

      let video_ip = this.$store.getters.orderIp; //"www.xilihuala.top";
      let video_port = this.$store.getters.orderPort; //"9500";
      let cph = data.cphm;

      let dPara = data.dPara;
      // console.log(data);
      let that = this;
      if (dPara != "") {
        let ay = dPara.split(",");
        // let num = 4;
        if (ay.length <= 1) {
          this.num = 1;
        } else if (ay.length > 1 && ay.length <= 4 && this.num == 1) {
          this.num = 4;
        } else if (ay.length > 4) {
          this.num = 9;
        }
        //
        this.boxes = this.num;
        //注释说明 9102 下发关闭命令
        if (this.ay_video_obj.length == 0) {
          //添加9个视频视频对象
          let ay_v_temp = [];
          for (let i = 0; i < 9; i++) {
            ay_v_temp.push({
              cph: "",
              sn_org: "",
              sn: "",
              idx: 0,
              key: "", //身份标识 sn_channel
              play: 0, //标识是否播放视频
              flag: 0, //位置占用否
              send: 0,
            });
          }
          this.ay_video_obj = ay_v_temp;
        } else {
          this.ay_video_obj.forEach((it) => {
            it.flag = 0;
          });
        }

        //先关闭不在数组内的视频
        if (this.ay_video_obj.length > this.boxes) {
          //
          for (let i = this.boxes; i < this.ay_video_obj.length; i++) {
            //下发关闭
            if (
              this.ay_video_obj[i].sn_org != "" &&
              this.ay_video_obj[i].idx != 0
            ) {
              console.log("wxw-v", "close", this.ay_video_obj[i].key);
              GpsSocket.sendMsg("order", {
                deviceid: this.ay_video_obj[i].sn_org,
                order: "9102",
                content:
                  video_ip +
                  "," +
                  video_port +
                  ",0," +
                  this.ay_video_obj[i].idx +
                  ",0,1",
              });
              this.ay_video_obj[i].sn_org = "";
              this.ay_video_obj[i].idx = 0;
            }
          }
        }

        this.$nextTick(() => {
          this.calculateBoxes();

          let sn = data.devID;
          let sn_org = data.devID;

          let channel_id = 1;

          let video_Idx = 0;

          let video_key = null;

          sn = this.leftZeroPadding(sn, 11);

          let ay_unuser_channel = []; //要播放的通道

          ay.forEach((it, idx) => {
            video_key = sn_org + "_" + (idx + 1);
            let is_use = 0;
            this.ay_video_obj.forEach((ik) => {
              if (ik.key == video_key) {
                ik.flag = 1;
                is_use = 1;
              }
            });
            if (is_use == 0) {
              ay_unuser_channel.push(idx + 1);
            }
          });
          ay_unuser_channel.reverse();
          if (ay_unuser_channel.length > 0) {
            this.ay_video_obj.forEach((iv) => {
              if (ay_unuser_channel.length > 0) {
                if (iv.play == 0 && iv.flag == 0) {
                  if (iv.send == 1) {
                    console.log("wxw-v", "close", iv.key);
                    GpsSocket.sendMsg("order", {
                      deviceid: iv.sn_org,
                      order: "9102",
                      content:
                        video_ip + "," + video_port + ",0," + iv.idx + ",0,1",
                    });
                  }
                  let num = ay_unuser_channel.pop();
                  iv.cph = cph;
                  iv.sn_org = sn_org;
                  iv.sn = sn;
                  iv.idx = num;
                  iv.key = sn_org + "_" + num;
                  iv.play = 1;
                  iv.flag = 0;
                  iv.send = 0;
                }
              }
            });
            //判断一下是否还有没用通道
            if (ay_unuser_channel.length > 0) {
              this.ay_video_obj.forEach((iv) => {
                iv.play = 0;
              });
            }

            this.ay_video_obj.forEach((iv) => {
              if (ay_unuser_channel.length > 0) {
                if (iv.play == 0 && iv.flag == 0) {
                  if (iv.send == 1) {
                    console.log("wxw-v", "close", iv.key);
                    GpsSocket.sendMsg("order", {
                      deviceid: iv.sn_org,
                      order: "9102",
                      content:
                        video_ip + "," + video_port + ",0," + iv.idx + ",0,1",
                    });
                  }
                  let num = ay_unuser_channel.pop();
                  iv.cph = cph;
                  iv.sn_org = sn_org;
                  iv.sn = sn;
                  iv.idx = num;
                  iv.key = sn_org + "_" + num;
                  iv.play = 1;
                  iv.flag = 0;
                  iv.send = 0;
                }
              }
            });
          }

          this.ay_video_obj.forEach((it) => {
            if (it.sn_org != "") {
              console.log("wxw-v", "open", it.key);
              GpsSocket.sendMsg("order", {
                deviceid: it.sn_org,
                order: "9101",
                content: video_ip + "," + video_port + ",0," + it.idx + ",0,1",
              });
              it.send = 1;
            }
          });

          let video_ip_play = this.$store.getters.videoIp; //"test.cnms365.com";
          let video_port_play = this.$store.getters.videoPort; //4022;

          const get_video_url = (
            video_ip_play,
            video_port_play,
            sn,
            channel_id
          ) => {
            return (
              "https://" +
              video_ip_play +
              ":" +
              video_port_play +
              "/flv?port=10077&app=live&stream=" +
              sn +
              "_channel_" +
              channel_id
            );
          };

          console.log("wxw-v", this.ay_video_obj);
          //视频播放端口
          let ay_video_port_play = [
            4022, 4022, 4022, 4023, 4023, 4023, 4024, 4024, 4024,
          ];

          setTimeout(() => {
            this.ay_video_obj.forEach((it, idx_i) => {
              if (it.sn_org != "" && it.send == 1) {
                // console.log("wxw-v", "url", idx_i, it.idx, it.key);
                video_port_play = ay_video_port_play[idx_i];
                let url = get_video_url(
                  video_ip_play,
                  video_port_play,
                  it.sn,
                  it.idx
                );
                this.$refs["video" + (idx_i + 1)][0].playUrl(url);
                this.$refs["video" + (idx_i + 1)][0].setVidoName(
                  idx_i + 1,
                  it.cph,
                  it.idx
                );
                console.log(this.$refs["video" + (idx_i + 1)]);
              }
            });
            //播放视频
            if (this.video_close_timer) {
              clearInterval(this.video_close_timer);
            }

            //
            this.video_close_timer = setInterval(() => {
              //
              if ((this.isShowVideo = true)) {
                this.dialogVisible_close_tip = true;

                if (this.video_close_timer_out) {
                  clearTimeout(this.video_close_timer_out);
                }

                this.video_close_timer_out = setTimeout(() => {
                  this.video_close_handler();
                }, 5000);
              }
            }, 60000 * this.video_close_min);
          }, 1000);
        });
      }
    },
    click_video_all_old(data) {
      if (!this.isShowVideo) {
        this.isShowVideo = true;
      }
      let dPara = data.dPara;
      // console.log(data);
      let that = this;
      if (dPara != "") {
        let ay = dPara.split(",");
        // let num = 4;
        if (ay.length <= 1) {
          this.num = 1;
        } else if (ay.length > 1 && ay.length <= 4 && this.num == 1) {
          this.num = 4;
        } else if (ay.length > 4) {
          this.num = 9;
        }
        //
        this.boxes = this.num;

        //先判断所有视频是否正在播放
        if (this.videoAy.length > this.boxes) {
          this.videoIdx = 0;
          let ay_del = this.videoAy.splice(this.boxes);
          //销毁视频和发送关闭视频
          ay_del.forEach((it) => {
            // window.parent.back_socket(dev_id, "9102", $("#VidedList" + idx).data('channel') + ",0,0,0");
          });
        }

        this.$nextTick(() => {
          this.calculateBoxes();

          let sn = data.devID;
          let sn_org = data.devID;
          let video_ip = this.$store.getters.orderIp; //"www.xilihuala.top";
          let video_port = this.$store.getters.orderPort; //"9500";
          let channel_id = 1;

          let video_Idx = 0;

          let video_key = null;

          sn = this.leftZeroPadding(sn, 11);

          ay.forEach((it, idx) => {
            // video_key = sn_org + "_" + it;
            // if (this.videoAy.includes(video_key)) {
            //   //视频正在播放
            // } else {
            //   //视频没播放
            //   // video_Idx = this.videoIdx % this.boxes;

            channel_id = idx + 1;
            GpsSocket.sendMsg("order", {
              deviceid: sn_org,
              order: "9101",
              content:
                video_ip + "," + video_port + ",0," + channel_id + ",0,1",
            });
            //   this.videoAy.push(video_key);
            // }
          });

          let video_ip_play = this.$store.getters.videoIp; //"test.cnms365.com";
          let video_port_play = this.$store.getters.videoPort; //4022;

          const get_video_url = (
            video_ip_play,
            video_port_play,
            sn,
            channel_id
          ) => {
            return (
              "https://" +
              video_ip_play +
              ":" +
              video_port_play +
              "/flv?port=10077&app=live&stream=" +
              sn +
              "_channel_" +
              channel_id
            );
          };

          setTimeout(() => {
            ay.forEach((it, idx) => {
              // video_key = sn_org + "_" + it;
              let url = get_video_url(
                video_ip_play,
                video_port_play,
                sn,
                idx + 1
              );
              if (!this.ay_player.includes(url)) {
                this.ay_player.push(url);
                let player_idx = this.ay_player.length % this.boxes;
                if (player_idx == 0) {
                  player_idx = this.boxes;
                }
                console.log("player_idx", player_idx);
                let video_close_obj = this.videoMap.get("video" + player_idx);
                if (
                  video_close_obj != null &&
                  typeof video_close_obj.sn !== "undefined" &&
                  video_close_obj.sn != ""
                ) {
                  console.log("9102", video_close_obj);
                  //下发关闭
                  GpsSocket.sendMsg("order", {
                    deviceid: video_close_obj.sn_org,
                    order: "9102",
                    content:
                      video_ip +
                      "," +
                      video_port +
                      ",0," +
                      video_close_obj.idx +
                      ",0,1",
                  });
                }
                this.$refs["video" + player_idx][0].playUrl(url);
                this.$refs["video" + player_idx][0].setVidoName(
                  data.vPlate,
                  idx + 1
                );
                this.videoMap.set("video" + player_idx, {
                  sn_org: sn_org,
                  sn: sn,
                  idx: idx + 1,
                });
              }
            });
          }, 1000);
        });
      }
    },

    change_video(num) {
      this.num = num;
      this.boxes = num;
      this.$nextTick(() => {
        this.calculateBoxes();
      });
    },
    calculateBoxes() {
      const cc = this.$refs.cc;
      if (cc != null && cc.offsetWidth) {
        const ccWidth = cc.offsetWidth;
        console.log("ccWidth: " + ccWidth);
        const ccHeight = cc.offsetHeight;
        console.log("ccHeight: " + ccHeight);

        // 根据容器的宽高和网格布局的列数计算每个区域的宽和高
        let width, height;

        width = Math.floor(ccWidth / Math.sqrt(this.boxes));
        height = Math.floor(ccHeight / Math.sqrt(this.boxes));
        if (this.boxes == 4) {
          width = width - 1;
        }
        this.ww = width;
        this.hh = height;

        // 设置每个区域的宽和高
        const boxes = cc.querySelectorAll(".box");
        boxes.forEach((box) => {
          box.style.width = `${width}px`;
          box.style.height = `${height}px`;
        });
      }
    },
    nodeClick(data, node) {
      this.rightTreeNode = node;
      this.rightTreeData = data;
    },
    answer_proc(data) {
      if (data.content.result == 101) {
        this.$message.error(this.$t("map.shebeilixian"));
      } else if (data.content.result == 0) {
        // this.$message.success("命令下发成功");
      }
    },
    realtime_proc(data) {
      console.log("data.content.order", data.content.order);
      let ay = [];
      let row;
      switch (data.content.order) {
        case "8300":
          switch (this.rightClickAct) {
            case "xfwz":
              this.$refs.treeRef_wz.getCheckedNodes().forEach((it) => {
                if (parseInt(it.devID) == parseInt(data.content.deviceid)) {
                  row = it;
                }
              });
              this.xfwz_tableData.push({
                vPlate: row.vPlate,
                sj: dateFormat("YYYY-mm-dd HH:MM:SS", new Date()),
                zt: this.$t("map.fasongchenggong"),
                nr: this.xfwz_val,
              });
              break;
          }
          break;
        case "4040":
          console.log("data", data);

          let array = data.content.content.varlist;
          let bb_array = array[2].varvalue.split("|");
          let clxxAy = {};
          clxxAy["bb_v"] = bb_array[0] + "-" + bb_array[1];
          clxxAy["imei_v"] = array[8].varvalue;
          let wllx_array = array[6].varvalue.split("|");
          let wllx_v = "";
          switch (wllx_array[0]) {
            case "0":
              wllx_v = lang.wx;
              break;
            case "1":
              wllx_v = "4G";
              break;
            case "2":
              wllx_v = "WIFI";
              break;
            case "3":
              wllx_v = "5G";
              break;
            case "4":
              wllx_v = "3G";
              break;
            case "5":
              wllx_v = "2G";

              break;
            case "255":
              wllx_v = lang.wz;
              break;
          }
          clxxAy["wllx_v"] = wllx_v;

          clxxAy["wxxh_v"] = wllx_array[2];
          clxxAy["wx_wldz_v"] = wllx_array[1];

          let wifi_array = array[7].varvalue.split("|");
          clxxAy["wifimc_v"] = wifi_array[0];
          clxxAy["wifixh_v"] = wifi_array[2];
          clxxAy["wifi_wldz_v"] = wifi_array[1];

          this.clxx_temp = clxxAy;

          let td_array = array[4].varvalue.split("|");
          let td_num = parseInt(td_array[0], 16);
          let splx = parseInt(td_array[1], 16);
          splx = splx.toString(2);
          let splx_array = splx.split("").reverse();

          let lxzt = parseInt(td_array[2], 16);
          lxzt = lxzt.toString(2);
          let lxzt_array = lxzt.split("").reverse();
          let lxAy = [];
          for (let i = 1; i <= td_num; i++) {
            lxAy.push({
              ttmc: "CH" + i,
              splx:
                lxzt_array[i - 1] == 1
                  ? this.$t("map.luxiang")
                  : this.$t("map.weiluxiang"),
              spds:
                splx_array[i - 1] == 1
                  ? this.$t("map.diushi")
                  : this.$t("map.zhengchang"),
            });
          }
          this.tableData_lx = lxAy;
          let cp_array = array[5].varvalue.split("|");
          let ccAy = [];
          for (let i = 1; i <= cp_array.length; i++) {
            let cpzl_array = cp_array[i - 1].split(",");
            let syrl = (parseInt(cpzl_array[0]) / 1024).toFixed(2);
            let sy = (parseInt(cpzl_array[1]) / 1024).toFixed(2);
            ccAy.push({
              cp: i,
              zrl: syrl + "GB",
              syrl: sy + "GB",
            });
          }
          this.tableData_cc = ccAy;

          break;
        case "0900":
          var ay_obj1 = data.content.content;
          if (
            typeof ay_obj1 !== "undefined" &&
            typeof ay_obj1.content !== "undefined"
          ) {
            var str_obj = ay_obj1.content;
            var ay_obj = JSON.parse(str_obj);
            console.log("ay_obj", ay_obj);
            //数据存储起来方便以后调用
            this.row_curr_cssz = ay_obj;
            switch (this.curr_tree_node.nodeName) {
              case "jcsz":
                this["cssztreeItemsObj_" + this.rightTreeData.dev_type_sn][
                  "a" + this.curr_tree_node.id
                ].forEach((it) => {
                  it.value = this.row_curr_cssz[it.key];
                });
                break;
              default:
                this["cssztreeItemsObj_" + this.rightTreeData.dev_type_sn][
                  "a" + this.curr_tree_node.id
                ].forEach((it) => {
                  if (this.row_curr_cssz[it.key])
                    it.value = this.row_curr_cssz[it.key];
                  if (it.list) {
                    it.list.forEach((it2) => {
                      if (this.row_curr_cssz[it2.key]) {
                        it2.value = this.row_curr_cssz[it2.key];
                        if (it2.key == "TimeZone") {
                          var sub_num = parseInt(
                            parseInt(this.row_curr_cssz["TimeZone"]) / 26
                          );
                          var num =
                            parseInt(this.row_curr_cssz["TimeZone"]) -
                            sub_num * 26;
                          this.row_curr_cssz["TimeZone_sub"] =
                            sub_num.toString();
                          console.log("sub_num", sub_num);
                          it2.value = num.toString();
                        }
                      }
                    });
                  }
                });
                break;
            }
          }
          break;
        case "B055":
          var ay_obj1 = data.content.content;
          if (
            typeof ay_obj1 !== "undefined" &&
            typeof ay_obj1.content !== "undefined"
          ) {
            var str_obj = ay_obj1.content;
            var ay_obj = JSON.parse(str_obj);
            console.log("ay_obj", ay_obj);
            //数据存储起来方便以后调用
            this.row_curr_cssz = ay_obj;
            //////////////////////////////
            if (this.curr_tree_node.id > 0) {
              // console.log(
              //   this.rightTreeData.dev_type_sn,
              //   this.curr_tree_node.id,
              //   this["cssztreeItemsObj_" + this.rightTreeData.dev_type_sn][
              //     "a" + this.curr_tree_node.id
              //   ]
              // );
              let ay_temp_wxw;
              this["cssztreeItemsObj_" + this.rightTreeData.dev_type_sn][
                "a" + this.curr_tree_node.id
              ].forEach((it) => {
                if (this.row_curr_cssz[it.key]) {
                  it.value = this.row_curr_cssz[it.key];
                  // console.log("hcn", this.curr_tree_node.nodeName);
                  switch (this.curr_tree_node.nodeName) {
                    case "dygl":
                      if (
                        it.key == "PowerOnTime" ||
                        it.key == "PowerOffTime" ||
                        it.key == "RebootTime"
                      ) {
                        it.value = int2time(this.row_curr_cssz[it.key]);
                      }
                      break;
                  }
                } else {
                  switch (this.curr_tree_node.nodeName) {
                    case "zxsz":
                    case "ccsz":
                      // case "bhsz":
                      // case "sjfwq":
                      ay_temp_wxw = it.key.split("_");

                      it.value =
                        this.row_curr_cssz[ay_temp_wxw[0]][ay_temp_wxw[1]];
                      break;
                    case "zilm":
                      ay_temp_wxw = it.key.split("_");

                      it.value =
                        this.row_curr_cssz[ay_temp_wxw[0]][ay_temp_wxw[1]][
                          ay_temp_wxw[2]
                        ];
                      break;
                  }
                }

                if (it.list) {
                  it.list.forEach((it2) => {
                    if (this.row_curr_cssz[it2.key]) {
                      it2.value = this.row_curr_cssz[it2.key];
                      if (it2.key == "timezone") {
                        var aa =
                          parseFloat(this.row_curr_cssz["timezone"]) * 0.1; //20.0
                        var bb = Math.floor(aa);
                        var cc = aa - bb;

                        // $("#sq").val(bb - 12);
                        it2.value = (bb - 12).toString();

                        // $("#sq_kz").val(cc * 60);
                        this.row_curr_cssz["timezone_sub"] = (
                          cc * 60
                        ).toString();
                        // var sub_num = parseInt(
                        //   parseInt(this.row_curr_cssz["timezone"]) / 26
                        // );
                        // var num =
                        //   parseInt(this.row_curr_cssz["timezone"]) - sub_num * 26;
                        // this.row_curr_cssz["timezone_sub"] = sub_num.toString();
                        // console.log("sub_num", sub_num);
                        // it2.value = num.toString();
                      }
                    } else {
                      //没有处理方式
                      if (!Number.isInteger(it2.key)) {
                        //
                        ay_temp_wxw = it2.key.split("_");
                        if (ay_temp_wxw.length == 2) {
                          it2.value =
                            this.row_curr_cssz[ay_temp_wxw[0]][ay_temp_wxw[1]];
                        } else if (ay_temp_wxw.length == 3) {
                          it2.value =
                            this.row_curr_cssz[ay_temp_wxw[0]][ay_temp_wxw[1]][
                              ay_temp_wxw[2]
                            ];
                        }
                      }
                    }
                  });
                }
              });
            }
          }
          break;
        case "8900":
        // case "0900":
        case "8103":
        case "0500":
        case "8105":
        case "8103":
          this.$message.success(this.$t("map.caozuochenggong"));
          break;
        // case "8300":
        //   //文本信息发送成功
        //   break;
        case "8104":
          //文本信息发送成功
          break;
        case "9101":
          //音频 不需要调用下面的方法
          break;
        case "0201":
          var data2 = data.content.content;
          console.log("data2", data2);
          if ((parseInt(data2.Status) & 0x00000800) > 0) {
            // 电源断开
            this.clkz_temp.Circuit = "1";
          } else {
            this.clkz_temp.Circuit = "0";
          }
          if ((parseInt(data2.Status) & 0x00001000) > 0) {
            // 车门加锁
            this.clkz_temp.carMen = "1";
          } else {
            this.clkz_temp.carMen = "1";
          }
          break;
        case "0104":
          var data = data.content.content;
          data.varlist.forEach((it) => {
            if (it.varid == "00000029") {
              this.gps_temp.timeValue = parseInt(it.varvalue, 16).toString(10);
            }
            if (it.varid == "00000027") {
              this.gps_temp.xm_timeValue = parseInt(it.varvalue, 16).toString(
                10
              );
            }
            if (it.varid == "00000055") {
              this.jssz_temp.zgsu_val = parseInt(it.varvalue, 16).toString(10);
            }
            if (it.varid == "00000056") {
              this.jssz_temp.cscxsj_val = parseInt(it.varvalue, 16).toString(
                10
              );
            }
            if (it.varid == "00000059") {
              this.jssz_temp.dtljsj_val = parseInt(it.varvalue, 16).toString(
                10
              );
            }
            if (it.varid == "00000058") {
              this.jssz_temp.lxjssj_val = parseInt(it.varvalue, 16).toString(
                10
              );
            }
            if (it.varid == "00000057") {
              this.jssz_temp.zctcsj_val = parseInt(it.varvalue, 16).toString(
                10
              );
            }
            if (it.varid == "0000005A") {
              this.jssz_temp.zxxxsj_val = parseInt(it.varvalue, 16).toString(
                10
              );
            }
            console.log(it);
          });
          // console.log(data.content.content);
          break;
        // case "alarm":
        //   //实时监控页面生成报警信息
        //   // console.log(JSON.stringify(data));
        //   if (data.content.content && data.content.content.length > 0) {
        //     // ay = this.dataAlram.concat();
        //     data.content.content.forEach((it) => {
        //       // ay.in(it);
        //       // ay.unshift(it);
        //       this.dataAlram.unshift(it);
        //     });
        //     // this.dataAlram = ay;
        //   }
        //   break;
      }
    },
    /**
     * 参数设置 81802
     */
    click_cssz_set_81802() {
      //
      let sn = this.leftZeroPadding(this.rightTreeData.devID, 12);
      var data = this.row_curr_cssz;

      let ay_para = [];

      this["cssztreeItemsObj_" + this.rightTreeData.dev_type_sn][
        "a" + this.curr_tree_node.id
      ].forEach((it) => {
        if (typeof it.list === "undefined") {
          if (typeof data[it.key] !== "undefined") {
            data[it.key] = it.value;
          } else {
            //
            ay_para = it.key.split("_");
            if (ay_para.length == 2) {
              data[ay_para[0]][ay_para[1]] = it.value;
            } else if (ay_para.length == 3) {
              data[ay_para[0]][ay_para[1]][ay_para[2]] = it.value;
            }
          }
        } else {
          switch (this.curr_tree_node.nodeName) {
            case "xtsz":
              // (parseInt(sq) + 12) * 10 + (parseInt(sq_kz) * 10) / 60;
              data["timezone"] =
                (parseInt(it.list[0].value) + 12) * 10 +
                (parseInt(it.list[1].value) * 10) / 60;
              break;
            default:
              it.list.forEach((it2) => {
                if (!Number.isInteger(it2.key)) {
                  if (typeof data[it2.key] !== "undefined") {
                    data[it2.key] = it2.value;
                  } else {
                    //
                    ay_para = it2.key.split("_");
                    if (ay_para.length == 2) {
                      data[ay_para[0]][ay_para[1]] = it2.value;
                    } else if (ay_para.length == 3) {
                      data[ay_para[0]][ay_para[1]][ay_para[2]] = it2.value;
                    }
                  }
                }
              });
              break;
          }
        }
      });

      var obj = {
        deviceid: sn,
        order: "B056",
        content: {
          msgid: "0A01",
          xmlrootname: this.curr_tree_node.xmlrootname,
          content: data,
        },
      };
      this.get_socket(obj);
      setTimeout(() => {
        this.$message.success(this.$t("map.caozuochenggong"));
      }, 2000);
    },
    /**
     * 参数设置
     */
    click_cssz_set() {
      let sn = this.leftZeroPadding(this.rightTreeData.devID, 12);
      let click_type = 2;
      let data = this.row_curr_cssz;
      data["MESSAGE_TYPE"] = click_type;

      this["cssztreeItemsObj_" + this.rightTreeData.dev_type_sn][
        "a" + this.curr_tree_node.id
      ].forEach((it) => {
        if (typeof it.list === "undefined") {
          data[it.key] = it.value;
        } else {
          switch (this.curr_tree_node.nodeName) {
            case "sjsz":
              data["TimeZone"] =
                parseInt(it.list[0].value) + parseInt(it.list[1].value) * 26;
              break;
          }
        }
      });

      var obj = {
        deviceid: sn,
        order: "8900",
        content: {
          msgtype: "FA",
          xmlrootname: this.curr_tree_node.xmlrootname,
          content: data,
        },
      };
      this.get_socket(obj);
      setTimeout(() => {
        this.$message.success(this.$t("map.caozuochenggong"));
      }, 2000);
    },
    /**
     * 参数设置获得数据
     */
    click_cssz_get() {
      let sn = this.leftZeroPadding(this.rightTreeData.devID, 12);
      let click_type = 1;
      // this.curr_tree_node
      var data = {
        MESSAGE_TYPE: click_type,
        MESSAGE_ID: this.curr_tree_node.MESSAGE_ID,
      };
      if (this.curr_tree_node.nodeName == "chsz") {
        data["diskId"] = this.cssztreeItemsObj_81801["a204"][0]["value"];
      } else if (this.curr_tree_node.nodeName == "jsdbj") {
        data["id"] = this.cssztreeItemsObj_81801["a403"][0]["value"];
      } else if (this.curr_tree_node.nodeName == "dybj") {
        data["id"] = this.cssztreeItemsObj_81801["a405"][0]["value"];
      }
      var obj = {
        deviceid: sn,
        order: "8900",
        content: {
          msgtype: "FA",
          xmlrootname: this.curr_tree_node.xmlrootname,
          content: data,
        },
      };
      if (this.curr_tree_node.nodeName == "bmsz") {
        //
        setTimeout(() => {
          this.get_socket(obj);
        }, 2000);
      } else {
        //
        this.get_socket(obj);
      }
    },
    click_jssz() {
      let sn = this.leftZeroPadding(this.rightTreeData.devID, 12);
      var is_empty = 0;
      var zgsu_val = this.jssz_temp.zgsu_val;
      var cscxsj_val = this.jssz_temp.cscxsj_val;
      var dtljsj_val = this.jssz_temp.dtljsj_val;
      var lxjssj_val = this.jssz_temp.lxjssj_val;
      var zctcsj_val = this.jssz_temp.zctcsj_val;
      var zxxxsj_val = this.jssz_temp.zxxxsj_val;

      var obj = {
        deviceid: sn,
        order: "8103",
        content: {
          varcount: 0,
          varlist: [],
        },
      };

      if (zgsu_val.replace(/(^s)(s$)/g, "").length != 0) {
        is_empty = 1;
        var zgsu_val = parseInt(zgsu_val);
        if (zgsu_val < 0 || zgsu_val > 300) {
          this.$message.error(this.$t("map.qingshezhizhengquedezuigaosudu"));
          return;
        }
        //需要转为16进制
        var zgsu_val = zgsu_val.toString(16);
        var value = {
          varid: "00000055",
          vartype: 4,
          varvalue: zgsu_val,
        };
        obj.content.varlist.push(value);
      }

      if (cscxsj_val.replace(/(^s)(s$)/g, "").length != 0) {
        is_empty = 1;
        var cscxsj_val = parseInt(cscxsj_val);
        if (cscxsj_val < 0 || cscxsj_val == 0) {
          this.$message.error(
            this.$t("map.qingshezhizhengquedechaosuchixushijian")
          );

          return;
        }
        //需要转为16进制
        var cscxsj_val = cscxsj_val.toString(16);
        var value = {
          varid: "00000056",
          vartype: 4,
          varvalue: cscxsj_val,
        };
        obj.content.varlist.push(value);
      }

      if (dtljsj_val.replace(/(^s)(s$)/g, "").length != 0) {
        is_empty = 1;
        var dtljsj_val = parseInt(dtljsj_val);
        if (dtljsj_val < 0 || dtljsj_val == 0) {
          this.$message.error(
            this.$t("map.qingshezhizhengquededangtianleijijiashishijian")
          );
          return;
        }
        //需要转为16进制
        var dtljsj_val = dtljsj_val.toString(16);
        var value = {
          varid: "00000058",
          vartype: 4,
          varvalue: dtljsj_val,
        };
        obj.content.varlist.push(value);
      }

      if (lxjssj_val.replace(/(^s)(s$)/g, "").length != 0) {
        is_empty = 1;
        var lxjssj_val = parseInt(lxjssj_val);
        if (lxjssj_val < 0 || lxjssj_val == 0) {
          this.$message.error(
            this.$t("map.qingshezhizhengquedelianxujiashishijian")
          );
          return;
        }
        //需要转为16进制
        var lxjssj_val = lxjssj_val.toString(16);
        var value = {
          varid: "00000057",
          vartype: 4,
          varvalue: lxjssj_val,
        };
        obj.content.varlist.push(value);
      }

      if (zctcsj_val.replace(/(^s)(s$)/g, "").length != 0) {
        is_empty = 1;
        var zctcsj_val = parseInt(zctcsj_val);
        if (zctcsj_val < 0 || zctcsj_val == 0) {
          this.$message.error(
            this.$t("map.qingshezhizhengquedezuichangtingcheshijian")
          );
          return;
        }
        //需要转为16进制
        var zctcsj_val = zctcsj_val.toString(16);
        var value = {
          varid: "0000005A",
          vartype: 4,
          varvalue: zctcsj_val,
        };
        obj.content.varlist.push(value);
      }

      if (zxxxsj_val.replace(/(^s)(s$)/g, "").length != 0) {
        is_empty = 1;
        var zxxxsj_val = parseInt(zxxxsj_val);
        if (zxxxsj_val < 0 || zxxxsj_val == 0) {
          this.$message.error(
            this.$t("map.qingshezhizhengquedezuixiaoxiuxishijian")
          );
          return;
        }
        //需要转为16进制
        var zxxxsj_val = zxxxsj_val.toString(16);
        var value = {
          varid: "00000059",
          vartype: 4,
          varvalue: zxxxsj_val,
        };
        obj.content.varlist.push(value);
      }
      if (is_empty != 1) {
        this.$message.error(this.$t("map.qingtianxieduiyingdeshuzhi"));
        return;
      }
      obj.content.varcount = obj.content.varlist.length;
      this.get_socket(obj);
      setTimeout(() => {
        this.$message.success(this.$t("map.caozuochenggong"));
      }, 2000);
    },
    click_lc() {
      let sn = this.leftZeroPadding(this.rightTreeData.devID, 12);
      let value = this.lc_val;
      if (this.dev_type_sn == "81802") {
        this.lcbc81802(sn, value);
      } else {
        var new_values = parseInt(value) * 100;
        var obj = {
          deviceid: sn,
          order: "8900",
          content: {
            msgtype: "FA",
            xmlrootname: "Info_speed",
            content: {
              MESSAGE_ID: "2",
              MESSAGE_TYPE: "2",
              Mileage: new_values,
            },
          },
        };
        this.get_socket(obj);
      }
      setTimeout(() => {
        this.$message.success(this.$t("map.caozuochenggong"));
      }, 2000);
    },
    lcbc81802(ay_dev, value) {
      GpsSocket.sendMsg("order", {
        deviceid: ay_dev,
        order: "8300",
        content: {
          msg: "#SET MILEAGE:" + value,
        },
      });
    },
    /**
     * 对讲功能
     */
    click_dj() {
      let sn = this.leftZeroPadding(this.rightTreeData.devID, 12);
    },
    open_Monitor(sn, channel_id) {
      var video_array = "test.cnms365.com";
      video_array = video_array.split("#");
      var video_port = "9500";
      var channel_id = channel_id + 32;
      GpsSocket.sendMsg("order", {
        deviceid: sn,
        order: "9101",
        content:
          video_array[0] + "," + video_port + ",0," + channel_id + ",2,0",
      });
    },
    click_cq() {
      let sn = this.leftZeroPadding(this.rightTreeData.devID, 12);
      var obj = {
        deviceid: sn,
        order: "8105",
        content: { varid: "04", varvalue: "" },
      };
      this.get_socket(obj);
      setTimeout(() => {
        this.$message.success(this.$t("map.caozuochenggong"));
      }, 2000);
    },
    click_clkz() {
      let sn = this.leftZeroPadding(this.rightTreeData.devID, 12);
      var carMen = this.clkz_temp.carMen;
      var Circuit = this.clkz_temp.Circuit;

      if (carMen == 1) {
        if (Circuit == 1) {
          var codeValue = 3;
        } else {
          var codeValue = 1;
        }
      } else {
        if (Circuit == 1) {
          var codeValue = 2;
        } else {
          var codeValue = 0;
        }
      }
      var obj = { deviceid: sn, order: "8500", content: codeValue };
      this.get_socket(obj);
      setTimeout(() => {
        this.$message.success(this.$t("map.caozuochenggong"));
      }, 2000);
    },
    /**
     * gps上报时间
     */
    click_gpssb() {
      let sn = this.leftZeroPadding(this.rightTreeData.devID, 12);
      var timeValue = this.gps_temp.timeValue;
      var timeValue = timeValue.replace(/(^\s*)|(\s*$)/g, ""); //过滤空格

      var xm_timeValue = this.gps_temp.xm_timeValue;
      var xm_timeValue = xm_timeValue.replace(/(^\s*)|(\s*$)/g, ""); //过滤空格

      if (
        timeValue.replace(/(^s)(s$)/g, "").length == 0 &&
        xm_timeValue.replace(/(^s)(s$)/g, "").length == 0
      ) {
        this.$message.error(this.$t("map.liangxiangshujubunengquanbuweikong"));
        return;
      }
      var obj = {
        deviceid: sn,
        order: "8103",
        content: {
          varcount: 1,
          varlist: [],
        },
      };

      if (timeValue.replace(/(^s)(s$)/g, "").length != 0) {
        var time = parseInt(timeValue);
        if (time < 0 || time == 0) {
          //只能大于0

          this.$message.error(this.$t("map.bunnegxiaoyu0huozhedayu0"));
          return;
        }
        //需要转为16进制
        var newTimeValue = time.toString(16);
        var value = {
          varid: "00000029",
          vartype: 4,
          varvalue: newTimeValue,
        };
        obj.content.varlist.push(value);
      }

      if (xm_timeValue.replace(/(^s)(s$)/g, "").length != 0) {
        var time = parseInt(xm_timeValue);
        if (time < 0 || time == 0) {
          //只能大于0
          this.$message.error(this.$t("map.bunnegxiaoyu0huozhedayu0"));
          return;
        }
        //需要转为16进制
        var newTimeValue = time.toString(16);
        var value = {
          varid: "00000027",
          vartype: 4,
          varvalue: newTimeValue,
        };
        obj.content.varlist.push(value);
      }
      this.get_socket(obj);
      setTimeout(() => {
        this.$message.success(this.$t("map.caozuochenggong"));
      }, 2000);
    },
    /**
     * 校准G-SENSOR
     */
    click_bzgsensor() {
      let sn = this.leftZeroPadding(this.rightTreeData.devID, 12);
      var obj = {
        deviceid: sn,
        order: "8900",
        content: {
          msgtype: "FA",
          xmlrootname: "Control_system",
          content: {
            MESSAGE_ID: "2",
            MESSAGE_TYPE: "2",
            Type: "10",
            Subtype: "0",
            State: "0",
            State1: "0",
            State2: "0",
            Data: "",
            DataLen: "0",
          },
        },
      };

      this.get_socket(obj);
      setTimeout(() => {
        this.$message.success(this.$t("map.caozuochenggong"));
      }, 2000);
    },
    /**
     * 格式化SD卡
     */
    click_gshsdk() {
      let sn = this.leftZeroPadding(this.rightTreeData.devID, 12);
      var obj = {
        deviceid: sn,
        order: "8900",
        content: {
          msgtype: "FA",
          xmlrootname: "Control_system",
          content: {
            MESSAGE_ID: "2",
            MESSAGE_TYPE: "2",
            Type: "25",
            Subtype: "0",
            State: "3",
            State1: "0",
            State2: "0",
            Data: "",
            DataLen: "0",
          },
        },
      };
      this.get_socket(obj);
      setTimeout(() => {
        this.$message.success(this.$t("map.caozuochenggong"));
      }, 2000);
    },
    /**
     * 格式化硬盘
     */
    click_gshyp() {
      let sn = this.leftZeroPadding(this.rightTreeData.devID, 12);
      var obj = {
        deviceid: sn,
        order: "8900",
        content: {
          msgtype: "FA",
          xmlrootname: "Control_system",
          content: {
            MESSAGE_ID: "2",
            MESSAGE_TYPE: "2",
            Type: "25",
            Subtype: "0",
            State: "2",
            State1: "0",
            State2: "0",
            Data: "",
            DataLen: "0",
          },
        },
      };
      this.get_socket(obj);
      setTimeout(() => {
        this.$message.success(this.$t("map.caozuochenggong"));
      }, 2000);
    },
    leftZeroPadding(number, length) {
      return String(number).padStart(length, "0");
    },
    get_socket(obj) {
      this.loadingInstance = ElLoading.service({
        lock: true,
      });
      this.is_show_loading = 1;
      // console.log("close1 loadingwxw");
      setTimeout(() => {
        // console.log("close2 loadingwxw");
        if (this.loadingInstance) {
          // console.log("close3 loadingwxw");
          this.loadingInstance.close();
        }
      }, 2000);
      // this.loadingInstance.show();
      GpsSocket.sendMsg("order", obj);
    },
    xfwzClick() {
      if (this.xfwz_val == "") {
        this.$message.error("请输入下发内容");
        return;
      }
      let nodes = this.$refs.treeRef_wz.getCheckedNodes();
      if (nodes && nodes.length > 0) {
        nodes.forEach((it) => {
          this.order_send(it.devID, this.xfwz_val);
        });
      }
      // console.log(nodes);
    },
    order_send(devID, msg) {
      GpsSocket.sendMsg("order", {
        deviceid: devID,
        order: "8300",
        content: {
          msgtype: "00001101",
          msg: msg,
        },
      });
    },
    get_pop_html(row) {
      let msg_top = "";
      // "<span class='iconfont icon-gps55 da'>&nbsp;" +
      // this.$t("map.zt_" + row.st) +
      // this.$t("map.gps_" + row.islatlng) +
      // "</span>";
      //msg_top += "<span class='iconfont icon-xinhao55 da'>&nbsp;弱</span>";
      // msg_top +=
      //   "<div style='display:flex;margin-right:3px;color:#2c78bf;align-items:center' ><div style='display:flex;align-items: flex-end;'><span style='width:2px;height:5px;background:#2c78bf;display:block;margin-right:2px'></span><span style='width:2px;height:8px;background:#2c78bf;display:block;margin-right:2px'></span><span style='width:2px;height:10px;background:#2c78bf;display:block;margin-right:2px'></span><span style='width:2px;height:12px;background:#2c78bf;display:block;margin-right:2px'></span><span style='width:2px;height:14px;background:rgba(0, 0, 0, 0.38);display:block;margin-right:2px'></span></div>&nbsp;弱</div>";
      if (row["lWireNetSignValue"] < 0) {
        //
        msg_top +=
          "<div style='display:flex;margin-right:3px;color:#2c78bf;align-items:center' ><div style='display:flex;align-items: flex-end;'><span style='width:2px;height:5px;background:rgba(0, 0, 0, 0.38);display:block;margin-right:2px'></span><span style='width:2px;height:8px;background:rgba(0, 0, 0, 0.38);display:block;margin-right:2px'></span><span style='width:2px;height:10px;background:rgba(0, 0, 0, 0.38);display:block;margin-right:2px'></span><span style='width:2px;height:12px;background:rgba(0, 0, 0, 0.38);display:block;margin-right:2px'></span><span style='width:2px;height:14px;background:rgba(0, 0, 0, 0.38);display:block;margin-right:2px'></span></div" +
          row["lWireNetSignValue"] +
          "</div>";
      } else if (
        row["lWireNetSignValue"] >= 0 &&
        row["lWireNetSignValue"] <= 6
      ) {
        //
        msg_top +=
          "<div style='display:flex;margin-right:3px;color:#2c78bf;align-items:center' ><div style='display:flex;align-items: flex-end;'><span style='width:2px;height:5px;background:#2c78bf;display:block;margin-right:2px'></span><span style='width:2px;height:8px;background:rgba(0, 0, 0, 0.38);display:block;margin-right:2px'></span><span style='width:2px;height:10px;background:rgba(0, 0, 0, 0.38);display:block;margin-right:2px'></span><span style='width:2px;height:12px;background:rgba(0, 0, 0, 0.38);display:block;margin-right:2px'></span><span style='width:2px;height:14px;background:rgba(0, 0, 0, 0.38);display:block;margin-right:2px'></span></div>" +
          row["lWireNetSignValue"] +
          "</div>";
      } else if (
        row["lWireNetSignValue"] >= 7 &&
        row["lWireNetSignValue"] <= 12
      ) {
        msg_top +=
          "<div style='display:flex;margin-right:3px;color:#2c78bf;align-items:center' ><div style='display:flex;align-items: flex-end;'><span style='width:2px;height:5px;background:#2c78bf;display:block;margin-right:2px'></span><span style='width:2px;height:8px;background:#2c78bf;display:block;margin-right:2px'></span><span style='width:2px;height:10px;background:rgba(0, 0, 0, 0.38);display:block;margin-right:2px'></span><span style='width:2px;height:12px;background:rgba(0, 0, 0, 0.38);display:block;margin-right:2px'></span><span style='width:2px;height:14px;background:rgba(0, 0, 0, 0.38);display:block;margin-right:2px'></span></div>" +
          row["lWireNetSignValue"] +
          "</div>";
      } else if (
        row["lWireNetSignValue"] >= 13 &&
        row["lWireNetSignValue"] <= 19
      ) {
        msg_top +=
          "<div style='display:flex;margin-right:3px;color:#2c78bf;align-items:center' ><div style='display:flex;align-items: flex-end;'><span style='width:2px;height:5px;background:#2c78bf;display:block;margin-right:2px'></span><span style='width:2px;height:8px;background:#2c78bf;display:block;margin-right:2px'></span><span style='width:2px;height:10px;background:#2c78bf;display:block;margin-right:2px'></span><span style='width:2px;height:12px;background:rgba(0, 0, 0, 0.38);display:block;margin-right:2px'></span><span style='width:2px;height:14px;background:rgba(0, 0, 0, 0.38);display:block;margin-right:2px'></span></div>" +
          row["lWireNetSignValue"] +
          "</div>";
      } else if (
        row["lWireNetSignValue"] >= 20 &&
        row["lWireNetSignValue"] <= 25
      ) {
        msg_top +=
          "<div style='display:flex;margin-right:3px;color:#2c78bf;align-items:center' ><div style='display:flex;align-items: flex-end;'><span style='width:2px;height:5px;background:#2c78bf;display:block;margin-right:2px'></span><span style='width:2px;height:8px;background:#2c78bf;display:block;margin-right:2px'></span><span style='width:2px;height:10px;background:#2c78bf;display:block;margin-right:2px'></span><span style='width:2px;height:12px;background:#2c78bf;display:block;margin-right:2px'></span><span style='width:2px;height:14px;background:rgba(0, 0, 0, 0.38);display:block;margin-right:2px'></span></div>" +
          row["lWireNetSignValue"] +
          "</div>";
      } else if (row["lWireNetSignValue"] >= 25) {
        msg_top +=
          "<div style='display:flex;margin-right:3px;color:#2c78bf;align-items:center' ><div style='display:flex;align-items: flex-end;'><span style='width:2px;height:5px;background:#2c78bf;display:block;margin-right:2px'></span><span style='width:2px;height:8px;background:#2c78bf;display:block;margin-right:2px'></span><span style='width:2px;height:10px;background:#2c78bf;display:block;margin-right:2px'></span><span style='width:2px;height:12px;background:#2c78bf;display:block;margin-right:2px'></span><span style='width:2px;height:14px;background:#2c78bf;display:block;margin-right:2px'></span></div>" +
          row["lWireNetSignValue"] +
          "</div>";
      }
      if (row["lGNSSStarCount"] > 0) {
        msg_top +=
          "<span class='iconfont icon-weixing55 da'>&nbsp;" +
          row["lGNSSStarCount"] +
          "</span>";
      }

      let html = "<div class='car_info' style='font-size: 10px;'>";
      html +=
        "<div class='car_info_header' style='width:370px; ' ><h2> " +
        row.cphm +
        "(" +
        row.dev_name +
        ") <div style='float:right;margin-right:30px;display:flex;align-items: center;'>" +
        msg_top +
        "</div></h2></div>";
      html +=
        "<div class='car_info_item' ><div style='width:200px;float:left;'><span class='car_info_item_span'><label>" +
        this.$t("map.pop_sj") +
        "&nbsp;" +
        "</label>" +
        row.dwsj +
        "</span></div>";
      html +=
        "<div style='width:130px;float:left;'><span class='car_info_item_span'><label>" +
        this.$t("map.pop_sd") +
        "&nbsp;" +
        "</label>" +
        row.sudu +
        "</span></div></div>";
      html += "<div style='clear:both;'></div> ";
      html +=
        "<div class='car_info_item' ><div style='width:200px;float:left;'><span class='car_info_item_span'><label>" +
        this.$t("map.ssgs") +
        "&nbsp;" +
        "</label>" +
        row.cpy_name +
        "</span></div>"; //所属公司
      html +=
        "<div style='width:130px;float:left;'><span class='car_info_item_span'><label>" +
        this.$t("map.pop_lc") +
        "&nbsp;" +
        "</label>" +
        row.licheng +
        "</span></div></div>";
      html += "<div style='clear:both;'></div> ";
      //后台选中直接展示地址 就直接展示地址代码
      // if (autoGetAddress == 1) {
      //   var address_info = display_get_addr(row);
      //   html +=
      //     "<div class='car_info_item' style='width: 330px;' ><label>" +
      //     this.$t("map.pop_wz") +
      //     "&nbsp;" +
      //     "</label><span data-lng='" +
      //     row.lng +
      //     "' data-lat='" +
      //     row.lat +
      //     "' data-wz='" +
      //     row.wz +
      //     "' data-islatlng='" +
      //     row.islatlng +
      //     "'>" +
      //     address_info +
      //     "</span></div>";
      // } else {
      html +=
        "<div class='car_info_item' style='width: 330px;' ><label>" +
        this.$t("map.pop_wz") +
        "&nbsp;" +
        "</label><span  class = 'googleAddressInfo'    data-lng='" +
        row.lng +
        "' data-lat='" +
        row.lat +
        "' data-wz='" +
        row.wz +
        "' data-islatlng='" +
        row.islatlng +
        "'>" +
        row.address +
        "</span></div>";
      // }
      this.$t("map.zt_0");
      // var msg = "";
      var msg =
        this.$t("map.zt_" + row.st) + this.$t("map.gps_" + row.islatlng);
      var str = row["lStatus"];
      var str_2 = parseInt(str).toString(2);

      var ay = str_2.split("");
      ay.reverse();
      msg += this.$t("map.s_1.s" + ay[0]);

      // msg += lang["gps_" + row["islatlng"]];

      // if (row["pLocate"] == 1) {
      //   msg += ",GPS定位";
      // }

      if (row["lNetType"] >= 0) {
        msg +=
          "," +
          [
            this.$t("map.yx"),
            "4G",
            "WIFI",
            "5G",
            "3G",
            "2G",
            this.$t("map.wz"),
          ][row["lNetType"]];
      }

      var xh = this.$t("map.yxh");
      if (row["lWireNetSignValue"] > 0 && row["lWireNetSignValue"] <= 13) {
        xh = this.$t("map.xhc");
      }

      if (row["lWireNetSignValue"] > 13 && row["lWireNetSignValue"] <= 20) {
        xh = this.$t("map.xhyb");
      }
      if (row["lWireNetSignValue"] > 20) {
        xh = this.$t("map.xhh");
      }
      // msg += "," + xh;

      // if (row["lGNSSStarCount"] > 0) {
      //   msg += "," + this.$t("map.wx") + "：" + row["lGNSSStarCount"];
      // }

      if (row["lRecordStatus"] != "" && row["lRecordStatus"] != null) {
        var str_str = row["lRecordStatus"];
        var str_ay = str_str.split(",");
        var result_ay = [];
        for (var i = 0; i < str_ay.length; i++) {
          if (str_ay[i] != "") {
            result_ay.push("CH" + str_ay[i]);
          }
        }
        msg += "," + result_ay.join(",");
      }

      var car_zt = row["lExtStatus"];
      var car_zt_2 = parseInt(car_zt).toString(2);
      var car_ay = car_zt_2.split("");
      car_ay.reverse();

      if (car_ay[2] == 1) {
        msg += "," + this.$t("map.yz");
      }
      if (car_ay[3] == 1) {
        msg += "," + this.$t("map.zz");
      }
      if (car_ay[4] == 1) {
        msg += "," + this.$t("map.cc");
      }
      if (car_ay[5] == 1) {
        msg += "," + this.$t("map.dc");
      }

      html +=
        "<div class='car_info_item'  style='width: 325px' ><label>" +
        this.$t("map.pop_zt") +
        "&nbsp;" +
        "</label><span>" +
        msg +
        "</span></div>";

      var ay_str = row["lAlarmMsg"];

      var ay_ay = ay_str.split(",");

      var ay_result = [];
      var str_temp = "";
      var str_a, ay_a, j;
      var str_str, str_ay;
      for (var i = 0; i < ay_ay.length; i++) {
        if (ay_ay[i] >= 217 && ay_ay[i] <= 224) continue;
        if (ay_ay[i] == "") continue;
        str_temp = this.$t("map.e_" + ay_ay[i]);
        if (ay_ay[i] == 101) {
          str_a = row["lVideoLossStatus"];
          ay_a = str_a.split(",");
          for (j = 0; j < ay_a.length; j++) {
            ay_a[j] = "CH" + ay_a[j];
          }
          str_temp += ay_a.join(",");
        } else if (ay_ay[i] == 103) {
          str_str = row["lVideoMemoryFailureStatus"];
          str_ay = str_str.split(",");
          result_ay = [];
          for (var ik = 0; ik < str_ay.length; ik++) {
            if (str_ay[ik] != "") {
              if (str_ay[ik] == 1) {
                result_ay.push("HDD");
              } else {
                if (str_ay[ik] == 2) {
                  result_ay.push("SD");
                } else {
                  result_ay.push("SD" + (parseInt(str_ay[ik]) - 1));
                }
              }
            }
          }
          str_temp += result_ay.join(",");
        } else if (ay_ay[i] == 102) {
          str_a = row["lVideoBlockingStatus"];
          ay_a = str_a.split(",");
          for (j = 0; j < ay_a.length; j++) {
            ay_a[j] = "CH" + ay_a[j];
          }
          str_temp += ay_a.join(",");
        }

        ay_result.push(str_temp);
      }

      //不录像
      var str_blx = row["lRecordState"];
      if (str_blx != "") {
        var str_temp_blx = this.$t("map.blx") + ",";
        var ay_blx = str_blx.split(",");
        for (var i = 0; i < ay_blx.length; i++) {
          ay_blx[i] = "CH" + ay_blx[i];
        }
        str_temp_blx += ay_blx.join(",");
        ay_result.push(str_temp_blx);
      }

      var str_output = ay_result.join(",");

      str_output = str_output.replace(/(^[\s,]*)|([\s,]*$)/g, "");
      if (str_output != "") {
        html +=
          "<div class='car_info_item'   style='width: 350px' ><label>" +
          this.$t("map.pop_bj") +
          "&nbsp;" +
          "</label><span class='bj'>" +
          str_output +
          "</span></div>";
      }

      html += "<div style='clear:both;padding-top:10px'>";

      html += "</div>";
      return html;
    },
    checkedNodes(tree_node, checked) {
      if (checked) {
        let ay_veh = [];
        if (tree_node.nodetype == 2) {
          ay_veh.push(tree_node.nodeId);
        } else if (tree_node.nodetype == 1) {
          const get_vehs = (ay_children) => {
            if (ay_children.length > 0) {
              ay_children.forEach((item) => {
                if (item.nodetype == 2) {
                  ay_veh.push(item.nodeId);
                } else {
                  item.children && get_vehs(item.children);
                }
              });
            }
          };
          get_vehs(tree_node.children);
        }

        // ay_veh.forEach((it) => {
        //   if (!this.ay_veh.includes(it)) {
        //     this.ay_veh.push(it);
        //   }
        // });

        this.$api.alarm_vehicle_info({ id: ay_veh.join(",") }).then((res) => {
          let ay = res.data.data;

          console.log("ay", ay);
          if (ay.length > 0) {
            let row_img = null;
            let icon = "";
            let dataCar_temp = [...this.dataCar];
            ay.forEach((it) => {
              // console.log("it", it);
              row_img = imgData(it.st);
              icon =
                "Public/tree/dxtree/imgs/vehicle/" +
                it.icon +
                "/" +
                row_img.dirName +
                "/" +
                car_dir(it.fangxiang) +
                ".png";
              it.icon = getHTTPURL() + icon;
              it.color = row_img.color;
              it.html = this.get_pop_html(it);
              // console.log("icon", icon);
              this.$refs.mainMap.addMarker(it);
              //判断有车
              if (!this.ay_veh.includes(it.id)) {
                this.ay_veh.push(it.id);
                dataCar_temp = dataCar_temp.concat(it);
              }
            });
            this.dataCar.splice(0, this.dataCar.length);
            this.dataCar = dataCar_temp;
            this.showBottom();
            setTimeout(() => {
              this.calculateBoxes();
            }, 100);
          }
          // console.log("this.dataCar", this.dataCar);
        });
      } else {
        let ay_veh = [];
        if (tree_node.nodetype == 2) {
          ay_veh.push(tree_node.nodeId);
        } else if (tree_node.nodetype == 1) {
          const get_vehs = (ay_children) => {
            if (ay_children.length > 0) {
              ay_children.forEach((item) => {
                if (item.nodetype == 2) {
                  ay_veh.push(item.nodeId);
                } else {
                  item.children && get_vehs(item.children);
                }
              });
            }
          };
          get_vehs(tree_node.children);
        }

        let dataCar_temp = [...this.dataCar];
        ay_veh.forEach((it) => {
          this.$refs.mainMap.removeMaerker({ id: it });
          for (let i = this.ay_veh.length - 1; i >= 0; i--) {
            if (this.ay_veh[i] == it) {
              this.ay_veh.splice(i, 1);
            }
          }
          for (let i = dataCar_temp.length - 1; i >= 0; i--) {
            if (dataCar_temp[i].id == it) {
              dataCar_temp.splice(i, 1);
            }
          }
        });

        this.dataCar.splice(0, this.dataCar.length);
        this.dataCar = dataCar_temp;
        this.showBottom();
        console.log("this.dataCar", this.dataCar);
        setTimeout(() => {
          this.calculateBoxes();
        }, 100);

        // this.$api.alarm_vehicle_info({ id: ay_veh.join(",") }).then((res) => {
        //   let ay = res.data.data;
        //   console.log("ay", ay);
        //   if (ay.length > 0) {
        //     let row_img = null;
        //     let icon = "";
        //     ay.forEach((it) => {
        //       this.$refs.mainMap.removeMaerker(it);
        //     });
        //   }
        //   // console.log(res.data);
        // });
      }

      // this.$refs.mainMap.addTest();
      // if (checked) {
      // } else {
      // }
      // console.log(tree_node, checked);
    },
    init_baidu() {
      this.$nextTick(() => {
        var normalMap = L.tileLayer.chinaProvider("Baidu.Normal.Map", {
            maxZoom: 18,
            minZoom: 5,
          }),
          satelliteMap = L.tileLayer.chinaProvider("Baidu.Satellite.Map", {
            maxZoom: 18,
            minZoom: 5,
          }),
          annotionMap = L.tileLayer.chinaProvider("Baidu.Satellite.Annotion", {
            maxZoom: 18,
            minZoom: 5,
          });

        let normalMap_google = L.tileLayer.chinaProvider("Google.Normal.Map", {
          maxZoom: 18,
          minZoom: 5,
        });

        var baseLayers = {
          地图: normalMap,
          影像: satelliteMap,
          google: normalMap_google,
        };

        // var overlayLayers = {
        //   标注: annotionMap,
        // };

        var map = L.map("map", {
          crs: L.CRS.Baidu,
          minZoom: 3,
          maxZoom: 18,
          attributionControl: false,
          center: [38.873433, 115.487135],
          zoom: 12,
        });
        // normalMap.addTo(map);

        L.control.layers(baseLayers).addTo(map);
      });
    },
    closeMsg() {
      if (this.mapBottomHeight > 0) {
        this.mapBottomHeight = 0;
      } else {
        this.mapBottomHeight = 200;
      }
    },
    cssszHandleNodeClick(row, data) {
      console.log(row, data, this.rightTreeData);
      this.curr_tree_node = row;
      if (this.cssztreeItemsObj["a" + row.id]) {
        this.cssztreeItems = this.cssztreeItemsObj["a" + row.id];
      }
      console.log("802", this.rightTreeData.dev_type_sn);
      if (this.rightTreeData.dev_type_sn == "81802") {
        //处理通道数量
        let ay_ch = this.rightTreeData.dPara.split(",");
        let ay_ch_zlm = [];
        switch (this.curr_tree_node.nodeName) {
          case "dygl":
            this["cssztreeItemsObj_" + this.rightTreeData.dev_type_sn][
              "a" + this.curr_tree_node.id
            ].forEach((it) => {
              if (it.key == "chk_ch") {
                let options = [];
                let chk = [];
                ay_ch.forEach((ic, ick) => {
                  options.push({
                    label: ic,
                    value: ick + 1,
                  });
                  chk.push(ick + 1);
                });
                it.options = options;
                it.value = chk;
              }
            });
            break;
          case "zlm":
            // ay_ch_zlm.push({
            //   label: this.$t("map.tongdao"),
            //   width: "130px",
            //   type: "list",
            //   value: "",
            //   key: "Phonenumber",
            //   unit: "",
            //   list: [
            //     {
            //       type: "span",
            //       label: this.$t("map.fenbianlv"),
            //       value: "1",
            //       key: 1,
            //       unit: "",
            //     },
            //     {
            //       type: "span",
            //       label: this.$t("map.zhenglv"),
            //       value: "2",
            //       key: 2,
            //       unit: "",
            //     },
            //     {
            //       type: "span",
            //       label: this.$t("map.huazhi"),
            //       value: "3",
            //       key: 3,
            //       unit: "",
            //     },
            //     {
            //       type: "span",
            //       label: this.$t("map.luxiang"),
            //       value: "4",
            //       key: 4,
            //       unit: "",
            //     },
            //     {
            //       type: "span",
            //       label:this.$t("map.jingxiang"),
            //       value: "5",
            //       key: 5,
            //       unit: "",
            //     },
            //     {
            //       type: "span",
            //       label: this.$t("map.luyin"),
            //       value: "6",
            //       key: 6,
            //       unit: "",
            //     },
            //   ],
            // });
            // ay_ch.forEach((ic, ick) => {
            //   ay_ch_zlm.push({
            //     label: ic,
            //     width: "130px",
            //     type: "list",
            //     value: "",
            //     key: "Phonenumber",
            //     unit: "",
            //     list: [
            //       {
            //         label: "",
            //         width: "130px",
            //         type: "sel",
            //         value: "",
            //         key: "MainChn_chn" + ick + "_Resolution",
            //         unit: "",
            //         options: [
            //           {
            //             label: "HD1080",
            //             value: 0,
            //           },
            //           {
            //             label: "HD720",
            //             value: 1,
            //           },
            //           {
            //             label: "D1",
            //             value: 3,
            //           },
            //           {
            //             label: "CIF",
            //             value: 5,
            //           },
            //         ],
            //       },
            //       {
            //         label: this.$t("map.zhenglv"),
            //         width: "130px",
            //         type: "sel",
            //         value: "",
            //         key: "MainChn_chn" + ick + "_FrameRate",
            //         unit: "",
            //         options: [
            //           { label: 1, value: 1 },
            //           { label: 2, value: 2 },
            //           { label: 3, value: 3 },
            //           { label: 4, value: 4 },
            //           { label: 5, value: 5 },
            //           { label: 6, value: 6 },
            //           { label: 7, value: 7 },
            //           { label: 8, value: 8 },
            //           { label: 9, value: 9 },
            //           { label: 10, value: 10 },
            //           { label: 11, value: 11 },
            //           { label: 12, value: 12 },
            //           { label: 13, value: 13 },
            //           { label: 14, value: 14 },
            //           { label: 15, value: 15 },
            //           { label: 16, value: 16 },
            //           { label: 17, value: 17 },
            //           { label: 18, value: 18 },
            //           { label: 19, value: 19 },
            //           { label: 20, value: 20 },
            //           { label: 21, value: 21 },
            //           { label: 22, value: 22 },
            //           { label: 23, value: 23 },
            //           { label: 24, value: 24 },
            //           { label: 25, value: 25 },
            //         ],
            //       },
            //       {
            //         label: this.$t("map.huazhi"),
            //         width: "130px",
            //         type: "sel",
            //         value: "",
            //         key: "MainChn_chn" + ick + "_Quality",
            //         unit: "",
            //         options: [
            //           { label: 1, value: 1 },
            //           { label: 2, value: 2 },
            //           { label: 3, value: 3 },
            //           { label: 4, value: 4 },
            //           { label: 5, value: 5 },
            //           { label: 6, value: 6 },
            //           { label: 7, value: 7 },
            //           { label: 8, value: 8 },
            //         ],
            //       },
            //       {
            //         label: this.$t("map.kaiguan"),
            //         width: "130px",
            //         type: "sel",
            //         value: "",
            //         key: "MainChn_chn" + ick + "_isRec",
            //         unit: "",
            //         options: [
            //           {
            //             label: this.$t("map.guanbi"),
            //             value: 0,
            //           },
            //           {
            //              label: this.$t("map.kaiqi"),
            //             value: 1,
            //           },
            //         ],
            //       },
            //       {
            //         label: this.$t("map.kaiguan"),
            //         width: "130px",
            //         type: "sel",
            //         value: "",
            //         key: "MainChn_chn" + ick + "_Mirror",
            //         unit: "",
            //         options: [
            //           {
            //             label: this.$t("map.guanbi"),
            //             value: 0,
            //           },
            //           {
            //             label: this.$t("map.zuoyoujingxiang"),
            //             value: 1,
            //           },
            //           {
            //             label: "上下镜像",
            //             value: 2,
            //           },
            //           {
            //             label: "上下+左右镜像",
            //             value: 3,
            //           },
            //         ],
            //       },
            //       {
            //         label: this.$t("map.kaiguan"),
            //         width: "130px",
            //         type: "sel",
            //         value: "",
            //         key: "MainChn_chn" + ick + "_HaveAudio",
            //         unit: "",
            //         options: [
            //           {
            //             label: this.$t("map.guanbi"),
            //             value: 0,
            //           },
            //           {
            //              label: this.$t("map.kaiqi"),
            //             value: 1,
            //           },
            //         ],
            //       },
            //     ],
            //   });
            // });
            // this["cssztreeItemsObj_" + this.rightTreeData.dev_type_sn][
            //   "a" + this.curr_tree_node.id
            // ] = ay_ch_zlm;
            break;
        }
        //发送socket请求
        let sn = this.leftZeroPadding(this.rightTreeData.devID, 12);
        let order = "B054";
        let node = row.xmlrootname;

        let obj = { deviceid: sn, order: order, content: node };
        this.get_socket(obj);
      }
    },
    rightMenuOpened() {
      switch (this.rightClickAct) {
        case "xfwz":
          this.$refs.treeRef_wz.setChecked(this.rightTreeNode.key, true);
          this.xfwz_tableData.splice(0, this.xfwz_tableData.length);
          break;
        case "clxx":
          this.$api
            .search_getVehInfo({ vPlate: this.rightTreeData.vPlate })
            .then((res) => {
              // console.log(res.data);
              this.veh_data = res.data.data;
              let sn = this.leftZeroPadding(this.rightTreeData.devID, 12);
              GpsSocket.sendMsg("order", {
                deviceid: sn,
                order: "B040",
                content: "",
              });
            });
          break;
        case "cssz":
        case "clxx":
          break;
      }
    },
    clearObj(obj) {
      for (let p in obj) {
        obj[p] = "";
      }
    },
    clickMenuItem(flag) {
      this.deepClearData(this.cssztreeItems);
      this.deepClearData(this.cssztreeItemsObj_81801);
      this.deepClearData(this.cssztreeItemsObj_81802);
      this.clearObj(this.veh_data);
      this.clearObj(this.clxx_temp);
      this.tableData_lx = [];
      this.tableData_cc = [];
      this.clearObj(this.jssz_temp);
      this.lc_val = "";
      this.dj_val = "1";
      this.clkz_temp.carMen = "1";
      this.clkz_temp.Circuit = "1";
      this.clearObj(this.gps_temp);
      // this.deepClearData(this.veh_data);
      // this.cssztreeItemsObj_81801 = this.deepCopy(
      //   this.cssztreeItemsObj_81801_temp
      // );
      // this.cssztreeItemsObj_81802 = this.deepCopy(
      //   this.cssztreeItemsObj_81802_temp
      // );

      this.rightClickTitle = this.rightTreeData.vPlate;
      switch (flag) {
        case "xfwz":

        case "clxx":
          this.rightClickWidth = "900px";
          break;
        case "cssz":
        case "jssz":
          this.rightClickWidth = "700px";
          break;

        default:
          this.rightClickWidth = "400px";
          break;
      }
      this.rightClickAct = flag;
      this.rightClickDialog = true;

      this.showRightMenu = false;

      // console.log(flag);
    },
    rightClick(event, data, node) {
      console.log(event, data, node);
      // if (data.carstatus == 2) return;
      this.rightTreeNode = node;
      this.rightTreeData = data;
      this.cssztreeData = this["cssztreeData_" + data.dev_type_sn];
      this.cssztreeItemsObj = this["cssztreeItemsObj_" + data.dev_type_sn];
      this.dev_type_sn = data.dev_type_sn;
      if (data.dev_type_sn == "81802") {
        let ay_ch = data.dPara.split(",");
        let ay_ch_zlm = [];
        ay_ch_zlm.push({
          label: this.$t("map.fanyi82"),
          width: "130px",
          type: "list",
          value: "",
          key: "Phonenumber",
          unit: "",
          list: [
            {
              type: "span",
              label: this.$t("map.fanyi85"),
              value: "1",
              key: 1,
              unit: "",
            },
            {
              type: "span",
              label: this.$t("map.fanyi86"),
              value: "2",
              key: 2,
              unit: "",
            },
            {
              type: "span",
              label: this.$t("map.fanyi87"),
              value: "3",
              key: 3,
              unit: "",
            },
            {
              type: "span",
              label: this.$t("map.luxiang"),
              value: "4",
              key: 4,
              unit: "",
            },
            {
              type: "span",
              label: this.$t("map.fanyi89"),
              value: "5",
              key: 5,
              unit: "",
            },
            {
              type: "span",
              label: this.$t("map.luyin"),
              value: "6",
              key: 6,
              unit: "",
            },
          ],
        });
        ay_ch.forEach((ic, ick) => {
          ay_ch_zlm.push({
            label: ic,
            width: "130px",
            type: "list",
            value: "",
            key: "Phonenumber",
            unit: "",
            list: [
              {
                label: "",
                width: "130px",
                type: "sel",
                value: "",
                key: "MainChn_chn" + ick + "_Resolution",
                unit: "",
                options: [
                  {
                    label: "HD1080",
                    value: 0,
                  },
                  {
                    label: "HD720",
                    value: 1,
                  },
                  {
                    label: "D1",
                    value: 3,
                  },
                  {
                    label: "CIF",
                    value: 5,
                  },
                ],
              },
              {
                label: this.$t("map.zhenglv"),
                width: "130px",
                type: "sel",
                value: "",
                key: "MainChn_chn" + ick + "_FrameRate",
                unit: "",
                options: [
                  { label: 1, value: 1 },
                  { label: 2, value: 2 },
                  { label: 3, value: 3 },
                  { label: 4, value: 4 },
                  { label: 5, value: 5 },
                  { label: 6, value: 6 },
                  { label: 7, value: 7 },
                  { label: 8, value: 8 },
                  { label: 9, value: 9 },
                  { label: 10, value: 10 },
                  { label: 11, value: 11 },
                  { label: 12, value: 12 },
                  { label: 13, value: 13 },
                  { label: 14, value: 14 },
                  { label: 15, value: 15 },
                  { label: 16, value: 16 },
                  { label: 17, value: 17 },
                  { label: 18, value: 18 },
                  { label: 19, value: 19 },
                  { label: 20, value: 20 },
                  { label: 21, value: 21 },
                  { label: 22, value: 22 },
                  { label: 23, value: 23 },
                  { label: 24, value: 24 },
                  { label: 25, value: 25 },
                ],
              },
              {
                label: this.$t("map.huazhi"),
                width: "130px",
                type: "sel",
                value: "",
                key: "MainChn_chn" + ick + "_Quality",
                unit: "",
                options: [
                  { label: 1, value: 1 },
                  { label: 2, value: 2 },
                  { label: 3, value: 3 },
                  { label: 4, value: 4 },
                  { label: 5, value: 5 },
                  { label: 6, value: 6 },
                  { label: 7, value: 7 },
                  { label: 8, value: 8 },
                ],
              },
              {
                label: this.$t("map.fanyi99"),
                width: "130px",
                type: "sel",
                value: "",
                key: "MainChn_chn" + ick + "_isRec",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
              {
                label: this.$t("map.fanyi99"),
                width: "130px",
                type: "sel",
                value: "",
                key: "MainChn_chn" + ick + "_Mirror",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.zuoyoujingxiang"),
                    value: 1,
                  },
                  {
                    label: this.$t("map.shangxiajingxiang"),
                    value: 2,
                  },
                  {
                    label: this.$t("map.shangxiazuoyoujingxiang"),
                    value: 3,
                  },
                ],
              },
              {
                label: this.$t("map.kaiguan"),
                width: "130px",
                type: "sel",
                value: "",
                key: "MainChn_chn" + ick + "_HaveAudio",
                unit: "",
                options: [
                  {
                    label: this.$t("map.guanbi"),
                    value: 0,
                  },
                  {
                    label: this.$t("map.kaiqi"),
                    value: 1,
                  },
                ],
              },
            ],
          });
        });
        this["cssztreeItemsObj_" + data.dev_type_sn]["a206"] = ay_ch_zlm;
      }
      // if (data.dev_type_sn == "81801") {
      //   //
      // } else if (ata.dev_type_sn == "81802") {
      //   //
      // }
      // this.showRightMenu = false;
      let height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      console.log("height", height, event.clientY);
      let menu = document.querySelector(".rightMenu");
      menu.style.left = event.clientX + 10 + "px";
      if (event.clientY < height - 400) {
        menu.style.top = event.clientY + "px";
      } else {
        if (data.carstatus == 2) {
          menu.style.top = event.clientY + "px";
        } else {
          menu.style.top = event.clientY - 400 + "px";
        }
      }

      // this.showRightMenu = !this.showRightMenu;
      this.showRightMenu = true;
      document.addEventListener("click", this.menuHide);
    },
    menuHide() {
      // this.showRightMenu = !this.showRightMenu;
      this.showRightMenu = false;
      document.removeEventListener("click", this.menuHide);
    },
    filterMethod(query, node) {
      console.log(query, node);
      if (query == 1) {
        return true;
      } else if (query == 2) {
        if (node.nodetype == 2)
          return (
            node.carstatus == 6 ||
            node.carstatus == 7 ||
            node.carstatus == 8 ||
            node.carstatus == 9 ||
            node.carstatus == 10
          );
        else return true;
      } else if (query == 3) {
        if (node.nodetype == 2)
          return (
            node.carstatus != 6 &&
            node.carstatus != 7 &&
            node.carstatus != 8 &&
            node.carstatus != 9 &&
            node.carstatus != 10
          );
        else return true;
      }
      // return true;
      // return node.nodeName.includes(query);
    },
    onQueryChanged_old(query) {
      this.query_key = query;
      let arr = [];
      let ay = this.getAllPId(arr, this.treeDataMain, "");
      // console.log(ay);
      let ay_pid = [];
      if (ay.length > 0) {
        let a_t = [];
        ay.forEach((it) => {
          a_t = it.split(",");
          a_t.forEach((ik) => {
            if (ik != "" && !ay_pid.includes(ik)) {
              ay_pid.push(ik);
            }
          });
        });
      }

      this.$nextTick(() => {
        if (this.tree_pid.length > 0) {
          let node = null;
          this.tree_pid.forEach((it) => {
            node = this.$refs.treeRef.getNode(it);
            if (node) {
              this.$refs.treeRef.collapseNode(node);
            }
          });
        }
        if (ay_pid.length > 0) {
          let node = null;
          ay_pid.forEach((it) => {
            node = this.$refs.treeRef.getNode(it);
            if (node) {
              this.$refs.treeRef.expandNode(node);
            }
          });

          let array = document.querySelectorAll(".act-title");
          if (array.length > 0) {
            // console.log(array);
            array[array.length - 1].scrollIntoView({
              block: "end",
              behavior: "smooth",
            });
          }
        }
      });
      // this.$refs.treeRef.filter(query);
    },
    init_map() {
      this.BMap = window.BMap;
      this.map = new this.BMap.Map("allmap");
      var point = new this.BMap.Point(115.487135, 38.873433);
      this.map.centerAndZoom(point, 13);
      this.map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
      // this.map.setMapStyle({ style: "midnight" });
    },
  },
};
</script>

<style>
.el-drawer__header {
  padding: 5px 10px 0;
  margin-bottom: 0;
}
.el-drawer__body {
  padding: 0 10px 5px;
}
.act-title {
  color: #fff !important;
  background: #409eff;
  padding-right: 5px;
}
.car_info {
  width: 350px;
}

.car_info h2 {
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  margin-bottom: 10px;
  font-size: 18px;
}

.car_info dl {
  clear: both;
  height: 20px;
  line-height: 20px;
  display: block;
}

.car_info dt {
  width: 25px;
  float: left;
  text-align: justify;
  text-justify: distribute-all-lines;
  text-align-last: justify;
  -moz-text-align-last: justify; /*ff*/
  -webkit-text-align-last: justify; /*chrome 20+*/
}

.car_info dd {
  width: 145px;
  float: left;
  margin-left: 5px;
}
</style>

<style lang="scss" scoped>
.navl {
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}
.xx {
  height: 30px;
  line-height: 30px;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  .xx-it {
    padding-right: 10px;
  }
  .green {
    color: var(--green);
  }
  .gray {
    color: var(--gray);
  }
  .red {
    color: var(--red);
  }
  .primary {
    color: var(--primary);
  }
  .warning {
    color: var(--warning);
  }
  .orange {
    color: var(--orange);
  }
}
.contextmenu {
  margin: 0;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
}
.contextmenu li {
  margin: 0;
  padding: 7px 16px;
  cursor: pointer;
}
.contextmenu li:hover {
  background: #eee;
}

::v-deep(.el-icon:hover) {
  transform: scale(1.1);
  color: rgb(44, 120, 191);
  cursor: pointer;
}
::v-deep(label) {
  vertical-align: middle;
}
::v-deep(.el-tree-node__content) {
  line-height: 26px;
}

.it-flex {
  width: 100%;
  text-align: center;
}
.dth {
  height: calc(100vh - 55px);
}
.left {
  box-sizing: border-box;
  padding: 5px 0px 5px 5px;
  border-right: 1px solid #ccc;
  height: 100%;
}
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  // text-align: center;
  width: 100%;
}
.flex {
  display: flex;
  justify-content: space-between;
}
#allmap {
  width: 100%;
  height: 100%;
}
.it-map {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .tv_control {
    height: 35px;
    text-align: left;
    position: relative;
    padding-top: 5px;

    .map_list {
      height: 35px;
      line-height: 35px;
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 14px;
      span {
        padding-right: 10px;
        cursor: pointer;
      }
    }
    .tv_control_font {
      margin: 5px 5px 0 5px;
      font-size: 17px;
      margin-top: 10px;
      cursor: pointer;
      position: relative;
      color: #2c78bf;
    }
    .tv_control_font:hover {
      transform: scale(1.5);
      color: #419cf2;
    }
  }
  .flex-row-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .mapL {
    flex: 2;
  }
  .mapR {
    flex: 1;
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    margin-right: 5px;
  }
}
.map-bottom {
  position: relative;
  .close {
    position: absolute;
    content: "";
    width: 30px;
    height: 30px;
    background: #92c9f6;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }
}
//样式可以自己定义
.rightMenu {
  position: fixed;
  z-index: 99999999;
  cursor: pointer;
  border: 1px solid #eee;
  box-shadow: 0 0.5em 1em 2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  color: #1a1a1a;
}
.rightMenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 6px;
  padding: 5px 0px;
  background: #fff;
}
.rightMenu ul li {
  padding: 6px 10px;
  background: #fff;
  //border-bottom: 1px solid #000;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-align: left;
}
.rightMenu ul li img {
  margin-right: 8px;
}
.rightMenu ul li svg {
  //margin-right: 8px;
}
.rightMenu ul li:last-child {
  border: none;
}
.rightMenu ul li:hover {
  transition: all 1s;
  background: #d3e0ef;
  color: #333;
}
::v-deep(label) {
  margin-bottom: -2px;
}

.tree-exp {
  display: block;
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 100;
  // background: #f00;
}

// .el-tree .el-tree-node .is-leaf + .el-checkbox .el-checkbox__inner {
//   display: inline-block;
// }
// ::v-deep .el-tree .el-tree-node .el-checkbox .el-checkbox__inner {
//   display: none;
// }
.left-1 {
  border: 1px solid #ccc;
  box-sizing: border-box;
  height: calc(100%);
}
.search {
  padding: 5px 10px;
  background: #fff;
  border-bottom: 1px solid #ccc;
}
.search .el-select {
  margin-right: 5px;
}
.el-tree {
  padding-top: 5px;
}
.el-icon {
  margin-right: 2px;
}
.el-drawer__title {
  font-size: 15px;
  color: #fff;
}
::v-deep .mapbox .tool {
  font-size: 14px;
  padding: 0px 5px;
  line-height: 20px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 7px;
}
::v-deep .el-drawer__title {
  font-size: 14px !important;
  color: #333;
  border-left: 3px solid #2c78bf;
  padding-left: 5px;
}
.xfwz {
}
.xf-1 {
  border-right: 1px solid #ededed;
  border-radius: 5px;
}
.xf-2 {
  border-radius: 5px;
  max-width: 74% !important;
}
::v-deep .xf-2 .el-textarea__inner {
  background: #fafafa !important;
  margin-top: 0px;
  box-shadow: none;
  border: 1px solid #ededed;
}
.xf-3 {
  border-radius: 5px;
}
::v-deep .xfwz .el-tree {
  padding-top: 0px !important;
  margin-top: -5px;
}
.xxs {
  padding-right: 5px;
  color: #2c78bf;
}
::v-deep .ttr .el-table th.el-table__cell {
  color: #333 !important;
}
.cssz-1 {
}
::v-deep .cssz-1 .el-tree {
  background: #fff;
  border: 1px solid #ccc;
  color: #000;
  padding: 15px 5px;
  height: 526px;
}
::v-deep .cssz-1 .is-current {
  border-radius: 5px;
  background: none;
  color: #2c78bf;
}
::v-deep #ccsz {
  color: #000;
  padding: 5px;
  padding-right: 60px;
  border: 1px solid #ccc;
  padding-top: 30px;
  margin-bottom: 10px;
  height: 526px;
}
::v-deep .el-form-item__label,
::v-deep .el-input__inner,
::v-deep .el-input-group__append {
  color: #000;
}
::v-deep .clxx .el-table--small {
  border: 1px solid #eee !important;
}
.gj {
  position: relative;
  top: -2px;
  cursor: pointer;
  color: rgb(44, 120, 191);
  margin-right: 10px;
}
.spb {
  color: rgb(44, 120, 191);
  margin-right: 7px;
}
::v-deep .car_info h2 {
  font-size: 15px;
  font-weight: bold;
}
::v-deep .car_info_item_span,
::v-deep .car_info_item {
  font-size: 13px;
  margin-bottom: 5px;
  line-height: 24px;
  margin-bottom: 0px;
}
::v-deep .car_info_item_span label,
::v-deep .car_info_item label {
  font-weight: bold;
  padding-left: 0px;
}
.cssz ::v-deep .el-select {
  margin-right: 5px;
}
.cssz ::v-deep .el-input {
  padding-right: 5px;
}
.cssz ::v-deep .el-select .el-input {
  padding-right: 0px;
}
.icon {
  font-size: 17px;
}
::v-deep .da {
  font-size: 14px !important;
  margin-right: 3px;
  color: #2c78bf;
  font-weight: bold;
}
::v-deep .da2 {
  font-size: 33px !important;
  margin-right: 3px;
}
::v-deep .da3 {
  font-size: 18px !important;
  margin-left: 5px;
  color: #000;
}
::v-deep .da34 {
  width: 42px;
}
::v-deep .sd {
  font-size: 14px !important;
  margin-right: 2px;
  color: rgba(0, 0, 0, 0.54);
}
::v-deep .sd6 {
  font-size: 16px !important;
  margin-right: 2px;
  color: rgba(0, 0, 0, 0.54);
}
::v-deep .sd7 {
  font-size: 21px !important;
  margin-right: 2px;
  color: rgba(0, 0, 0, 0.54);
}
::v-deep .da3:hover {
}
.boxes {
  overflow: hidden;
  padding-right: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e1e1;
  padding: 10px 0px;
}
.boxes .card {
  cursor: pointer;
  height: auto;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
  box-sizing: border-box;
  border: none;
  background: none;

  position: relative;
  z-index: 1;
}
.boxes .card .info {
  min-height: 35px;
  //padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.boxes .card .info .img {
  width: 42px;
}
.color0 {
  color: #868686;
}
.color1 {
  color: #65c240;
}
.color2 {
  color: #ff4141;
}
.color3 {
  color: #7f03d8;
}
.color4 {
  color: #efd70c;
}
.color5 {
  color: #1469b8;
}

.boxes .card .info .img .icon {
  width: 35px;
  height: 35px;
}
.boxes .card .info .view {
  padding-left: 5px;
  line-height: 25px;
  margin-top: -10px;
  width: 100%;
}
.boxes .card .info .view h4 {
  font-size: 12px;
  color: #2b3d55;
  font-weight: 700;
  width: 100%;
  height: 40px;
  line-height: 40px;
  position: relative;
  z-index: 1;
}
.icon-jia55 {
  margin-left: 5px;
}
.info-lb {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 30px;
  line-height: 15px;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sudu {
  display: flex;
  align-items: center;
}
.mr25 {
  margin-right: 25px;
  display: flex;
  align-items: center;
}
::v-deep(.da2:hover) {
  transform: scale(1.1);
  font-size: 34px !important;
  //color: #333;
  cursor: pointer;
}
::v-deep(.iconfont:hover) {
  transform: scale(1.1);
  //color: #333;
  cursor: pointer;
}
::v-deep .iconfont label {
  margin-left: 3px;
  margin-bottom: 0px !important;
  font-size: 13px;
  width: 20px;
}
.lbs {
  //margin-left: 20px;
  color: #094bf5;
  margin-right: 5px;
}

.wangluo {
  display: flex;
  align-items: center;
  position: relative;
  width: 40px;
}
.wangluo div {
  display: flex;
  align-items: flex-end;
}
.wangluo span {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.38);
  display: block;
  margin-right: 2px;
}
.wangluo span:nth-child(1) {
  width: 2px;
  height: 5px;
}
.wangluo span:nth-child(2) {
  width: 2px;
  height: 8px;
}
.wangluo span:nth-child(3) {
  width: 2px;
  height: 11px;
}
.wangluo span:nth-child(4) {
  width: 2px;
  height: 14px;
}
.wangluo span:nth-child(5) {
  width: 2px;
  height: 17px;
}
::v-deep(.wangluo:hover) {
  transform: scale(1.1);
  //color: #333;
  cursor: pointer;
}
.blue {
  background: #094bf5 !important;
}
.blues {
  color: #094bf5;
}
.view strong {
  //width: 120px;
  font-size: 14px;
}
.w120 {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.gps-page {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  font-size: 12px;
  display: flex;
  background: #fff;
  padding-top: 10px;
  z-index: 999;
}
::v-deep .el-pager li.is-active {
  cursor: default;
  //background: #2c78bf;
  color: #2c78bf;
}
::v-deep .el-pagination,
::v-deep .el-pagination__total {
  font-size: 12px !important;
}
::v-deep .el-pager li {
  min-width: 20px;
  height: 20px;
  padding: 3px !important;
  font-size: 12px !important;
}
::v-deep .el-pagination {
  margin: 0 auto;
}
.boxes:hover {
  border-bottom: 1px solid #2c78bf;
  background: #dceaf6;
}
.act {
  border-bottom: 1px solid #2c78bf;
  background: #dceaf6;
}
.boxes {
  position: relative;
}
.guanzhu {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 999;
}
.guanzhu .icon {
  cursor: pointer;
}
.demo-tabs {
  background: #fff;
}
::v-deep .el-tabs__item.is-active {
  background: none !important;
  color: #2c78bf !important;
  border-radius: 5px;
  border: none !important;
}
::v-deep .el-tabs__item {
  padding: 0px 15px !important;
}
::v-deep .el-tabs__header {
  margin-bottom: 0px;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 1px;
}
::v-deep .el-scrollbar__view {
  padding-bottom: 40px;
}
.xxsc {
  height: 1px;
  background: #eaeaea;
  margin: 10px 0px;
}
.closea {
  top: -2px !important;
  margin-left: -2px !important;
}
.map_tj {
  position: absolute;
  z-index: 9999;
  bottom: 10px;
  right: 40px;
}
.tv-fp {
  display: flex;
  justify-content: space-between;
  width: 66%;
}
.tv-an {
  background: #f9543e;
  border: none;
  color: #fff;
}
::v-deep .gm-ui-hover-effect {
  width: 30px !important;
  height: 30px !important;
  outline: none !important;
  margin-right: 5px !important;
}
.n_act {
  border-bottom: 2px solid #2c78bf;
  color: #2c78bf;
}
.navl div {
  padding: 0px 5px;
}
.xx .warning {
  color: var(--orange) !important;
}
.xx .orange {
  color: #dc5bc7 !important;
}
::v-deep .map_tj .el-button--primary {
  background: #03b356;
  border-radius: 20px;
  padding: 2px 15px;
  border: none;
}
::v-deep .map_tj .el-button--warning {
  background: #f9543e;
  border-radius: 20px;
  padding: 2px 15px;
  border: none;
}
::v-deep .map_tj .act_online {
  box-shadow: 5px 5px 5px #00612e;
}
::v-deep .map_tj .act_alarm {
  box-shadow: 5px 5px 5px #700e01;
}
::v-deep .cp1 table tr td:nth-child(2) div {
  background: #fdc000;
  display: inline-block;
  margin-top: 2px;
  padding: 2px 10px;
  border-radius: 5px;
  color: #000;
  position: relative;
}
::v-deep .cp1 table tr td:nth-child(2) div span:before {
  position: absolute;
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  content: "";
  display: block;
  border: 1px solid currentColor;
  border-radius: 4px;
}

::v-deep .cp2 table tr td:nth-child(1) div {
  background: #fdc000;
  display: inline-block;
  margin-top: 2px;
  padding: 2px 10px;
  border-radius: 5px;
  color: #000;
  position: relative;
}
::v-deep .cp2 table tr td:nth-child(1) div span:before {
  position: absolute;
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  content: "";
  display: block;
  border: 1px solid currentColor;
  border-radius: 4px;
}
.tv-fp svg {
  cursor: pointer;
}
</style>
